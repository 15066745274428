export const GET_CAGES = 'app/cages/GET_CAGES';
export const GET_CAGE = 'app/cages/GET_CAGE';
export const GET_CAGE_SETTINGS = 'app/cages/GET_CAGE_SETTINGS';
export const POST_CAGE = 'app/cages/POST_CAGE';
export const POST_CAGE_NOTE = 'app/cages/POST_CAGE_NOTE';
export const PUT_CAGE = 'app/cages/PUT_CAGE';
export const CREATE_CAGE_DETAIL = 'app/cages/CREATE_CAGE_DETAIL';
export const PUT_CAGE_DETAIL = 'app/cages/PUT_CAGE_DETAIL';
export const PUT_CAGE_STATUS = 'app/cages/PUT_CAGE_STATUS';
export const PUT_CAGE_FINISH = 'app/cages/PUT_CAGE_FINISH';
export const PUT_LABEL_CAGE = 'app/cages/PUT_LABEL_CAGE';
export const DELETE_CAGE = 'app/cages/DELETE_CAGE';
export const FILE_UPLOAD = 'app/cages/FILE_UPLOAD';
export const GET_CAGE_LABEL_DETAIL = 'app/cages/GET_CAGE_LABEL_DETAIL';
