import React from "react";
import PropTypes from "prop-types";

const SettingOption = ({
    id,
    label,
    description,
    type,
    options,
    value,
    onChange
}) => {
    return (
        <div className="form-group">
            <label htmlFor={`Setting_${id}`} className="text-head mb-0">
                {label}
            </label>
            <div className="small text-muted mb-4">{description}</div>
            {type === "select" && (
                <select
                    id={`Setting_${id}`}
                    type="text"
                    name={id}
                    className="custom-select"
                    value={value}
                    onChange={onChange}
                >
                    {options.map(option => {
                        return (
                            <option
                                key={`${id}_${option.value}`}
                                value={option.value}
                            >
                                {option.label}
                            </option>
                        );
                    })}
                </select>
            )}
        </div>
    );
};

SettingOption.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func
};

export default SettingOption;
