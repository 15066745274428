import React from 'react';

const AgentOut = (props) => {
  const { inspection, tasks } = props;
  if (!inspection || !tasks) {
    return null;
  }

  let agent = [];
  for (var i = 0; i < tasks.length; i++) {
    const task = tasks[i];
    const photoCode = task.photoCode;
    const taskSlots = task.taskSlots ? task.taskSlots : [];
    let nameAgentOut = [];
    let nameAgentKick = [];
    for (let k = 0; k < taskSlots.length; k++) {
      const taskSlot = taskSlots[k];
      if (taskSlot.logsKickAgent && taskSlot.logsKickAgent.length > 0) {
        for (let l = 0; l < taskSlot.logsKickAgent.length; l++) {
          const username = taskSlot.logsKickAgent[l];
          nameAgentKick.push(
            <div
              className={`badge  text-head text-uppercase mr-1`}
              style={{ background: '#e67e22', color: '#fff' }}
              key={`agentKick-${l}`}
            >
              {username}
              {taskSlot.inspectName && (
                <div className="badge badge-light ml-2">
                  {taskSlot.inspectName}
                </div>
              )}
            </div>,
          );
        }
      }
      if (taskSlot.logsTimeout && taskSlot.logsTimeout.length > 0) {
        for (let p = 0; p < taskSlot.logsTimeout.length; p++) {
          let username = taskSlot.logsTimeout[p];
          if (typeof username === 'object') {
            username = '??????';
          }
          nameAgentOut.push(
            <div
              className={`badge badge-danger text-head text-uppercase mr-1`}
              key={`${photoCode}-${p}`}
            >
              {username || ''}
              {taskSlot.inspects && taskSlot.inspects.name && (
                <div className="badge badge-light ml-2">
                  {taskSlot.inspects.name}
                </div>
              )}
            </div>,
          );
        }
      }
    }

    agent.push(
      <tr key={task._id}>
        <td>
          <div className={`badge badge-warning text-head text-uppercase`}>
            {photoCode}
          </div>
        </td>
        <td>
          {nameAgentOut}
          {nameAgentKick}
        </td>
      </tr>,
    );
  }
  return (
    <table className="table table-sm table-bordered">
      <tbody>
        <tr>
          <td width="20%">
            <div className={`badge badge-secondary text-head text-uppercase`}>
              Inspection
            </div>
          </td>
          <td width="80%">
            <div className={`badge badge-secondary text-head text-uppercase`}>
              Agent Name
            </div>
          </td>
        </tr>
        {agent}
      </tbody>
    </table>
  );
};

export default AgentOut;
