import React from 'react';
import TimelineNode from './TimelineNode';
import get from 'lodash/get';
import { getDuration, getSelectiveTime } from './_utils';

const statusStyle = {
  FINISHED: 'success',
  ACCEPTED: 'success',
  REJECTED: 'danger',
  PROCESSING: 'primary',
  'IN-QUEUE': 'secondary',
  WAITING: 'secondary',
};

const Timeline = ({
  task,
  showInspect,
  onClickChangeStatusTaskSlot,
  statusInspection,
  inspection,
  showReject,
  user,
  kickAgent,
  qcPassed,
  showReport,
}) => {
  if (!task) {
    return false;
  }
  if (['AMZ', 'AMZ-1', 'AMZ-2', 'AMZ-3'].includes(inspection.group)) {
    qcPassed = false;
  }
  const checkSkipQC =
    inspection &&
    inspection.options &&
    inspection.options.customized &&
    inspection.options.customized.custom_skip_qc &&
    inspection.options.customized.custom_skip_qc.length > 0
      ? inspection.options.customized.custom_skip_qc.map((item) => {
          if (item < 10) {
            return `0${item}`;
          } else {
            return `${item}`;
          }
        })
      : [];
  const usecase =
    inspection && inspection.options && inspection.options.usecase
      ? inspection.options.usecase
      : null;
  const qc_only_accept =
    inspection && inspection.options && inspection.options.qc_only_accept
      ? inspection.options.qc_only_accept
      : null;
  const decodeFailed =
    usecase === 'holman' && task && task.options && task.options.decodeFailed
      ? task.options.decodeFailed
      : false;
  const tsStart = new Date(task.createdAt);
  const tsEnd = getSelectiveTime(task.finishedAt, task.status, task.updatedAt);
  const duration = getDuration(tsStart, tsEnd);
  const inspector = inspection.inspector ? inspection.inspector : '';
  const qa = inspection.qa ? inspection.qa : '';
  const manager = inspection.manager ? inspection.manager : '';
  const group = inspection.group ? inspection.group : 'DEFAULT';
  const holding = inspection.holding ? inspection.holding : false;
  const role = user.role ? user.role : 'AGENT';
  const username = user.username ? user.username : '';
  let checkShowButton = false;
  let showButtonReport = false;
  if (['QA', 'AGENT'].includes(role)) {
    if (username === inspector) {
      checkShowButton = true;
      showButtonReport = true;
    }
    if (username === qa) {
      checkShowButton = true;
      showButtonReport = true;
    }
    if (holding) {
      checkShowButton = false;
      showButtonReport = true;
    }
    if (manager) {
      checkShowButton = false;
      showButtonReport = false;
    }
    if (['VIN-CONFIRM', 'VIN-CHECKING'].includes(inspection.status)) {
      checkShowButton = false;
      showButtonReport = false;
    }
  }
  if (['SUPER', 'TRAINER', 'ADMIN'].includes(role)) {
    showButtonReport = true;
    if (username === manager) {
      checkShowButton = true;
    }
  }

  let timeline = [];
  const code = task.photoCode;
  const slots =
    task.taskSlots && task.taskSlots.length > 0
      ? task.status === 'REJECTED'
        ? [task.taskSlots[0]]
        : task.taskSlots.sort((a, b) =>
            a.inspects && b.inspects && a.inspects.name && b.inspects.name
              ? a.inspects.name.localeCompare(b.inspects.name)
              : a.type,
          )
      : [];
  if (slots && slots.length > 0) {
    for (let i = 0; i < slots.length; i++) {
      const slot = slots[i];
      const slotStart = new Date(slot.processAt);
      const slotEnd = getSelectiveTime(
        slot.finishedAt,
        slot.status,
        slot.updatedAt,
      );
      const plusTime = slot.plusTime ? slot.plusTime : false;
      const nodeMin = getDuration(tsStart, slotStart);
      const nodeSize = getDuration(slotStart, slotEnd);
      const pic =
        slot.agent && slot.agent.username ? slot.agent.username : false;
      const position =
        slot.inspects && slot.inspects.position
          ? slot.inspects.position
          : false;
      let option = {};
      let waitToQC;
      const photoCode = slot?.photoCode;
      if (['40', '70'].includes(photoCode)) {
        checkShowButton = false;
        showButtonReport = false;
      }
      if (slot.type === 'QC') {
        option.taskLabel =
          code === '01'
            ? 'QC & VIN'
            : slot && slot.inspects && slot.inspects.name
            ? slot.inspects.name
            : 'QC';
        option.badgeClass = 'warning';
        //qcDone = nodeMin + nodeSize;
      } else {
        option.taskLabel =
          slot.inspects && slot.inspects.name ? slot.inspects.name : '???';
        option.badgeClass = 'success';
      }

      let progress = [];
      const inspector = get(slot, 'admin', {});

      if (slot.status === 'ADMIN-INSPECTING') {
        progress.push(
          <div
            key="Left_bar"
            className="progress-bar progress-bar-striped progress-bar-animated bg-danger"
            role="progressbar"
            style={{
              width: '100%',
              textTransform: 'lowercase',
              textAlign: 'center',
            }}
          >
            {inspector.username || '???'}
          </div>,
        );
      } else {
        if (inspector.username) {
          progress.push(
            <div
              key="Admin_inspected"
              className={`progress-bar admin-inspected`}
              role="progressbar"
              style={{
                width: '100%',
                textTransform: 'lowercase',
                textAlign: 'center',
              }}
            >
              {inspector.username}
            </div>,
          );
        }
        if (nodeMin > 0) {
          // add waiting line
          if (slot.type === 'QC') {
            waitToQC = (
              <TimelineNode
                key={`QC_waiting`}
                label={{
                  style: 'secondary',
                  value: 'Wait to QC',
                }}
                duration={{ style: 'light', value: nodeMin }}
              />
            );
          }
        }
        progress.push(
          <div
            key="Duration_bar"
            className={`progress-bar ${
              ['PROCESSING', 'INSPECTING'].includes(slot.status)
                ? 'progress-bar-striped progress-bar-animated'
                : ''
            } bg-${option.badgeClass}`}
            role="progressbar"
            style={{
              width: ['FINISHED', 'PROCESSING', 'INSPECTING'].includes(
                slot.status,
              )
                ? '100%'
                : '',
            }}
          />,
        );
      }
      const finalTimeline = (
        <TimelineNode
          key={`Slot_${slot._id}`}
          plusTime={plusTime}
          label={{
            style: option.badgeClass,
            value: option.taskLabel,
            position: position,
          }}
          pic={{
            style: 'light',
            value: pic,
          }}
          duration={{ style: 'light', value: nodeSize }}
          progress={<div className="progress">{progress}</div>}
          changeAction={
            checkShowButton &&
            ['PENDING', 'REJECTED'].includes(slot.status) &&
            !['VIEWER', 'TRAINEE'].includes(user.role) &&
            !['AMZ', 'AMZ-1', 'AMZ-2', 'AMZ-3'].includes(group) &&
            slot.type !== 'QC' && (
              <i
                style={{
                  fontSize: '13px',
                  padding: '3px',
                  color: '#fff',
                  background: 'rgb(103, 93, 122)',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                className="fa fa-edit mr-1"
                onClick={() => onClickChangeStatusTaskSlot(slot._id)}
              />
            )
          }
          editTaskSlot={
            [
              'FINISHED',
              'ADMIN-INSPECTING',
              'REJECTED',
              'ACCEPTED',
              'PENDING',
            ].includes(slot.status) &&
            checkShowButton &&
            (['qc_interior', 'qc_odom', 'qc_tire', 'qc_glass'].includes(
              slot.inspects && slot.inspects.name ? slot.inspects.name : '',
            )
              ? slot.type
              : slot.type !== 'QC') &&
            !['TRAINEE', 'VIEWER'].includes(user.role) &&
            !['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
              statusInspection,
            ) && (
              <i
                style={{
                  fontSize: '13px',
                  padding: '3px',
                  color: '#fff',
                  background: 'red',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                className="fa fa-edit ml-1"
                onClick={() => showInspect(slot._id)}
              />
            )
          }
          onRejectTask={
            checkShowButton &&
            !qcPassed &&
            !decodeFailed &&
            !checkSkipQC.includes(slot.photoCode) &&
            ['ACCEPTED', 'FINISHED', 'REJECTED'].includes(slot.status) &&
            slot.type === 'QC' &&
            !['TRAINEE', 'VIEWER'].includes(user.role) &&
            !qc_only_accept &&
            !['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
              statusInspection,
            ) && (
              <i
                style={{
                  fontSize: '13px',
                  padding: '3px',
                  color: '#fff',
                  background: 'red',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                className="fa fa-ban ml-1"
                onClick={() => showReject(slot.photoCode, task._id)}
              />
            )
          }
          onReportImage={
            showButtonReport &&
            ['FINISHED'].includes(slot.status) &&
            slot.type === 'QC' && (
              <i
                style={{
                  fontSize: '13px',
                  padding: '3px',
                  color: '#000',
                  background: '#ffc107',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                className="fas fa-flag-checkered ml-1"
                onClick={() => showReport(slot.photoCode, task._id)}
              />
            )
          }
          kickAgent={
            checkShowButton &&
            ['INSPECTING', 'PROCESSING'].includes(slot.status) &&
            !['TRAINEE', 'VIEWER'].includes(user.role) && (
              <i
                data-toggle="tooltip"
                data-placement="right"
                title="Kick This Agent"
                className=" fas fa-door-open ml-1"
                style={{
                  fontSize: '13px',
                  padding: '3px',
                  color: '#fff',
                  background: 'orange',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => kickAgent(slot._id)}
              />
            )
          }
        />
      );

      if (slot.type === 'QC') {
        timeline.unshift(waitToQC, finalTimeline);
      } else {
        timeline.push(finalTimeline);
      }
    }
  }
  timeline.push(
    <TimelineNode
      key="Task_summary"
      label={{
        style: 'blackcurrant',
        value: task.status,
      }}
      duration={{
        style: statusStyle[task.status] || 'blackcurrant',
        value: duration,
      }}
    />,
  );
  return <div className="progress-timeline">{timeline}</div>;
};

export default Timeline;
