import React from 'react';
import SVG from 'react-inlinesvg';
const CountDown = (props) => {
  let { end, priority_ttc } = props;
  end = end.getTime();
  if (priority_ttc) {
    end = end + priority_ttc;
  }
  const diff = end - Date.now();
  const inSeconds = Math.round(diff / 1000);
  const inMinutes = Math.floor(inSeconds / 60);
  const secondLeft = inSeconds - inMinutes * 60;
  return (
    <div className="mr-1">
      <div className="d-flex align-items-center">
        <SVG
          className={'clock'}
          src={`img/svg/clock.svg`}
          uniquifyIDs={false}
          width="30%"
        ></SVG>
        <div className="d-block mb-1">
          <div className="d-flex">
            <div
              style={{
                width: '49%',
                fontSize: '17px',
              }}
              className="badge text-head d-flex align-items-center justify-content-center badge-danger mr-1"
            >
              {inMinutes}
            </div>
            <span className="text-head">:</span>
            <div
              style={{
                width: '49%',
                fontSize: '17px',
              }}
              className="badge d-flex text-head align-items-center justify-content-center badge-danger ml-1"
            >
              {secondLeft}
            </div>
          </div>
          <div
            style={{
              fontSize: '15px',
            }}
            className="badge text-head d-block badge-dark mt-1"
          >
            {priority_ttc ? 'Qc-Passed' : 'Run Auto'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountDown;
