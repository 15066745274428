import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getInspections, setNotiAdmin } from '../operations';
import { Link } from 'react-router-dom';
const $ = window.$;
const Notification = (props) => {
	//let timer;
	const [listNoti, setlistNoti] = useState([]);
	const [pingNoti, setpingNoti] = useState(false);
	const [showList, setShowList] = useState(false);
	const { ui } = props;
	const dataNoti = ui.list && ui.list.data ? ui.list.data : null;
	const notiAdmin = ui.notiAdmin;
	const { user } = props.auth;
	const toggleList = () => {
		setShowList(!showList);
		setpingNoti(false);
	};
	useEffect(() => {
		if (user.role !== 'AGENT' && notiAdmin) {
			const timer = setInterval(() => {
				props.getInspections(
					{
						limit: 4,
					},
					false
				);
			}, 5000);
			if (listNoti && listNoti.length > 0) {
				for (let i = 0; i < dataNoti.length; i++) {
					const itemDataNoti = dataNoti[i];
					const check = listNoti.some(
						(item) => itemDataNoti.sessionID === item.sessionID
					);
					if (check) {
						for (let j = 0; j < listNoti.length; j++) {
							const itemListNoti = listNoti[j];
							if (
								itemListNoti.sessionID ===
									itemDataNoti.sessionID &&
								itemDataNoti.status === 'FINISHED'
							) {
								listNoti.splice(j, 1);
							}
						}
					} else if (!check && itemDataNoti.status !== 'FINISHED') {
						setpingNoti(true);
						listNoti.unshift(itemDataNoti);
						var x = document.getElementById('myAudio');
						x.play();
						$('#Noti').addClass('lack');
					}
				}
				setlistNoti(listNoti);
			} else {
				if (dataNoti && dataNoti.length > 0) {
					let list = [];
					for (let i = 0; i < dataNoti.length; i++) {
						const itemDataNoti = dataNoti[i];
						if (
							itemDataNoti.status === 'PROCESSING' ||
							itemDataNoti.status === 'IN-QUEUE'
						) {
							list.push(itemDataNoti);
						}
					}
					if (list && list.length > 0) {
						setlistNoti(list);
					}
				}
			}
			return () => {
				clearInterval(timer);
				var x = document.getElementById('myAudio');
				x.pause();
			};
		} else if (user.role === 'AGENT') {
			props.setNotiAdmin(false);
		}
		// eslint-disable-next-line
	}, [listNoti, dataNoti, notiAdmin]);
	const handleNotiAdmin = () => {
		props.setNotiAdmin(!notiAdmin);
	};
	return (
		<div>
			{user.role !== 'AGENT' ? (
				<div id="Noti">
					<i className="fas fa-car" onClick={toggleList}></i>
					{pingNoti && (
						<div className="noti-bell">
							<i className="fas fa-bell"></i>
						</div>
					)}
					<div
						className="onOff"
						onClick={() => handleNotiAdmin()}
						style={{
							position: 'absolute',
							width: '17px',
							height: '17px',
							borderRadius: '50%',
							right: '-5px',
							bottom: '10px',
							background: notiAdmin ? '#2ed573' : 'red',
							cursor: 'pointer',
						}}
					></div>
					<audio id="myAudio">
						<source src="never.mp3" type="audio/mpeg" />
					</audio>
					{showList && (
						<div className="noti-list">
							<div className="list">
								{listNoti &&
									listNoti.length > 0 &&
									listNoti.map((item, index) => {
										return (
											<div
												className="item"
												key={`item_` + index}
											>
												<div className="badge badge-dark">
													<Link
														to={`/inspectionView/${item._id}`}
														className="text-head text-uppercase"
													>
														{item.inspectionID}
													</Link>
												</div>
											</div>
										);
									})}
							</div>
							<div className="triangle"></div>
						</div>
					)}
				</div>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ui: state.ui,
	auth: state.auth,
	inspection: state.inspect,
});

export default connect(mapStateToProps, { getInspections, setNotiAdmin })(
	Notification
);
