import React from 'react';
import PrismaZoom from 'react-prismazoom';

const ModalImage = (props) => {
	let { support } = props;
	return (
		<div className="modal image-support show-modal" id="myModal">
			<div className="modal-dialog modal-xl">
				<div className="modal-content">
					<div className="modal-header">
						<h4 className="modal-title">Support View</h4>
					</div>
					<div
						className="modal-body"
						style={{
							overflow: 'hidden',
							margin: '1rem',
							padding: 0,
						}}
					>
						<PrismaZoom
							className="App-zoom"
							minZoom={1}
							maxZoom={4}
							leftBoundary={0}
							topBoundary={0}
						>
							<img
								src={
									support && support.support
										? support.support
										: ''
								}
								width="100%"
								height="auto"
								alt="support"
								className="imgId"
							/>
						</PrismaZoom>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalImage;
