import React, { Component } from 'react';
import $ from 'jquery';
import { Rnd } from 'react-rnd';
window.$ = $;

class FileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 'modal-lg',
      listSize: ['modal-lg', 'modal-xl', 'modal-full'],
      listSizeLabel: {
        'modal-lg': 'L',
        'modal-xl': 'XL',
        'modal-full': 'FL',
      },
      rotate: 0,
      showCage: false,
      cage: {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
      },
    };
  }

  componentDidMount() {
    $('.file-modal').on('shown.bs.modal', function () {
      var images = $(this).find('.img-lazy-load');
      images.each(function () {
        var img = $(this);
        img.attr('src', img.attr('data-src'));
      });
    });
    const { size } = this.props;
    if (size) {
      this.setState({ size: size });
    }
  }
  rotateImg = () => {
    const { rotate } = this.state;
    this.setState({
      rotate: rotate + 90,
    });
  };
  handleShowCage = () => {
    const { id, cageInfo } = this.props;
    const { showCage } = this.state;
    const offsetWidth = $(`#${id}`).width();
    const offsetHeight = $(`#${id}`).height();
    const actualImageWidth =
      cageInfo && cageInfo.actual_image && cageInfo.actual_image.width
        ? cageInfo.actual_image.width
        : 0;
    const actualImageHeight =
      cageInfo && cageInfo.actual_image && cageInfo.actual_image.height
        ? cageInfo.actual_image.height
        : 0;
    const ratioX = offsetWidth / actualImageWidth;
    const ratioY = offsetHeight / actualImageHeight;
    let wCage =
      cageInfo && cageInfo.cage && cageInfo.cage.width
        ? Math.round(cageInfo.cage.width) * ratioX - 15
        : 0;
    let hCage =
      cageInfo && cageInfo.cage && cageInfo.cage.height
        ? Math.round(cageInfo.cage.height) * ratioY + 10
        : 0;

    let xCage =
      cageInfo && cageInfo.cage && cageInfo.cage.x
        ? Math.round(cageInfo.cage.x) * ratioX + 25
        : 0;
    let yCage =
      cageInfo && cageInfo.cage && cageInfo.cage.y
        ? Math.round(cageInfo.cage.y) * ratioY + 15
        : 0;
    this.setState({
      showCage: !showCage,
      cage: {
        width: Math.round(wCage),
        height: Math.round(hCage),
        x: Math.round(xCage),
        y: Math.round(yCage),
      },
    });
  };
  onChangeSize = (newSize, e) => {
    e.preventDefault();
    const { size, listSize } = this.state;
    if (size !== newSize && listSize.includes(newSize)) {
      this.setState({ size: newSize });
    }
  };

  render() {
    const { id, title, src, cageInfo, vehicle, photoCode, bgColor } =
      this.props;
    const { size, listSize, listSizeLabel, cage, showCage, rotate } =
      this.state;
    const urlCage = cageInfo && cageInfo.cageSvg ? cageInfo.cageSvg : null;
    let modalBodyStyle = {
      overflow: 'hidden',
      padding: 0,
      margin: '15px',
      background: bgColor,
    };
    return (
      <div
        className="modal file-modal fade"
        id={`${id}_modal`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`${id}_modal_title`}
        aria-hidden="true"
      >
        <div className={`modal-dialog ${size}`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id={`${id}_modal_title`}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={modalBodyStyle}>
              <div className="btn-group mb-2">
                {listSize.map((sz) => {
                  return (
                    <button
                      key={`Size_button_${sz}`}
                      className={`btn btn-tiny ${
                        size === sz ? 'active btn-warning' : 'btn-light'
                      }`}
                      onClick={this.onChangeSize.bind(this, sz)}
                    >
                      {listSizeLabel[sz]}
                    </button>
                  );
                })}
                {photoCode === '01' ? (
                  <button
                    className="btn btn-tiny ml-2"
                    onClick={this.rotateImg.bind()}
                  >
                    <i className="fas fa-sync"></i>
                  </button>
                ) : null}
              </div>
              {urlCage ? (
                <button
                  className={`btn btn-tiny btn-show-cage ${
                    showCage ? 'active btn-warning' : 'btn-light'
                  }`}
                  onClick={() => this.handleShowCage()}
                >
                  {showCage ? 'Hide' : 'Show'} Agent Fixed Cage
                </button>
              ) : null}

              <img
                src=""
                data-src={`${src}?v=${Date.now()}`}
                alt={id}
                id={id}
                style={{
                  transform: `rotate(${rotate}deg)`,
                  width: '100%',
                  height: `${photoCode === '01' ? 'calc(90vh)' : '100%'}`,
                }}
                className="img-lazy-load"
              ></img>
              {urlCage && showCage ? (
                <Rnd
                  className="rnd-cage "
                  style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '0 0',
                    backgroundSize: 'contain',
                  }}
                  size={{
                    width: cage.width,
                    height: cage.height,
                  }}
                  position={{ x: cage.x, y: cage.y }}
                  //bounds=".img-full"
                  lockAspectRatio={true}
                  disableDragging={true}
                >
                  <img src={urlCage} alt="Cage" />
                </Rnd>
              ) : null}
              {vehicle ? (
                <div
                  className="info mt-2 mb-2"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    className="group-info"
                    style={{
                      background: '#fff',
                      padding: '5px 25px',
                      fontSize: '17px',
                      color: 'red',
                      borderRadius: '15px',
                    }}
                  >
                    <span>
                      {vehicle && vehicle.year ? vehicle.year : ''}-
                      {vehicle && vehicle.make ? vehicle.make : ''}-
                      {vehicle && vehicle.model ? vehicle.model : ''}-
                      {vehicle && vehicle.bodyType ? vehicle.bodyType : ''}
                    </span>
                  </div>
                </div>
              ) : null}
              <div className="small text-logan mt-3">{src}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FileModal;
