import * as types from './types';

const getAllTaskFailed = (data) => ({
  type: types.GET_ALL_TASKFAILED,
  payload: { ...data },
});
const getStatistic = (data) => ({
  type: types.STATISTIC,
  payload: { ...data },
});
const getTaskFailed = (data) => ({
  type: types.GET_TASKFAILED,
  payload: { ...data },
});
const confirmTaskFailed = (data) => ({
  type: types.CONFIRM,
  payload: { ...data },
});
export default {
  getAllTaskFailed,
  getTaskFailed,
  getStatistic,
  confirmTaskFailed,
};
