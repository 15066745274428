import React from 'react';
import PropTypes from 'prop-types';
import { AccordionItem, HistoryItem } from '../widgets';

const CageViewHistory = ({ data, show }) => {
	const total = data.length;
	let totalIndex = total + 1;
	const bodyContent = (
		<ul className="list-group">
			{data.map((item) => {
				totalIndex--;
				return (
					<HistoryItem
						key={item._id}
						index={totalIndex}
						data={item}
					/>
				);
			})}
		</ul>
	);
	return (
		<AccordionItem
			id="history"
			buttonLabel={`History (${total})`}
			bodyContent={bodyContent}
			show={show}
		/>
	);
};

CageViewHistory.propTypes = {
	data: PropTypes.array.isRequired,
};

CageViewHistory.defaultProps = {
	show: false,
};

export default CageViewHistory;
