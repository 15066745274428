import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
} from 'recharts';
import { orderBy } from 'lodash';
const Data = (props) => {
  const { data, type, download, filters, role } = props;
  const mistake = data.mistake ? data.mistake : 0;
  const criticalMistake = data.criticalMistake ? data.criticalMistake : 0;
  const verified = data.verified ? data.verified : 0;
  const auto = data.auto ? data.auto : 0;
  const totalDetailMistake = data.totalDetailMistake
    ? data.totalDetailMistake
    : false;
  const listData = data.listData ? data.listData : false;
  const [dataBar, setdataBar] = useState([]);
  const [dataUser, setdataUser] = useState([]);
  const [activeBar, setactiveBar] = useState('Quantity');
  const listTab = [
    { name: 'Quantity', icon: <i className="fas fa-calendar"></i> },
    { name: 'Point', icon: <i className="fas fa-calculator"></i> },
    { name: 'Efficiency', icon: <i className="fas fa-check-square"></i> },
  ];
  const dataChart = [
    {
      name: 'Critical',
      quantity: criticalMistake,
      fill: '#8dd1e1',
    },
    {
      name: 'Verifield + Auto',
      quantity: verified + auto,
      fill: '#83a6ed',
    },
    {
      name: 'Mistake',
      quantity: mistake,
      fill: '#8884d8',
    },
  ];

  const style = {
    top: '50%',
    right: 0,
    color: '#000',
    transform: 'translate(0, -50%)',
    lineHeight: '20px',
  };
  useEffect(() => {
    let tmpList = [];
    let inspectorList = [];
    let sort = {
      key: 'quantity',
      type: 'desc',
    };
    if (typeof totalDetailMistake === 'object') {
      Object.keys(totalDetailMistake).map((key) => {
        const item = totalDetailMistake[key];
        let name = key.replace('_', '');
        switch (key) {
          case 'odom_unit':
            name = 'unit';
            break;
          case 'odom_reading':
            name = 'reading';
            break;
          case 'damage_glass':
          case 'damage_tire':
          case 'damage_part':
          case 'damage_body':
          case 'damage_wheel':
            name = name.replace('damage', '');
            break;
          case 'plate_number':
            name = 'plate';
            break;
          case 'wheel_material':
            name = 'WMaterial';
            break;
          default:
            break;
        }
        return tmpList.push({
          username: key,
          name: name,
          quantity: item.count ? item.count : 0,
          point: item.point ? item.point : 0,
        });
      });
    }
    setdataBar(tmpList);
    if (typeof listData === 'object') {
      if (activeBar === 'Point') {
        sort = {
          ...sort,
          key: 'point',
        };
      }
      Object.keys(listData).map((key) => {
        const item = listData[key];
        return inspectorList.push({
          username: key,
          quantity: item.criticalMistake + item.mistake,
          point: item.point ? item.point : 0,
          type: item.type ? item.type : false,
          taskFailed: item.taskFailed,
          support: item.support,
          verified: item.verified,
          review: item.review,
          auto: item.auto,
          criticalMistake: item.criticalMistake,
          mistake: item.mistake,
        });
      });
    }
    inspectorList = orderBy(inspectorList, sort.key, sort.type);
    setdataUser(inspectorList);
  }, [data, activeBar]);
  const onDownloadStats = () => {
    let content = [];
    let contentPoint = [];
    let contentEff = [
      'Username',
      'Task Failed',
      'Mistake',
      'Support',
      'Review',
      'Verified',
      'Auto',
      'Critical',
    ];
    const { from, to } = filters.range || {};
    for (let index = 0; index < 1; index++) {
      const item = dataUser[index];
      const type = item.type ? item.type : {};
      content.push('username');
      contentPoint.push('username');
      Object.keys(type).map((item) => {
        content.push(item);
        contentPoint.push(item);
      });
      content.push('total');
      contentPoint.push('total');
    }
    for (let i = 0; i < dataUser.length; i++) {
      const item = dataUser[i];
      const type = item.type ? item.type : {};

      let row = [];
      row.push(item.username);
      let rowPoint = [];
      rowPoint.push(item.username);
      let rowEff = [
        item.username,
        item.taskFailed || 0,
        item.mistake || 0,
        item.support || 0,
        item.review || 0,
        item.verified || 0,
        item.auto || 0,
        item.criticalMistake || 0,
      ];

      Object.keys(type).map((item) => {
        let data = type[item].count ? type[item].count : 0;
        let dataPoint = type[item].point ? type[item].point : 0;
        data = type[item].count ? type[item].count : 0;
        dataPoint = type[item].point ? type[item].point : 0;
        row.push(data);
        rowPoint.push(dataPoint);
      });
      row.push(item.quantity);
      rowPoint.push(item.point);

      content += '\r\n' + row.join();
      contentPoint += '\r\n' + rowPoint.join();
      contentEff += '\r\n' + rowEff.join();
    }
    download(content, 'quantity', from, to);
    download(contentPoint, 'point', from, to);
    download(contentEff, 'eff', from, to);
  };
  return (
    <div className="total">
      <div className="d-flex mb-3">
        <div
          className="box-total"
          style={{
            width: '20%',
            margin: '5px',
          }}
        >
          <div
            className="number mb-2"
            style={{
              width: '100%',
              display: 'inline-block',
            }}
          >
            <div
              className="box-count"
              style={{
                padding: '16px 20px',
                margin: '0 5px',
                borderRadius: '10px',
                background: '#321640',
              }}
            >
              <div className="text-head text-white mb-2">
                <div
                  className="action"
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '18px',
                  }}
                >
                  {type}
                </div>
                <div
                  className="number text-dark"
                  style={{
                    background: '#fff',
                    borderRadius: '5px',
                    height: '230px',
                  }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <RadialBarChart
                      width={'100%'}
                      height={'100%'}
                      cx="30%"
                      cy="50%"
                      innerRadius="20%"
                      outerRadius="100%"
                      barSize={12}
                      data={dataChart}
                    >
                      <RadialBar
                        minAngle={15}
                        label={{ position: 'insideStart', fill: '#fff' }}
                        background
                        clockWise
                        dataKey="quantity"
                      />
                      <Tooltip />
                      <Legend
                        iconSize={10}
                        layout="vertical"
                        verticalAlign="middle"
                        wrapperStyle={style}
                      />
                    </RadialBarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="chart text-head"
          style={{
            width: '80%',
            margin: '5px',
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={'100%'}
              height={'100%'}
              data={dataBar}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis
                style={{
                  fontSize: '12px',
                }}
                dataKey="name"
              />
              <YAxis
                style={{
                  fontSize: '15px',
                }}
              />
              <Tooltip />
              <Legend
                style={{
                  fontSize: '12px',
                }}
              />
              {activeBar === 'Quantity' ? (
                <Bar barSize={30} dataKey="quantity" fill="#8884d8" />
              ) : null}
              {activeBar === 'Point' ? (
                <Bar barSize={30} dataKey="point" fill="#82ca9d" />
              ) : null}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="tab d-flex mb-2">
        {listTab.length > 0
          ? listTab.map((item, index) => {
              const width = 100 / listTab.length;
              return (
                <div
                  className="d-flex text-head text-white"
                  key={item.name + index}
                  style={{
                    width: `${width}%`,
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '32px',
                    padding: '25px 20px',
                    margin: '3px',
                    background: '#321640',
                    borderRadius: '15px',
                    opacity: activeBar === item.name ? '1' : '0.3',
                    cursor: 'pointer',
                  }}
                  onClick={() => setactiveBar(item.name)}
                >
                  <div className="mr-2">{item.icon}</div>
                  <div> {item.name} </div>
                </div>
              );
            })
          : null}
      </div>
      {['TRAINER', 'SUPER'].includes(role) ? (
        <button
          className="btn btn-warning"
          style={{
            display: 'block',
          }}
          onClick={() => onDownloadStats()}
        >
          <i className="fas fa-download"></i> Download All
        </button>
      ) : null}

      {['Quantity', 'Point'].includes(activeBar) ? (
        <div className="mt-2">
          <div className="table">
            <div
              className="d-flex"
              style={{
                padding: '5px 10px',
                fontSize: '12px',
              }}
            >
              <div className="text-head" style={{ width: '8%' }}></div>
              <div style={{ width: '89%', display: 'flex' }}>
                {dataBar.length > 0
                  ? dataBar.map((item, index) => {
                      const width = 100 / dataBar.length;
                      return (
                        <div
                          key={`nam_${index}`}
                          style={{ width: `${width}%` }}
                        >
                          {item.name}
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="text-head" style={{ width: '3%' }}>
                Total
              </div>
            </div>
          </div>

          <div style={{ borderRadius: '15px' }} className="text-head">
            {dataUser.length > 0 ? (
              dataUser.map((item, index) => {
                const type = item.type ? item.type : false;
                let dataShow = 0;
                let quantity = item.quantity;
                let point = item.point;
                if (activeBar === 'Quantity') {
                  dataShow = quantity;
                }
                if (activeBar === 'Point') {
                  dataShow = point;
                }
                let background = '#fff';
                let textColor = 'text-dark';
                let showIcon = false;
                let icon = '';
                switch (index) {
                  case 0:
                    showIcon = true;
                    background = '#d63031';
                    textColor = 'text-white';
                    icon = 'fas fa-skull';
                    break;
                  case 1:
                    background = '#e17055';
                    textColor = 'text-white';
                    break;
                  case 2:
                    background = '#fdcb6e';
                    textColor = 'text-dark';
                    break;
                  default:
                    break;
                }

                return (
                  <div
                    key={`listdata_${index}`}
                    className={`mb-2 d-flex ${textColor}`}
                    style={{
                      padding: '9px 10px',
                      borderRadius: '15px',
                      background: background,
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    {showIcon ? (
                      <div
                        className="icon"
                        style={{
                          position: 'absolute',
                          left: '10px',
                          top: '-35px',
                          fontSize: '39px',
                          color: '#000',
                        }}
                      >
                        <i className={icon}></i>
                      </div>
                    ) : null}
                    <div style={{ width: '8%' }}>{item.username}</div>
                    <div style={{ width: '89%', display: 'flex' }}>
                      {type && Object.keys(type).length > 0
                        ? Object.keys(type).map((item, index) => {
                            const width = 100 / Object.keys(type).length;
                            let data = 0;
                            if (activeBar === 'Quantity') {
                              data = type[item].count;
                            }
                            if (activeBar === 'Point') {
                              data = type[item].point;
                            }
                            return (
                              <div
                                key={`typeName_${index}`}
                                style={{ width: `${width}%` }}
                              >
                                {data || 0}
                              </div>
                            );
                          })
                        : null}
                    </div>
                    <div style={{ width: '3%' }}>
                      <div className="point">{dataShow || 0}</div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <div>
                  <i>No data to display.</i>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {activeBar === 'Efficiency' ? (
        <div className="mt-2">
          <div className="table">
            <div
              className="d-flex"
              style={{
                padding: '5px 10px',
                fontSize: '12px',
              }}
            >
              <div className="text-head" style={{ width: '9%' }}></div>
              <div className="text-head" style={{ width: '13%' }}>
                Task Failed
              </div>
              <div className="text-head" style={{ width: '13%' }}>
                Mistake
              </div>
              <div className="text-head" style={{ width: '13%' }}>
                Support
              </div>
              <div className="text-head" style={{ width: '13%' }}>
                Review
              </div>
              <div className="text-head" style={{ width: '13%' }}>
                Verified
              </div>
              <div className="text-head" style={{ width: '13%' }}>
                Auto
              </div>
              <div className="text-head" style={{ width: '13%' }}>
                Critical
              </div>
            </div>
          </div>
          <div style={{ borderRadius: '15px' }} className="text-head">
            {dataUser.length > 0 ? (
              dataUser.map((item, index) => {
                return (
                  <div
                    key={`listdata_${index}`}
                    className={`mb-2 d-flex `}
                    style={{
                      padding: '9px 10px',
                      borderRadius: '15px',
                      alignItems: 'center',
                      position: 'relative',
                      background: '#fff',
                    }}
                  >
                    <div className="text-head" style={{ width: '9%' }}>
                      {item.username}
                    </div>
                    <div className="text-head" style={{ width: '13%' }}>
                      {item.taskFailed || 0}
                    </div>
                    <div className="text-head" style={{ width: '13%' }}>
                      {item.mistake || 0}
                    </div>
                    <div className="text-head" style={{ width: '13%' }}>
                      {item.support || 0}
                    </div>
                    <div className="text-head" style={{ width: '13%' }}>
                      {item.review || 0}
                    </div>
                    <div className="text-head" style={{ width: '13%' }}>
                      {item.verified || 0}
                    </div>
                    <div className="text-head" style={{ width: '13%' }}>
                      {item.auto || 0}
                    </div>
                    <div className="text-head" style={{ width: '13%' }}>
                      {item.criticalMistake}
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <div>
                  <i>No data to display.</i>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Data;
