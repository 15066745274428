import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { clearAll } from '../operations';
import { logout } from '../../auth/operations';
const Alert = (props) => {
  const { ui, clearAll } = props;
  const [alert, setAlert] = useState(Object);

  useEffect(() => {
    const { errors, response } = ui;
    if ((errors && errors.status) || (response && response.status)) {
      let data = {};
      if (errors.message) {
        data.class = 'errors';
        data.statusClass = 'danger';
        data.statusCode = 'Error ' + errors.status;
        data.message = errors.message;
      } else if (response.message) {
        data.class = response.status === 200 ? 'done' : 'notify';
        data.statusClass = response.status === 200 ? 'success' : 'warning';
        data.statusCode = 'Success ' + response.status;
        data.message = response.message;
      }

      if (data.message) {
        setAlert(data);
        setTimeout(() => {
          if (errors.data && errors.data.code === 'SESSION_EXPIRED') {
            props.logout();
          }
          clearAll();
          setAlert({});
        }, 6000);
      }
    }
  }, [ui, clearAll]);

  const onClose = (e) => {
    e.preventDefault();
    clearAll();
  };

  if (!alert.message) return null;

  return (
    <div id="Alert">
      <div className={`alert alert-${alert.class}`}>
        <b className={`mr-3 text-${alert.statusClass}`}>{alert.statusCode}</b>
        {alert.message}
        <button type="button" className="btn btn-close" onClick={onClose}>
          <span aria-hidden="true">
            <i className="la la-times text-secondary" />
          </span>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ui: state.ui,
});

export default connect(mapStateToProps, { clearAll, logout })(Alert);
