import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DateRangePicker } from '../../ui/components/Form';
import Total from './components/Total';
import { getStatistic, downloadCsv, download } from '../operations';
import ListData from './components/ListData';
import Data from './components/Data';
import DataTrainer from './components/DataTrainer';
const statistic = (props) => {
  const { user } = props.auth;
  const { statistic } = props.taskFailed;
  const dataInspector =
    statistic && statistic.dataInspector ? statistic.dataInspector : false;
  const dataQa = statistic && statistic.dataQa ? statistic.dataQa : false;
  const [filters, setfilters] = useState({
    time: 'create',
  });
  const [errors, setErrors] = useState({});
  const [activeTab, setactiveTab] = useState('Basic');
  const [maxDays] = useState(125);
  const [listTab, setlistTab] = useState([
    { name: 'Basic', icon: <i className="fas fa-columns"></i> },
    { name: 'Inspector', icon: <i className="fas fa-calendar"></i> },
    { name: 'Qa', icon: <i className="fas fa-calculator"></i> },
    { name: 'Trainer', icon: <i className="fas fa-check"></i> },
  ]);
  const timezone =
    user && user.options && user.options.timezone
      ? user.options.timezone
      : 'Asia/Ho_Chi_Minh';
  useEffect(() => {
    if (['ADMIN', 'QA', 'AGENT'].includes(user.role)) {
      setlistTab([
        { name: 'Basic', icon: <i className="fas fa-columns"></i> },
        { name: 'Inspector', icon: <i className="fas fa-calendar"></i> },
        { name: 'Qa', icon: <i className="fas fa-calculator"></i> },
      ]);
    }
    const viewRange = 'isoWeek';
    const startDay = initDate(moment(new Date()).startOf(viewRange));
    const endDay = initDate(new Date());
    const filter = {
      time: 'create',
      range: {
        from: startDay,
        to: endDay,
      },
      timezone: timezone,
    };
    props.getStatistic({
      filters: { ...filter },
    });
  }, []);
  const onDownloadTaskFailed = () => {
    const { from, to } = filters.range || {};
    const start = moment(from || new Date());
    const end = moment(to || new Date());
    const days = end.diff(start, 'days');
    if (days > maxDays) {
      setErrors({
        ...errors,
        range: `Date range exceeded maximum of ${maxDays} days.`,
      });
    } else {
      let filter = {
        ...filters,
        timezone: timezone,
      };
      props.downloadCsv({
        filters: filter,
      });
    }
  };

  const initDate = (value) => {
    let localTzDate = moment(value);
    return localTzDate.format('YYYY-MM-DD');
  };
  const onChange = (e) => {
    let filter = filters;
    filter[e.target.name] = e.target.value;
    setfilters(filter);
  };

  const onSubmit = () => {
    //e.preventDefault();
    const { from, to } = filters.range || {};
    const start = moment(from || new Date());
    const end = moment(to || new Date());
    const days = end.diff(start, 'days');

    if (days > maxDays) {
      setErrors({
        ...errors,
        range: `Date range exceeded maximum of ${maxDays} days.`,
      });
    } else {
      let filter = {
        ...filters,
        timezone: timezone,
      };
      setfilters(filter);
      props.getStatistic({
        filters,
      });
    }
  };

  return (
    <div id="statistic">
      <div className="container-fluid">
        <h2 className="subtitle">
          <small>
            <Link to="/performance" className="text-logan">
              <i className="fa fa-arrow-left" />
              <span>List Performance</span>
            </Link>
          </small>
        </h2>
        <h2 className="subtitle">Statistic</h2>

        <div className="groupSearch mb-1 d-flex align-items-center">
          <select
            className="d-inline mr-2 mt-2 badge badge-dark text-head"
            style={{ fontSize: '18px' }}
            name="time"
            onChange={onChange}
          >
            <option key="create" value="create">
              create
            </option>
            <option key="finish" value="finish">
              finish
            </option>
          </select>
          <div
            className="searchRange"
            style={{
              width: '25%',
              displa: 'flex',
              alignItems: 'center',
            }}
          >
            <DateRangePicker
              name="range"
              onChange={onChange}
              error={errors.range}
              maxDate={new Date()}
            ></DateRangePicker>
          </div>
          <button
            className="btn btn-primary mt-2"
            onClick={onSubmit}
            style={{ width: '80px', height: '40px' }}
          >
            Search
          </button>
          {['TRAINER', 'SUPER'].includes(user.role) && (
            <button
              className="btn btn-warning  ml-2 mt-2"
              onClick={() => onDownloadTaskFailed()}
              style={{ width: '250px', height: '40px' }}
            >
              Download Tasks failed
            </button>
          )}
        </div>

        <div className="tab d-flex mb-3">
          {listTab.length > 0
            ? listTab.map((item, index) => {
                const width = 100 / listTab.length;
                return (
                  <div
                    className="d-flex text-head text-white"
                    key={item.name + index}
                    style={{
                      width: `${width}%`,
                      alignContent: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '23px',
                      padding: '5px 20px',
                      margin: '3px',
                      background: '#321640',
                      borderRadius: '25px',
                      opacity: activeTab === item.name ? '1' : '0.3',
                      cursor: 'pointer',
                    }}
                    onClick={() => setactiveTab(item.name)}
                  >
                    <div className="mr-2">{item.icon}</div>
                    <div> {item.name} </div>
                  </div>
                );
              })
            : null}
        </div>
        {activeTab === 'Basic' ? (
          <div className="">
            <Total statistic={statistic} />
            <div className="row mt-3">
              <div className="col-sm-6">
                <h2 className="subtitle">Top Inspector</h2>
                <ListData data={dataInspector} type="inspector" />
              </div>
              <div className="col-sm-6">
                <h2 className="subtitle">Top Qa</h2>
                <ListData data={dataQa} type="qa" />
              </div>
            </div>
          </div>
        ) : null}
        {activeTab === 'Inspector' ? (
          <Data
            data={statistic.dataInspector}
            type={'Inspector'}
            download={props.download}
            filters={filters}
            role={user.role}
          />
        ) : null}
        {activeTab === 'Qa' ? (
          <Data
            data={statistic.dataQa}
            type={'Qa'}
            download={props.download}
            filters={filters}
            role={user.role}
          />
        ) : null}
        {activeTab === 'Trainer' ? (
          <DataTrainer data={statistic.dataTrainer} />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  setting: state.setting,
  taskFailed: state.taskFailed,
});

export default connect(mapStateToProps, {
  getStatistic,
  downloadCsv,
  download,
})(statistic);
