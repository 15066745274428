import React from 'react';
import { findFromList } from '../../operations';
const InspectorData = (props) => {
  const { taskFailed, vehData } = props;
  let inspectData;
  inspectData =
    taskFailed && taskFailed.inspectorData ? taskFailed.inspectorData : {};
  const searchDataVehicle = (name, id) => {
    if (name === 'int_id') {
      const intColors = vehData.vehicle_int_colors || [];
      const intColor = findFromList(intColors, 'int_color', id ? id : false);
      return (
        <div style={{ display: 'inline-block' }}>
          <div
            className="name mr-2 text-head"
            style={{
              display: 'inline-block',
              textTransform: 'uppercase',
              padding: '3px 6px',
              background: '#321640',
              color: '#fff',
              borderRadius: '6px',
              fontSize: '12px',
            }}
          >
            {name ? name.replace('_', ' ') : ''}
          </div>
          <div className="data" style={{ display: 'inline-block' }}>
            {intColor.color_name ? intColor.color_name : ''}(
            {intColor.color_code ? intColor.color_code : ''})
          </div>
        </div>
      );
    }
    if (name === 'ext_id') {
      const extColors = vehData.vehicle_ext_colors || [];
      const extColor = findFromList(extColors, 'ext_color', id ? id : false);
      return (
        <div style={{ display: 'inline-block' }}>
          <div
            className="name mr-2"
            style={{
              display: 'inline-block',
              textTransform: 'uppercase',
              padding: '5px 10px',
              background: '#321640',
              color: '#fff',
              borderRadius: '6px',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            {name ? name.replace('_', ' ') : ''}
          </div>
          <div className="data mr-2" style={{ display: 'inline-block' }}>
            {extColor.color_name ? `(${extColor.color_name})` : ''}
            {extColor.generic_name ? extColor.generic_name : ''}(
            {extColor.color_code ? extColor.color_code : ''})
          </div>
          <div
            className="color"
            style={{
              width: '34px',
              height: '34px',
              border: '1px solid',
              background: `#${extColor.hex_value ? extColor.hex_value : ''}`,
              borderRadius: '50%',
              display: 'inline-block',
            }}
          ></div>
        </div>
      );
    }
    if (name === 'trim_id') {
      const vehicleTrimPack = vehData.vehicle_trims_pack || [];
      const checkSource =
        vehicleTrimPack &&
        vehicleTrimPack.length &&
        vehicleTrimPack.some((item) => item.trim && item.trim.source);
      const trims = checkSource ? vehicleTrimPack : vehData.vehicle_trims;
      const trim = findFromList(trims, 'trim', id ? id : false);
      return (
        <div style={{ display: 'inline-block' }}>
          <div
            className="name mr-2"
            style={{
              display: 'inline-block',
              textTransform: 'uppercase',
              padding: '5px 10px',
              background: '#321640',
              color: '#fff',
              borderRadius: '6px',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            {name.replace('_', ' ')}
          </div>
          <div className="data mr-2" style={{ display: 'inline-block' }}>
            {trim
              ? `${trim.name ? trim.name : ''}
                ${trim.drivetrain && !checkSource ? trim.drivetrain : ''}
                ${trim.transmission ? trim.transmission : ''}
                ${
                  trim.transmission_type_id
                    ? trim.transmission_type_id &&
                      trim.transmission_type_id === 1
                      ? 'Auto'
                      : 'Manual'
                    : ' '
                }
                ${trim.engine ? trim.engine : ''}
                ${trim.engine_type ? trim.engine_type : ''}
                ${trim.fuel_type && !checkSource ? trim.fuel_type : ''} 
                ${trim.mfr_model_code ? trim.mfr_model_code : ''}
                ${trim.brand && !checkSource ? trim.brand : ''}
                ${trim.model && !checkSource ? trim.model : ''}
                ${trim.year && !checkSource ? trim.year : ''}`
              : trim.trim_name
              ? trim.trim_name
              : id}
          </div>
        </div>
      );
    }
  };
  return (
    <div className="mb-2">
      <div
        className="title mb-2"
        style={{
          fontSize: '18px',
          background: '#321640',
          color: 'white',
          borderRadius: '6px',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Inspector
      </div>
      {Object.keys(inspectData).length > 0 &&
        Object.keys(inspectData).map((key, index) => {
          if (key !== 'detected_damages') {
            return (
              <div key={'dataInspector_' + index}>
                {key === 'ext_id' || key === 'int_id' || key === 'trim_id' ? (
                  searchDataVehicle(key, inspectData[key])
                ) : (
                  <div className="d-flex mt-2 mb-2 align-items-center">
                    <div
                      className="name mr-2 text-head"
                      style={{
                        textTransform: 'uppercase',
                        padding: '3px 6px',
                        background: '#321640',
                        color: '#fff',
                        borderRadius: '6px',
                        fontSize: '12px',
                      }}
                    >
                      {key.replace('_', ' ')}
                    </div>
                    <div className="data">{inspectData[key]}</div>
                  </div>
                )}
              </div>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
};

export default InspectorData;
