import React, { useEffect } from 'react';
import { getDuration, getSelectiveTime, displayDuration } from '../_utils';
// import './style.scss';

const Progress = ({ type, border, size, start, end, isWait }) => {
  return (
    <>
      <div
        className={`progress-bar bg-${type}`}
        role="progressbar"
        style={{
          width: `${size}%`,
          textAlign: 'left',
          height: '23px',
          border: `2px solid ${border}`,
          borderRadius: '1rem',
          color: type === 'secondary' ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
          position: isWait ? 'absolute' : '',
          background: isWait ? 'rgba(255,255,255,.3)' : '',
        }}
      >
        {size < 10 ? (
          <span className="text-head" style={{ marginLeft: '-3.3rem' }}>
            {start}
            <div
              style={{
                display: 'inline-block',
                width: '100%',
                margin: '0 0.5rem',
              }}
            ></div>
            <span className="text-head" style={{ marginLeft: '-3.3rem' }}>
              {end}
            </span>
          </span>
        ) : (
          <span
            className="d-flex justify-content-between mx-2 text-head"
            style={{ zIndex: 5 }}
          >
            {start}
            <span>{end}</span>
          </span>
        )}
      </div>
    </>
  );
};

const Agent_Name = ({ task }) => {
  if (task.taskSlots) {
    if (task.taskSlots.length > 1) {
      return (
        <div className="mt-5 pt-3 text-center">
          {task.taskSlots.map((item, index) => {
            return (
              <span key={`agnet_name` + index}>
                <span className="badge badge-dark text-head my-2">
                  {item.agent && item.agent.username ? item.agent.username : ''}
                </span>
                <br />
              </span>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <span className="badge badge-dark text-head mt-2">
            {task.taskSlots[0] &&
            task.taskSlots[0].agent &&
            task.taskSlots[0].agent.username
              ? task.taskSlots[0].agent.username
              : ''}
          </span>
        </div>
      );
    }
  }
  return null;
};
const Photo_Duration = ({ photoCode, duration, photoID, isChild = false }) => {
  return (
    <div className={`p-1 ${isChild ? 'text-right' : ''}`}>
      <span
        className="badge text-left text-head p-0 pr-3"
        style={{
          background: 'rgba(50,22,64,0.4)',
          fontSize: '14px',
          width: isChild ? '90%' : '100%',
        }}
      >
        <span
          className="badge text-light mr-3 py-2 w-50 text-uppercase"
          style={{
            background: 'rgb(50,22,64)',
            fontSize: '12px',
            borderTopRightRadius: '1rem',
            borderBottomRightRadius: '1rem',
          }}
        >
          {photoCode}
        </span>
        {photoCode !== 'TOTAL' ? duration + 's' : displayDuration(duration)}
      </span>
      <br />
      {photoID ? (
        <span
          className="badge badge-pill text-head badge-light mt-1"
          style={{ width: '100%' }}
        >
          {photoID}
        </span>
      ) : null}
    </div>
  );
};

const ProgressTimeline = (props) => {
  const { inspection, tasks, timezone } = props;
  if (!inspection || !tasks) {
    return null;
  }

  const tsStart = new Date(inspection.processAt);
  const tsEnd = getSelectiveTime(
    inspection.finishedAt,
    inspection.status,
    inspection.updatedAt,
  );
  const duration = getDuration(tsStart, tsEnd);
  const handleDuration = (duration, tsStart, tsEnd) => {
    const timeStart = tsStart ? tsStart : false;
    const timeEnd = tsEnd ? tsEnd : new Date();
    const durationTime = getDuration(timeStart, timeEnd);
    let percent = getDuration(timeStart, timeEnd);
    percent = Math.round((durationTime * 100) / duration);

    let timeIn = getDuration(tsStart, timeStart);
    if (timeIn < 0 || timeIn === 0) timeIn = 0;
    const timeInPercent = Math.round((timeIn * 100) / duration);
    return {
      durationTime,
      percent,
      timeInPercent,
      timeStart,
      timeEnd,
    };
  };
  const handleDurationTask = (task, duration, tsStart) => {
    const taskStart = new Date(task.createdAt);
    const taskProcess =
      task && task.taskSlots && task.taskSlots[0]
        ? new Date(task.taskSlots[0].processAt)
        : false;
    const taskEnd = getSelectiveTime(
      task.finishedAt,
      task.status,
      task.updatedAt,
    );
    const taskDuration = getDuration(taskStart, taskEnd);
    let taskWaiting = getDuration(tsStart, taskProcess);
    let taskPercent = getDuration(taskProcess, taskEnd);
    taskPercent = Math.round((taskDuration * 100) / duration);
    taskWaiting = Math.round((taskWaiting * 100) / duration);

    let taskIn = getDuration(tsStart, taskStart);
    if (taskIn < 0 || taskIn === 0) taskIn = 0;
    const taskInPercent = Math.round((taskIn * 100) / duration);
    return {
      taskDuration,
      taskPercent,
      taskInPercent,
      taskStart,
      taskWaiting,
      taskEnd,
    };
  };

  const handleDurationChild = (task, duration) => {
    const taskStart = task.processAt ? new Date(task.processAt) : null;
    const taskEnd = getSelectiveTime(
      task.finishedAt,
      task.status,
      task.updatedAt,
    );
    const taskDuration = getDuration(taskStart, taskEnd);
    const taskPercent = Math.round((taskDuration * 100) / duration);
    let taskIn = getDuration(tsStart, taskStart);
    if (taskIn < 0 || taskIn === 0) taskIn = 0;
    const taskInPercent = Math.round((taskIn * 100) / duration);
    return { taskDuration, taskPercent, taskInPercent, taskStart, taskEnd };
  };

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  let timeline = [];
  const inspectedAt = inspection.inspectedAt
    ? new Date(inspection.inspectedAt)
    : null;
  const inspectSuccessedAt = inspection.inspectSuccessedAt
    ? new Date(inspection.inspectSuccessedAt)
    : null;
  const checkedAt = inspection.inspectedAt
    ? new Date(inspection.inspectedAt)
    : null;

  const finishedAt = inspection.finishedAt
    ? new Date(inspection.finishedAt)
    : null;
  if (inspectedAt) {
    const { durationTime, percent, timeInPercent, timeStart, timeEnd } =
      handleDuration(duration, inspectedAt, inspectSuccessedAt);
    timeline.push(
      <tr key={'handle'}>
        <td>
          <Photo_Duration photoCode="Handle" duration={durationTime} />
        </td>
        <td>
          <div className="text-center">
            <div className="badge badge-dark  text-head">
              {inspection.inspector}
            </div>
          </div>
        </td>
        <td>
          <div
            className="progress bg-light mt-2"
            style={{
              height: '23px',
              borderRadius: '1rem',
              marginBottom: '2.5rem',
            }}
          >
            <Progress
              type="transparent"
              border="transparent"
              size={timeInPercent}
            />
            <Progress type={`transparent`} border="transparent" />
            <Progress
              type={'success'}
              border={`rgb(0,119,23)`}
              size={percent}
              start={timeStart
                .toLocaleTimeString('en-US', {
                  timeZone: timezone || 'America/Toronto',
                })
                .replace('AM', '')
                .replace('PM', '')}
              end={timeEnd
                .toLocaleTimeString('en-US', {
                  timeZone: timezone || 'America/Toronto',
                })
                .replace('AM', '')
                .replace('PM', '')}
            />
          </div>
        </td>
      </tr>,
    );
  }
  if (checkedAt) {
    const { durationTime, percent, timeInPercent, timeStart, timeEnd } =
      handleDuration(duration, checkedAt, finishedAt);
    timeline.push(
      <tr key={`check-${checkedAt}`}>
        <td>
          <Photo_Duration photoCode="Check" duration={durationTime} />
        </td>
        <td>
          <div className="text-center">
            <div className="badge badge-dark  text-head">{inspection.qa}</div>
          </div>
        </td>
        <td>
          <div
            className="progress bg-light mt-2"
            style={{
              height: '23px',
              borderRadius: '1rem',
              marginBottom: '2.5rem',
            }}
          >
            <Progress
              type="transparent"
              border="transparent"
              size={timeInPercent}
            />
            <Progress type={`transparent`} border="transparent" />
            <Progress
              type={'success'}
              border={`rgb(0,119,23)`}
              size={percent}
              start={timeStart
                .toLocaleTimeString('en-US', {
                  timeZone: timezone || 'America/Toronto',
                })
                .replace('AM', '')
                .replace('PM', '')}
              end={timeEnd
                .toLocaleTimeString('en-US', {
                  timeZone: timezone || 'America/Toronto',
                })
                .replace('AM', '')
                .replace('PM', '')}
            />
          </div>
        </td>
      </tr>,
    );
  }
  for (var i = 0; i < tasks.length; i++) {
    const task = tasks[i];
    const {
      taskDuration,
      taskPercent,
      taskInPercent,
      taskStart,
      taskWaiting,
      taskEnd,
    } = handleDurationTask(task, duration, tsStart);

    const taskChild = [];
    const progressChild = [];
    const regex = /\w+$/;
    task.status !== 'REJECTED' &&
      task.taskSlots &&
      task.taskSlots.length > 1 &&
      task.taskSlots.forEach((item, index) => {
        const { taskDuration, taskPercent, taskInPercent, taskStart, taskEnd } =
          handleDurationChild(item, duration);
        let taskChildName = '';
        if (item.photoCode === '04' || item.photoCode === '07') {
          taskChildName = item.inspects
            ? item.inspects.position || item.inspects.name
            : '';
        } else {
          taskChildName = item.inspects
            ? item.inspects.name.includes('damage')
              ? regex.exec(item.inspects.name)
                ? regex.exec(item.inspects.name)[0]
                : ''
              : item.inspects.name.includes('qc')
              ? 'qc'
              : item.inspects.name
            : '';
        }
        if (taskStart) {
          taskChild.push(
            <Photo_Duration
              key={index}
              photoCode={taskChildName}
              duration={taskDuration}
              isChild={true}
            />,
          );
          progressChild.push(
            <div
              key={'progressChild' + index}
              className="progress bg-light my-3"
              style={{ height: '23px', borderRadius: '1rem' }}
            >
              <Progress
                type="transparent"
                border="transparent"
                size={taskInPercent}
              />
              <Progress
                type={`${
                  taskChildName === 'qc' || taskChildName === 'vin'
                    ? 'warning'
                    : 'success'
                }`}
                border={`${
                  taskChildName === 'qc' || taskChildName === 'vin'
                    ? 'rgb(199,145,0)'
                    : 'rgb(0,119,23)'
                }`}
                size={taskPercent}
                start={taskStart
                  .toLocaleTimeString('en-US', {
                    timeZone: timezone || 'America/Toronto',
                  })
                  .replace('AM', '')
                  .replace('PM', '')}
                end={taskEnd
                  .toLocaleTimeString('en-US', {
                    timeZone: timezone || 'America/Toronto',
                  })
                  .replace('AM', '')
                  .replace('PM', '')}
                agent={
                  item.agent && item.agent.username ? item.agent.username : ''
                }
              />
            </div>,
          );
        }
      });
    if (taskDuration) {
      timeline.push(
        <tr key={task._id}>
          <td>
            <Photo_Duration
              photoCode={task.photoCode}
              duration={taskDuration}
              photoID={task.photo_id}
            />
            {taskChild}
          </td>
          <td>
            <Agent_Name task={task} />
          </td>
          <td>
            <div
              className="progress bg-light mt-2"
              style={{
                height: '23px',
                borderRadius: '1rem',
                marginBottom: '2.5rem',
              }}
            >
              <Progress
                type="transparent"
                border="transparent"
                size={taskInPercent}
              />
              <br />
              <Progress
                type={`${task.status === 'REJECTED' ? 'red' : 'green'}`}
                border="transparent"
                size={taskWaiting}
                isWait={true}
              />
              <Progress
                type={`${task.status === 'REJECTED' ? 'danger' : 'success'}`}
                border={`${
                  task.status === 'REJECTED' ? 'rgb(137,0,1)' : 'rgb(0,119,23)'
                }`}
                size={taskPercent}
                start={taskStart
                  .toLocaleTimeString('en-US', {
                    timeZone: timezone || 'America/Toronto',
                  })
                  .replace('AM', '')
                  .replace('PM', '')}
                end={taskEnd
                  .toLocaleTimeString('en-US', {
                    timeZone: timezone || 'America/Toronto',
                  })
                  .replace('AM', '')
                  .replace('PM', '')}
              />
            </div>
            {progressChild}
          </td>
        </tr>,
      );
    }
  }

  return (
    <table className="table table-sm table-bordered">
      <tbody>
        <tr>
          <td width="13%">
            <Photo_Duration photoCode={'TOTAL'} duration={duration} />
          </td>
          <td width="8%" className="text-center">
            <span
              className="badge text-head"
              style={{
                background: 'rgb(50,22,64)',
                fontSize: '14px',
                color: 'rgb(255,255,255)',
                fontWeight: 'bolder',
                margin: '5px',
              }}
            >
              Agent
            </span>
          </td>
          <td>
            <div
              className="progress mt-2"
              style={{ height: '23px', borderRadius: '1rem' }}
            >
              <Progress
                type="secondary"
                border="rgb(65,73,81)"
                size="100"
                start={tsStart
                  .toLocaleTimeString('en-US', {
                    timeZone: timezone || 'America/Toronto',
                  })
                  .replace('AM', '')
                  .replace('PM', '')}
                end={tsEnd
                  .toLocaleTimeString('en-US', {
                    timeZone: timezone || 'America/Toronto',
                  })
                  .replace('AM', '')
                  .replace('PM', '')}
              />
            </div>
          </td>
        </tr>
        {timeline}
      </tbody>
    </table>
  );
};

export default ProgressTimeline;
