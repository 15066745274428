import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalConfirm = (props) => {
	const { hideModal, show, handle, data } = props;

	return (
		<Modal show={show} onHide={hideModal}>
			<Modal.Header closeButton>
				<Modal.Title>Confirm</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Are You Sure ????
				<br></br>
				{data}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={hideModal}>
					Close
				</Button>
				<Button variant="success" onClick={handle}>
					Ok
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalConfirm;
