import * as types from './types';

const getAllCages = (data) => ({
  type: types.GET_CAGES,
  payload: {
    ...data,
  },
});

const getCage = (data) => ({
  type: types.GET_CAGE,
  payload: {
    ...data,
  },
});

const getCageSettings = (data) => ({
  type: types.GET_CAGE_SETTINGS,
  payload: {
    ...data,
  },
});

const postCage = (data) => ({
  type: types.POST_CAGE,
  payload: {
    ...data,
  },
});

const postCageNote = (data) => ({
  type: types.POST_CAGE_NOTE,
  payload: {
    ...data,
  },
});

const putCage = (data) => ({
  type: types.PUT_CAGE,
  payload: {
    ...data,
  },
});
const createCageDetail = (data) => ({
  type: types.CREATE_CAGE_DETAIL,
  payload: {
    ...data,
  },
});
const putCageDetail = (data) => ({
  type: types.PUT_CAGE_DETAIL,
  payload: {
    ...data,
  },
});
const putCageLabelDone = (data) => ({
  type: types.PUT_LABEL_CAGE,
  payload: { ...data },
});

const putCageStatus = (data) => ({
  type: types.PUT_CAGE_STATUS,
  payload: {
    ...data,
  },
});
const getCageLabelDetailDone = (data) => ({
  type: types.GET_CAGE_LABEL_DETAIL,
  payload: { ...data },
});
const putCageFinish = () => ({
  type: types.PUT_CAGE_FINISH,
});

const deleteCage = () => ({
  type: types.DELETE_CAGE,
});
const uploadFile = (data) => ({
  type: types.FILE_UPLOAD,
  payload: {
    ...data,
  },
});
export default {
  getAllCages,
  getCage,
  putCageLabelDone,
  getCageSettings,
  getCageLabelDetailDone,
  postCage,
  postCageNote,
  putCage,
  createCageDetail,
  putCageDetail,
  putCageStatus,
  putCageFinish,
  deleteCage,
  uploadFile,
};
