import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AgentHistoryTable from './components/AgentHistoryTable';
import Pagination from '../../../components/common/Pagination';
import TextFieldGroup from '../../../components/common/TextFieldGroup';
import { getAgent, getAgentHistory, putAgent } from '../operations';

const skills = [
  'vin',
  'trim_id',
  'ext_id',
  'damage_full',
  'damage_other',
  'damage_body',
  'qc',
  'plate_number',
  'odom',
  'interior',
  'tire',
  'glass',
  'qc_plate',
];
export const AgentView = (props) => {
  const { user, getAgent, getAgentHistory, putAgent, auth } = props;

  const [item, setItem] = useState(Object);
  const [agentHistory, setAgentHistory] = useState(Object);
  const [errors, setErrors] = useState(Object);
  const [groups, setgroups] = useState(['DEFAULT', 'AMZ']);
  const { listLocation, listRole, listSex } = props.user.settings;
  const role = auth && auth.user && auth.user.role ? auth.user.role : '';
  const { id } = props.match.params;
  const [filters] = useState({
    userId: id,
  });
  const timezone =
    auth && auth.user && auth.user.options && auth.user.options.timezone
      ? auth.user.options.timezone
      : 'Asia/Ho_Chi_Minh';
  useEffect(() => {
    if (process.env.REACT_APP_APP_ENV === 'development') {
      setgroups([
        'DEFAULT',
        'AMZ',
        'AMZ-1',
        'AMZ-2',
        'AMZ-3',
        'DEF-1',
        'DEF-2',
        'DEF-3',
      ]);
    }
    getAgent(id);
    getAgentHistory({
      filters,
      sorts: {
        createdAt: 'desc',
      },
    });
  }, [getAgent, getAgentHistory]);
  useEffect(() => {
    setItem(user.item);
    setAgentHistory(user.agentHistory);
  }, [user]);
  const onSubmit = async (e) => {
    e.preventDefault();
    const success = await putAgent(id, item);
    if (success) {
      setItem({ ...item });
    }
  };

  const onChange = (e) => {
    setItem({ ...item, [e.target.name]: e.target.value });
  };

  const onCheckSkills = (e) => {
    const value = e.target.value;
    let newSkills = [];
    if (item.skills.includes(value)) {
      newSkills = item.skills.filter((item) => item !== value);
    } else {
      newSkills = [...item.skills, value];
    }
    setItem({ ...item, skills: newSkills });
  };
  const onCheckGroups = (e) => {
    const value = e.target.value;
    let newGroups = [];
    if (item.groups && item.groups.includes(value)) {
      newGroups = item.groups.filter((itemGroup) => itemGroup !== value);
    } else {
      newGroups = [...item.groups, value];
    }
    setItem({ ...item, groups: newGroups });
  };
  const onFocus = (e) => {
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const onPageChange = (e) => {
    e.preventDefault();
    let page;
    if (e.target.getAttribute('data-page')) {
      page = parseInt(e.target.getAttribute('data-page'), 10);
    } else if (e.target.value) {
      page = parseInt(e.target.value, 10);
    }
    getAgentHistory({
      filters,
      page,
    });
  };

  return (
    <div id="Agent_item">
      <div className="container-fluid">
        <h2 className="subtitle">
          <small>
            <Link to="/agents" className="text-logan">
              <i className="fa fa-arrow-left" />
              <span>Our Agent</span>
            </Link>
          </small>
          <br />
          <span>Agent Account: {item && item.username}</span>
        </h2>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">Account Information</div>
              <div className={`show`}>
                <div className="card-body">
                  {item.username && (
                    <form onSubmit={onSubmit} autoComplete="noPrefillPlease">
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <TextFieldGroup
                            label="Username"
                            placeholder="Please enter account username"
                            name="username"
                            value={item.username}
                            onChange={onChange}
                            onFocus={onFocus}
                            error={errors.username}
                            autoComplete="off"
                            disabled="disabled"
                          />
                          <TextFieldGroup
                            label="Email"
                            type="email"
                            placeholder="e.g. agent@pave.bot"
                            name="email"
                            value={item.email}
                            onChange={onChange}
                            onFocus={onFocus}
                            error={errors.email}
                            disabled="disabled"
                          />
                          <TextFieldGroup
                            label="New Password"
                            placeholder="Set new password"
                            name="password"
                            value={item.password}
                            onChange={onChange}
                            onFocus={onFocus}
                            error={errors.password}
                            autoComplete="off"
                          />
                          {role === 'SUPER' && (
                            <div className="">
                              <label className="display-6 text-secondary text-head">
                                Role
                              </label>
                              <select
                                className="form-control"
                                name="role"
                                onChange={onChange}
                                value={item.role}
                              >
                                <option value="">( select role agent )</option>
                                {listRole &&
                                  listRole.map((loc) => (
                                    <option
                                      key={`Loc_${loc.value}`}
                                      value={loc.value}
                                    >
                                      {loc.label}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-lg-4">
                          <TextFieldGroup
                            label="First Name"
                            placeholder="e.g. Hairy"
                            name="first"
                            value={item.first}
                            onChange={onChange}
                            onFocus={onFocus}
                            error={errors.first}
                            disabled="disabled"
                          />
                          <TextFieldGroup
                            label="Last Name"
                            placeholder="e.g. Swordsman"
                            name="last"
                            value={item.last}
                            onChange={onChange}
                            onFocus={onFocus}
                            error={errors.last}
                            disabled="disabled"
                          />
                          <label className="display-6 text-secondary text-head">
                            Location
                          </label>
                          <select
                            className="form-control"
                            name="location"
                            onChange={onChange}
                            defaultValue={
                              item.options ? item.options.location : ''
                            }
                          >
                            <option value="">( select agent location )</option>
                            {listLocation &&
                              listLocation.map((loc) => (
                                <option
                                  key={`Loc_${loc.value}`}
                                  value={loc.value}
                                >
                                  {loc.label}
                                </option>
                              ))}
                          </select>
                          <label className="display-6 text-secondary text-head mt-3">
                            Sex
                          </label>
                          <select
                            className="form-control"
                            name="sex"
                            onChange={onChange}
                            value={item.sex ? item.sex : 'male'}
                          >
                            {listSex &&
                              listSex.map((loc) => (
                                <option key={`sex_${loc}`} value={loc}>
                                  {loc}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group mb-0">
                            <label className="display-7 text-logan text-head text-uppercase">
                              Inspection Skills
                            </label>
                          </div>
                          <div className="row">
                            {skills.map((s, i) => {
                              return (
                                <div
                                  key={`Skill_${i}`}
                                  className="col-12 col-lg-6"
                                >
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`Skill_${s}`}
                                      name="skills[]"
                                      value={s}
                                      disabled={
                                        !['SUPER', 'TRAINER', 'ADMIN'].includes(
                                          role,
                                        )
                                          ? 'disabled'
                                          : ''
                                      }
                                      checked={item.skills.includes(s)}
                                      onChange={onCheckSkills}
                                    />
                                    <label
                                      className="custom-control-label text-xsm text-uppercase"
                                      htmlFor={`Skill_${s}`}
                                    >
                                      {s.toUpperCase()}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="form-group mb-0">
                            <label className="display-7 text-logan text-head text-uppercase">
                              Groups
                            </label>
                          </div>
                          <div className="row">
                            {groups.map((s, i) => {
                              return (
                                <div
                                  key={`Group_${i}`}
                                  className="col-12 col-lg-6"
                                >
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`Group_${s}`}
                                      name="groups[]"
                                      value={s}
                                      disabled={
                                        !['SUPER', 'TRAINER', 'ADMIN'].includes(
                                          role,
                                        )
                                          ? 'disabled'
                                          : ''
                                      }
                                      checked={
                                        item.groups && item.groups.includes(s)
                                      }
                                      onChange={onCheckGroups}
                                    />
                                    <label
                                      className="custom-control-label text-xsm text-uppercase"
                                      htmlFor={`Group_${s}`}
                                    >
                                      {s.toUpperCase()}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="btn-group mt-4">
                        <button className="btn btn-success" type="submit">
                          <i className="fa fa-upload" />
                          <span>Submit</span>
                        </button>
                        <Link to="/agents" className="btn btn-secondary">
                          <i className="fa fa-times" />
                          <span>Cancel</span>
                        </Link>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card mt-3">
              <div className="card-header">Account History</div>
              <div className="card-body">
                {agentHistory.data && agentHistory.data.length > 0 && (
                  <Fragment>
                    <Pagination
                      page={agentHistory.current}
                      pages={agentHistory.pages}
                      records={agentHistory.count}
                      size={agentHistory.limit}
                      action={onPageChange}
                      changeSize={false}
                      displayInfo={false}
                    />
                    <AgentHistoryTable
                      items={agentHistory.data}
                      timezone={timezone}
                    />
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  setting: state.setting,
  auth: state.auth,
});

const mapDispatchToProps = { getAgent, getAgentHistory, putAgent };

export default connect(mapStateToProps, mapDispatchToProps)(AgentView);
