import * as types from './types';

const setLoading = () => ({
	type: types.SET_LOADING,
});

const setErrors = (e) => ({
	type: types.SET_ERRORS,
	payload: e.response,
});

const setResponse = (e) => ({
	type: types.SET_RESPONSE,
	payload: e.response,
});

const clearErrors = () => ({
	type: types.CLEAR_ERRORS,
});
const setNotiAdminDone = (action) => ({
	type: types.SET_NOTIADMIN,
	payload: action,
});
const clearLoading = () => ({
	type: types.CLEAR_LOADING,
});

const clearResponse = () => ({
	type: types.CLEAR_RESPONSE,
});

const clearAll = () => ({
	type: types.CLEAR_ALL,
});
const getInspections = (data) => ({
	type: types.GET_INSPECTIONS,
	payload: { ...data },
});
export default {
	setLoading,
	setErrors,
	setResponse,
	clearErrors,
	clearLoading,
	clearResponse,
	clearAll,
	getInspections,
	setNotiAdminDone,
};
