export const GET_MISSION = 'app/inspect/GET_MISSION';
export const PUT_MISSION = 'app/inspect/PUT_MISSION';
export const PROCESSING = 'app/inspect/PROCESSING';
export const LOADING = 'app/inspect/LOADING';
export const HAS_ERROR = 'app/inspect/HAS_ERROR';
export const STATUS_WAIT = 'app/inspect/STATUS_WAIT';
export const STATUS_PROCESS = 'app/inspect/STATUS_PROCESS';
export const STATUS_START = 'app/inspect/STATUS_START';
export const REJECT_IMAGE = 'app/inspect/REJECT_IMAGE';
export const ACCEPT_IMAGE = 'app/inspect/ACCEPT_IMAGE';
export const FINISH_IMAGE = 'app/inspect/FINISH_IMAGE';
export const ADMIN_FINISH = 'app/inspect/ADMIN_FINISH';
export const COUNT_TIME = 'app/inspect/COUNT_TIME';
export const COUNT_TIMETASK = 'app/inspect/COUNT_TIMETASK';
export const SET_TIME = 'app/inspect/SET_TIME';
export const TIME_OUT = 'app/inspect/TIME_OUT';
export const GET_ADMIN_TASK = 'app/inspect/GET_ADMIN_TASK';
export const INFO_USER = 'app/inspect/INFO_USER';
export const RESET_INSPECTION = 'app/inspect/RESET_INSPECTION';
export const SUMARY_TASK_USER = 'app/inspect/SUMARY_TASK_USER';
export const GET_DAMAGES = 'app/inspect/GET_DAMAGES';
export const CLEAR_LIST_DAMAGES = 'app/inspect/CLEAR_LIST_DAMAGES';
export const CLEAR_MESSAGE = 'app/inspection/CLEAR_MESSAGE';
