import React from 'react';
import SVG from 'react-inlinesvg';
const Permission = () => {
  return (
    <div id="Permission">
      <div className="content container-fluid">
        <div className="row">
          <div className="col-12">
            <div
              style={{
                position: 'fixed',
                width: '100%',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                left: '0',
                textAlign: 'center',
                flexDirection: 'column',
                background: '#321640',
              }}
            >
              <div className="mt-5"></div>
              <SVG
                className={'mt-5'}
                src={'img/svg/unauthorized.svg'}
                uniquifyIDs={false}
                style={{
                  width: '60%',
                }}
              ></SVG>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Permission;
