import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../auth/operations';
import { getSettings } from '../../settings/operations';
import '../../../_sass/animation.scss';
import _sample from 'lodash/sample';

class Toolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      person: 'X',
      action: 'X',
    };
  }

  componentDidMount() {
    this.setAnimation();
    this.setPersonAction();
    const { isAuthenticated } = this.props.auth;
    const { loaded } = this.props.setting;
    if (isAuthenticated && !loaded) {
      this.props.getSettings();
    }
  }

  componentDidUpdate() {
    this.setAnimation();
  }

  setAnimation = () => {
    const spans = document.querySelectorAll('.word span');
    spans.forEach((span) => {
      span.addEventListener('click', (e) => {
        e.target.classList.add('active');
      });
      span.addEventListener('animationend', (e) => {
        e.target.classList.remove('active');
      });
    });
  };

  setPersonAction = () => {
    const { user } = this.state;
    let listPerson = ['Admin', 'Captain', 'General', 'Chef', 'PAVE'];
    if (user && user.username) {
      listPerson.push(user.username);
    }

    const person = _sample(listPerson);

    const action = _sample([
      'action',
      'cooking',
      'working',
      'freaking',
      'sweating',
      'watching',
      'testing',
      'doing',
      'learning',
    ]);

    this.setState({ ...this.state, person, action });
  };

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logout();
  };

  render() {
    const { user } = this.props.auth;
    const { person, action } = this.state;

    if (!user.username) {
      return <Fragment></Fragment>;
    }

    return (
      <div id="Toolbar" className="toolbar">
        <h1 className="text-uppercase text-muted word">
          <span>{person}</span>
          <span className="text-logan">is</span>
          <span>{action}</span>
        </h1>
        <ul className="buttons">
          <li>
            <button className="btn btn-head" onClick={this.setPersonAction}>
              <span className="text-logan">
                <span className="text-normal text-xsm">Welcome</span>{' '}
                {user.username}
              </span>
            </button>
          </li>
          <li>
            <button className="btn text-danger" onClick={this.onLogoutClick}>
              <i className="fa fa-lg fa-power-off" />
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  ui: state.ui,
  setting: state.setting,
});

export default connect(mapStateToProps, { logout, getSettings })(Toolbar);
