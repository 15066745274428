import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAdminAction } from '../../../operations';
const AdminAction = (props) => {
  const { inspection, adminAction, timezone } = props;
  const [filters] = useState({
    sessionID: inspection.sessionID,
  });
  useEffect(() => {
    props.getAdminAction({ filters, limit: 50 });
  }, []);
  if (!inspection) {
    return null;
  }
  const displayInfoItem = (label, value) => {
    return (
      <div className="badge badge-light text-head text-uppercase">
        <span className="small text-logan mr-2">{label}</span>
        <b>{value}</b>
      </div>
    );
  };
  const showActionAdmin = (adminAction, index) => {
    return (
      <Fragment key={`Task_Action_` + index}>
        {adminAction &&
          adminAction.data &&
          adminAction.data.length > 0 &&
          adminAction.data.map((action, index) => {
            const data = action.data ? action.data : false;
            const inspectName =
              data && data.inspectName ? data.inspectName : '';
            const position = data && data.position ? data.position : null;
            const from = data && data.from ? data.from : null;
            const actionAt = action.createdAt
              ? new Date(action.createdAt).toLocaleString('en-US', {
                  timeZone: timezone,
                })
              : null;

            return (
              <tr key={`TaskSlot_Action_` + index}>
                <td>
                  {actionAt ? (
                    <div>{displayInfoItem('Action At', actionAt)}</div>
                  ) : null}
                </td>
                <td>
                  <div className="badge badge-warning text-head text-uppercase">
                    {action.user.role}
                  </div>
                </td>
                <td>
                  <div className="badge badge-danger text-head text-uppercase">
                    {action.user.username}
                  </div>
                </td>
                <td>
                  <div className="badge badge-success text-head text-uppercase">
                    {action.action}
                  </div>
                </td>
                <td>
                  <div
                    className="text-head text-uppercase"
                    style={{
                      color: '#fff',
                    }}
                  >
                    {data.photoCode ? (
                      <div className="badge badge-dark">{data.photoCode}</div>
                    ) : null}

                    {data.photo_id ? (
                      <div className="badge badge-dark ml-1">
                        {data.photo_id}
                      </div>
                    ) : null}

                    {inspectName ? (
                      <div className="badge badge-success text-head text-uppercase ml-1">
                        {data.inspectName}
                      </div>
                    ) : null}
                    {position && (
                      <div className="badge badge-success text-head text-uppercase ml-1">
                        {position}
                      </div>
                    )}
                    {from && (
                      <div className="badge badge-warning text-head text-uppercase ml-1">
                        {from}
                      </div>
                    )}
                  </div>
                </td>
                <td></td>
              </tr>
            );
          })}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <table className="table table-sm table-bordered">
        <tbody>
          <tr>
            <td width="12%">
              <div className={`badge badge-secondary text-head text-uppercase`}>
                Action At
              </div>
            </td>
            <td width="6%">
              <div className={`badge badge-secondary text-head text-uppercase`}>
                Role
              </div>
            </td>
            <td width="10%">
              <div className={`badge badge-secondary text-head text-uppercase`}>
                User Name
              </div>
            </td>
            <td width="15%">
              <div className={`badge badge-secondary text-head text-uppercase`}>
                Action
              </div>
            </td>
            <td width="55%">
              <div className={`badge badge-secondary text-head text-uppercase`}>
                Detail
              </div>
            </td>
          </tr>
          {showActionAdmin(adminAction)}
        </tbody>
      </table>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAdminAction,
})(AdminAction);
