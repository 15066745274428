import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AccordionItem, FileModal } from '../widgets';
import { defCageDetailOptions } from '../../settings';
const inputRef = {};
const CageViewDetail = (props) => {
  const {
    data,
    files,
    show,
    onRemoveUser,
    onUpload,
    user,
    inforCage,
    onChange,
  } = props;
  const [showCreateDetail, setshowCreateDetail] = useState(false);
  const createCageDetail = (photoCode) => {
    setshowCreateDetail(false);
    props.createCageDetail(photoCode);
  };
  const bodyContent = (
    <div>
      <table className="table table-sm table-bordered">
        <thead className="thead-dark">
          <tr>
            <th width="10%">Detail</th>
            <th width="11.25%" className="text-center">
              Photo
            </th>
            <th width="11.25%" className="text-center">
              Photo By
            </th>
            <th width="11.25%" className="text-center">
              Cage (SVG)
            </th>
            <th width="11.25%" className="text-center">
              Cage By
            </th>
            <th width="11.25%" className="text-center">
              Outline (SVG)
            </th>
            <th width="11.25%" className="text-center">
              Outline By
            </th>
            <th width="11.25%" className="text-center">
              Label
            </th>
            <th width="11.25%">Label By</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            const file = files[item.code] || {};
            let modal = [];
            let allowLabel = false;
            const fileTypes = Object.keys(file);
            for (let i = 0; i < fileTypes.length; i++) {
              const type = fileTypes[i];
              const fileType = file[type] || {};
              const options = defCageDetailOptions[type] || {};
              modal[type] = [];
              if (fileType.url) {
                let buttonIcon = options.icon;
                if (fileType.fallback) {
                  buttonIcon += ' text-logan';
                } else {
                  buttonIcon += ' text-success';
                  if (type === 'cage') {
                    allowLabel = true;
                  }
                }
                modal[type].push(
                  <button
                    key={`${type}_${item.code}_modal_button`}
                    className="btn btn-tiny"
                    data-toggle="modal"
                    data-target={`#${type}_${item.code}_modal`}
                  >
                    <i className={buttonIcon} />
                  </button>,
                );
                modal[type].push(
                  <FileModal
                    key={`${type}_${item.code}_modal`}
                    id={`${type}_${item.code}`}
                    title={`${item.code}. ${item.name}: ${options.title} <span class="small text-secondary">(${fileType.folder})</span>`}
                    src={fileType.url}
                    bgColor={type === 'outline' ? '#000' : false}
                  />,
                );
              } else if (type === 'cage' && item.labelBy) {
                modal[type].push(
                  <button
                    key={`${type}_${item.code}_labelling`}
                    className="btn btn-tiny"
                    disabled={true}
                  >
                    <i className="fa fa-lg fa-cog fa-spin" />
                  </button>,
                );
              } else {
                modal[type].push(
                  <button
                    key={`${type}_${item.code}_missing`}
                    className="btn btn-tiny"
                    disabled={true}
                  >
                    <i className="fa fa-unlink text-logan" />
                  </button>,
                );
              }
            }

            let labelAction = [];
            if (allowLabel) {
              if (
                !item.labelBy ||
                (user.role !== 'AGENT' && user.role !== 'VIEWER') ||
                item.labelBy._id === user._id
              ) {
                labelAction.push(
                  <Link
                    key={`Label_btn_${item._id}`}
                    to={`/cageLabel/${item._id}`}
                    className={`btn btn-tiny mr-2 ${
                      user.role !== 'AGENT' ? 'btn-warning' : 'btn-light'
                    }`}
                  >
                    <i className={`fa fa-fw fa-tags`} />
                  </Link>,
                );
              }
              if (item.labelBy && item.labelStatus !== 'DONE') {
                labelAction.push(
                  <i
                    key={`Label_processing_${item._id}`}
                    className="fa fa-lg fa-cog fa-spin text-secondary"
                  />,
                );
              }
            }
            return (
              <tr key={item._id}>
                <td>
                  <span className="badge badge-dark mr-2 text-head">
                    {item.code}
                  </span>
                  {item.name}
                </td>
                <td>
                  <div className="pull-left">
                    <div className="custom-file">
                      <input
                        ref={(ref) => (inputRef['Photo_' + item.code] = ref)}
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={onUpload.bind(this, item._id, 'photoFile')}
                      />
                      {user.role !== 'VIEWER' && (
                        <button
                          className="btn btn-tiny btn-light"
                          onClick={() => inputRef['Photo_' + item.code].click()}
                        >
                          <i className="fa fa-fw fa-upload" />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="pull-right">{modal.photo}</div>
                </td>
                <td className="text-center">
                  {item.photoBy ? item.photoBy.username : ''}
                </td>
                <td>
                  <div className="pull-left">
                    <div className="custom-file">
                      <input
                        ref={(ref) => (inputRef['Cage_' + item.code] = ref)}
                        type="file"
                        accept="image/svg+xml"
                        onChange={onUpload.bind(this, item._id, 'cageFile')}
                      />
                      {user.role !== 'VIEWER' && (
                        <button
                          className="btn btn-tiny btn-light"
                          onClick={() => inputRef['Cage_' + item.code].click()}
                        >
                          <i className="fa fa-fw fa-upload" />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="pull-right">{modal.cage}</div>
                </td>
                <td className="text-center">
                  {item.cageBy ? item.cageBy.username : ''}
                </td>
                <td>
                  <div className="pull-left">
                    <div className="custom-file">
                      <input
                        ref={(ref) => (inputRef['Outline_' + item.code] = ref)}
                        type="file"
                        accept="image/svg+xml"
                        onChange={onUpload.bind(this, item._id, 'outlineFile')}
                      />
                      {user.role !== 'VIEWER' && (
                        <button
                          className="btn btn-tiny btn-light"
                          onClick={() =>
                            inputRef['Outline_' + item.code].click()
                          }
                        >
                          <i className="fa fa-fw fa-upload" />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="pull-right">{modal.outline}</div>
                </td>
                <td className="text-center">
                  {item.outlineBy ? item.outlineBy.username : ''}
                </td>
                <td>
                  <div className="pull-left">{labelAction}</div>
                  <div className={allowLabel ? 'pull-right' : 'text-center'}>
                    {modal.label}
                  </div>
                </td>
                {item.labelBy ? (
                  <td>
                    <div className="row">
                      <div className="col">{item.labelBy.username}</div>
                      {user.role !== 'AGENT' && user.role !== 'VIEWER' ? (
                        <div className="col text-right">
                          <button
                            onClick={onRemoveUser.bind(this, item._id, 'Label')}
                            className="btn btn-tiny"
                          >
                            <i className="fa fa-times text-danger" />
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </td>
                ) : (
                  <td />
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length < 11 &&
      ['SUPER', 'TRAINER', 'ADMIN', 'QA'].includes(user.role) &&
      inforCage.status !== 'ACTIVE' ? (
        <div className="mt-2">
          <button
            onClick={() => {
              setshowCreateDetail(!showCreateDetail);
            }}
            style={{ display: 'inline' }}
            className="btn btn-success mr-2"
          >
            <i className="fas fa-plus"></i>
          </button>
          {showCreateDetail ? (
            <div
              style={{
                display: 'inline-block',
              }}
            >
              <span
                style={{ fontSize: '20px' }}
                className="badge badge-dark mr-2 text-head"
              >
                0{data.length + 1}
              </span>
              <input
                style={{
                  display: 'inline-block',
                  width: '150px',
                  height: '40px',
                }}
                type="text"
                className="form-control form-control-sm mr-2"
                placeholder="Name View"
                name="title"
                onChange={(e) => onChange(false, e)}
              />
              <button
                onClick={() => {
                  createCageDetail(`0${data.length + 1}`);
                }}
                style={{ display: 'inline' }}
                className="btn btn-success"
              >
                OK
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
  return (
    <AccordionItem
      id="detail"
      buttonLabel="Cage Detail"
      bodyContent={bodyContent}
      show={show}
    />
  );
};

CageViewDetail.propTypes = {
  data: PropTypes.array.isRequired,
};

CageViewDetail.defaultProps = {
  show: false,
  files: {},
};

export default CageViewDetail;
