import * as types from './types';

const initialState = {
  list: {
    data: [],
    count: 0,
    pages: 0,
    current: 1,
    limit: 10,
    filters: {},
    sorts: {},
  },
  success: false,
  item: {},
  agentHistory: {},
  agentStats: {},
  settings: {},
  shifts: {},
  shift: {},
  schedule: [],
  teams: [],
  team: {},
  error: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_AGENTS:
      return {
        ...state,
        error: null,
        loading: false,
        item: {},
        ...action.payload,
      };
    case types.GET_AGENT:
      return {
        ...state,
        error: null,
        loading: false,
        ...action.payload,
      };
    case types.CLEAR_SUCCESS:
      return {
        ...state,
        success: false,
      };
    case types.GET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case types.PUT_AGENT:
      return {
        ...state,
        ...action.payload,
      };
    case types.GET_AGENT_HISTORY:
      return {
        ...state,
        agentHistory: { ...action.payload },
      };
    case types.GET_AGENT_SHIFTS:
      return {
        ...state,
        shifts: action.payload,
      };
    case types.GET_AGENT_SHIFT:
    case types.SET_AGENT_SHIFT:
      return {
        ...state,
        shift: action.payload.data,
      };
    case types.UPDATE_AGENT_SHIFT:
      return {
        ...state,
        shift: { ...state.shift, accounts: action.payload.data },
      };
    case types.SET_AGENT_SCHEDULE:
      return {
        ...state,
        schedule: action.payload.data,
      };
    case types.SET_AGENT_STATS:
      return {
        ...state,
        agentStats: action.payload,
      };
    default:
      return state;
  }
}
