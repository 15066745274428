import actions from './actions';
import * as client from '../../utils/client';
import moment from 'moment';
let agentAPI =
  process.env.REACT_APP_AGENT_BASE_URL || 'https://agent.paveapi.com';
export const getAgentStats = (params, loading = true) => {
  return (dispatch) => {
    client.exec(
      'get',
      `${agentAPI}/api/users/allAgentStats`,
      params,
      function (res) {
        dispatch(actions.getAgentStats(res));
      },
      loading,
    );
  };
};
export const getStatisticInspectors = (params, loading = true) => {
  return (dispatch) => {
    client.exec(
      'get',
      `${agentAPI}/api/users/statisticInspectors`,
      params,
      function (res) {
        dispatch(actions.getStatisticInspectors(res));
      },
      loading,
    );
  };
};
export const getStatisticCheckers = (params, loading = true) => {
  return (dispatch) => {
    client.exec(
      'get',
      `${agentAPI}/api/users/statisticCheckers`,
      params,
      function (res) {
        dispatch(actions.getStatisticCheckers(res));
      },
      loading,
    );
  };
};

export const getAgentStatsCsv = (data) => {
  return () => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `agent_stats_${moment(new Date()).format('YYYYMMDD')}.csv`,
    );
    document.body.appendChild(link);
    link.click();
  };
};
export const getCsv = (data, name) => {
  return () => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${name}_${moment(new Date()).format('YYYYMMDD')}.csv`,
    );
    document.body.appendChild(link);
    link.click();
  };
};
