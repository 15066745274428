import React from 'react';

const TitleInspect = (props) => {
  const { taskSettings, taskSlot } = props;
  const title =
    taskSettings && taskSettings.description ? taskSettings.description : null;
  const position =
    taskSlot && taskSlot.inspects && taskSlot.inspects.position
      ? taskSlot.inspects.position
      : false;
  const photoCode = taskSlot && taskSlot.photoCode ? taskSlot.photoCode : null;

  return (
    <div className="group-title">
      <div className="title-inspect mb-1 mt-3">
        <div className="tag-title text-head text-uppercase ">
          <div
            style={{ fontSize: '19px' }}
            className="badge badge-success d-inline number mr-1"
          >
            {photoCode}
          </div>
          <div
            style={{ fontSize: '19px' }}
            className="badge badge-primary title d-inline mr-1"
          >
            {title}
          </div>
          {props.inspectName ? (
            <div
              style={{ fontSize: '19px' }}
              className="badge badge-dark d-inline mr-1"
            >
              {props.inspectName}
            </div>
          ) : null}
          {position ? (
            <div
              style={{ fontSize: '19px' }}
              className=" badge badge-dark d-inline"
            >
              {position}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TitleInspect;
