import * as types from './types';
import isEmpty from '../../validation/is-empty';

const initialState = {
  isAuthenticated: false,
  user: {},
  account: {},
  response: {},
  errors: {},
  accessUrl: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case types.UPDATE_TASKFAILED:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case types.GET_LOGGED_USER:
      return {
        ...state,
        account: action.payload,
      };
    case types.CLEAR_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        account: {},
        accessUrl: false,
      };
    case types.CLEAR_RESPONSE:
      return {
        ...state,
        response: {},
      };
    case types.CHANGE_PROFILE:
      return {
        ...state,
        response: action.payload,
      };
    case types.SET_ACCESS_URL:
      return {
        ...state,
        accessUrl: action.payload,
      };
    default:
      return state;
  }
}
