import React from 'react';

export const getDuration = (start, end) => {
  if (!start || !end) {
    return 0;
  }
  return Math.round((end.getTime() - start.getTime()) / 1000);
};

export const getSelectiveTime = (primary, status, alter) => {
  if (primary) {
    return new Date(primary);
  } else if (status === 'FINISHED' || status === 'REJECTED') {
    return new Date(alter);
  } else {
    return new Date();
  }
};

export const displayDuration = (duration, style = true) => {
  let textClass = '';
  let text;
  const inMinutes = Math.floor(duration / 60);
  const secondLeft = duration - inMinutes * 60;
  const mins = inMinutes;
  const secs = secondLeft;
  if (mins >= 0 && mins < 6) {
    textClass = 'text-success';
  } else if (mins < 9) {
    textClass = 'text-warning';
  } else {
    textClass = 'text-danger';
  }
  text = `${mins}m ${secs}s`;
  return (
    <span
      className={`${style ? textClass : ''} badge badge-light`}
      style={{ fontSize: '14px' }}
    >
      {text}
    </span>
  );
};
