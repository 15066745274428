import React, { useState, useEffect } from 'react';
import { uid, durationText } from '../../../../utils/string';
import { orderBy } from 'lodash';
import { DateRangePicker } from '../../../ui/components/Form';
import moment from 'moment';

const defaultTimezone = 'UTC +0';

const AgentStatsTable = (props) => {
  const { items, today, onChangeDate, onDownloadStats } = props;
  const [list, setList] = useState(Array);
  const [order, setOrder] = useState({ key: 'total', type: 'desc' });
  const [query, setQuery] = useState(today);
  const [errors, setErrors] = useState(Object);
  const [maxDays, setMaxDays] = useState(365);
  const [showDetailInspect, setshowDetailInspect] = useState(false);

  useEffect(() => {
    let tmpList = [];
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        tmpList.push({
          ...item,
        });
      }

      tmpList = orderBy(tmpList, order.key, order.type);
      setList(tmpList);
    }
  }, [items, order]);
  const onChangeScheduleData = (e) => {
    setQuery({ ...query, [e.target.name]: e.target.value });
  };
  const date = new Date();
  const lastDay = new Date(date);
  const lastTime = new Date(date);
  lastDay.setDate(date.getDate() - 1);
  lastTime.setHours(date.getHours() - 1);
  const onViewStats = (e) => {
    e.preventDefault();
    const { from, to } = query.range || {};
    const start = moment(from || new Date());
    const end = moment(to || new Date());
    const days = end.diff(start, 'days');
    if (days > maxDays) {
      setErrors({
        ...errors,
        range: `Date range exceeded maximum of ${maxDays} days.`,
      });
    } else {
      setErrors({ ...errors, range: null });
      onChangeDate({ from, to });
    }
  };

  const toggleOrder = (key) => {
    if (key === order.key) {
      setOrder({
        ...order,
        type: order.type === 'desc' ? 'asc' : 'desc',
      });
    } else {
      setOrder({ key, type: 'desc' });
    }
  };

  return (
    <div>
      <p className="small text-muted font-italic mb-3">
        (*) Stats are generated based on "{defaultTimezone}" timezone. Maximum
        filter range is
        <span className="text-head" onClick={() => setMaxDays(maxDays)}>
          {maxDays} days
        </span>
        .
      </p>
      <div className="row">
        <div className="col-12 col-md-4">
          <DateRangePicker
            name="range"
            onChange={onChangeScheduleData}
            error={errors.range}
            maxDate={lastDay}
            maxTime={lastTime}
          ></DateRangePicker>
        </div>
        <div className="col-12 col-md-8">
          <div className="mb-1">&nbsp;</div>
          <button className="btn btn-warning" onClick={onViewStats}>
            View Stats
          </button>
          <button
            className="btn btn-dark ml-4"
            onClick={() => onDownloadStats(list)}
          >
            Download Stats
          </button>
        </div>
      </div>
      <table className="table table-sm table-bordered">
        <thead className="thead-dark">
          <tr>
            <th
              onClick={() => {
                toggleOrder('user');
              }}
              className="clickable"
            >
              Username
            </th>
            <th
              onClick={() => {
                toggleOrder('check');
              }}
              className="clickable"
            >
              Check
            </th>
            <th
              onClick={() => {
                toggleOrder('inspect');
              }}
              className="clickable"
            >
              Inspect
            </th>
            <th
              onClick={() => {
                toggleOrder('total');
              }}
              className="clickable"
            >
              Tasks
            </th>
            <th
              onClick={() => {
                toggleOrder('type.qc-accepted');
              }}
              className="clickable"
            >
              Accept
            </th>
            <th
              onClick={() => {
                toggleOrder('type.qc-rejected');
              }}
              className="clickable"
            >
              Reject
            </th>
            <th className="clickable" width="15%">
              <span
                onClick={() => {
                  toggleOrder('type.inspect');
                }}
              >
                Inspect
              </span>
              <div
                className={`badge badge-${
                  !showDetailInspect ? 'success' : 'danger'
                } float-right`}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setshowDetailInspect(!showDetailInspect);
                }}
              >
                {!showDetailInspect ? (
                  <i className="fas fa-plus"></i>
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </div>
            </th>
            <th
              onClick={() => {
                toggleOrder('onWaiting');
              }}
              className="clickable"
            >
              On Waiting
            </th>
            <th
              onClick={() => {
                toggleOrder('onInspect');
              }}
              className="clickable"
            >
              On Inspect
            </th>
            <th
              onClick={() => {
                toggleOrder('onDuty');
              }}
              className="clickable"
            >
              On Duty
            </th>
            <th className="clickable" width="5%">
              Plus
            </th>
            <th
              onClick={() => {
                toggleOrder('hasTimeout');
              }}
              className="clickable"
              width="5%"
            >
              Time QC
            </th>
            <th className="clickable" width="5%">
              Time Ins
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(list) && list.length > 0 ? (
            list.map((item) => {
              return (
                <tr key={uid()}>
                  <td>{item.username}</td>
                  <td>
                    <span>{item.check || 0}</span>
                    <span className="d-block mt-1">
                      {durationText(item.timeCheck)}
                    </span>
                  </td>
                  <td>
                    <span>{item.inspect || 0}</span>
                    <span className="d-block mt-1">
                      {durationText(item.timeInspect)}
                    </span>
                  </td>
                  <td>{item.total}</td>
                  <td>
                    {item.type && item.type['qc-accepted']
                      ? item.type['qc-accepted']
                      : 0}
                  </td>
                  <td>
                    {item.type && item.type['qc-rejected']
                      ? item.type['qc-rejected']
                      : 0}
                  </td>
                  <td>
                    {item.type && item.type['inspect']
                      ? item.type['inspect']
                      : 0}

                    {showDetailInspect ? (
                      <div className="data">
                        {item.skills &&
                          Object.keys(item.skills).length &&
                          Object.keys(item.skills).map((i) => {
                            return (
                              <div
                                key={i}
                                className="badge badge-dark text-head mr-1 mb-1"
                              >
                                {i}:
                                <span className="ml-1">{item.skills[i]}</span>
                              </div>
                            );
                          })}
                      </div>
                    ) : null}
                  </td>
                  <td>{durationText(item.onDuty)}</td>
                  <td>{durationText(item.onInspect)}</td>
                  <td>{durationText(item.onDuty + item.onInspect)}</td>
                  <td>{item.plusTime && item.plusTime ? item.plusTime : 0}</td>
                  <td>{item.hasTimeout}</td>
                  <td>{item.hasInspectTimeout}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="7">
                <i>No data to display.</i>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AgentStatsTable;
