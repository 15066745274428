import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCages } from '../operations';
import { CageListTable } from './components';
import Pagination from '../../../components/common/Pagination';
import Permission from '../../ui/containers/Permission';

const CageList = () => {
  const dispatch = useDispatch();
  const cage = useSelector((state) => state.cage);
  const auth = useSelector((state) => state.auth);
  const {
    filters: defaultFilters,
    sorts: defaultSorts,
    limit: defaultLimit,
    current: defaultCurrent,
    pages: defaultPages,
    count: defaultCount,
  } = cage.list;

  // const [autoRefresh, setAutoRefresh] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [sorts, setSorts] = useState(defaultSorts);
  const [limit, setLimit] = useState(defaultLimit);
  const [current, setCurrent] = useState(defaultCurrent);
  const [pages, setPages] = useState(defaultPages);
  const [count, setCount] = useState(defaultCount);

  const user = auth.user;

  useEffect(() => {
    dispatch(getAllCages({ filters, sorts }));
  }, []);

  useEffect(() => {
    if (cage.list) {
      const { filters, sorts, limit, current, pages, count } = cage.list;
      setFilters(filters);
      setSorts(sorts);
      setLimit(limit);
      setCurrent(current);
      setPages(pages);
      setCount(count);
    }
  }, [cage.list]);

  const onPageChange = useCallback(
    (e) => {
      e.preventDefault();
      let newLimit = limit;
      if (e.target.getAttribute('data-value')) {
        newLimit = parseInt(e.target.getAttribute('data-value') || limit, 10);
      }
      let page;
      if (e.target.getAttribute('data-page')) {
        page = parseInt(e.target.getAttribute('data-page'), 10);
      } else if (e.target.value) {
        page = parseInt(e.target.value, 10);
      }
      dispatch(
        getAllCages({
          page: page,
          limit: newLimit,
          filters: filters,
          sorts: sorts,
        }),
      );
      setCurrent(page);
      setLimit(newLimit);
    },
    [limit, filters, sorts, dispatch],
  );

  // const onToggle = useCallback(
  //   (e) => {
  //     const state = !autoRefresh;
  //     setAutoRefresh(state);
  //   },
  //   [autoRefresh],
  // );

  const onChange = useCallback(
    (e) => {
      let newFilters = { ...filters };
      newFilters[e.target.name] = e.target.value;
      dispatch(getAllCages({ filters: newFilters, limit }));
      setFilters(newFilters);
    },
    [filters, limit, dispatch],
  );

  const onSort = useCallback(
    (e) => {
      const sortKey = e.target.getAttribute('data-key');
      let sortValue = e.target.getAttribute('data-value') || 'asc';
      if (sortKey) {
        let newSorts = { ...sorts };
        if (sortValue === 'asc') {
          sortValue = 'desc';
        } else {
          sortValue = 'asc';
        }
        newSorts[sortKey] = sortValue;
        setSorts({ [sortKey]: sortValue });
        dispatch(
          getAllCages({
            filters,
            sorts: newSorts,
            limit,
          }),
        );
      }
    },
    [sorts, filters, limit, dispatch],
  );

  if (['QA', 'AGENT', 'TRAINEE', 'VIEWER'].includes(user.role)) {
    return <Permission />;
  }

  return (
    <div className="container-fluid">
      <h2 className="subtitle">Cage Manager</h2>
      <div className="buttons">
        <Link to="/cageCreate" className="btn btn-success">
          <i className="fa fa-lg fa-plus" />
          <span>Add new cage</span>
        </Link>
      </div>
      {pages > 1 && (
        <Pagination
          size={limit}
          records={count}
          pages={pages}
          page={current}
          action={onPageChange}
        />
      )}
      <CageListTable
        user={user}
        data={cage.list.data}
        filters={filters}
        sorts={sorts}
        onChange={onChange}
        onSort={onSort}
        settings={cage.settings}
      />
    </div>
  );
};

export default CageList;
