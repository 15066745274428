import * as types from './types';

const initialState = {
  list: {
    data: [],
    count: 0,
    pages: 0,
    current: 1,
    limit: 10,
    filters: {},
    sorts: {},
  },
  item: null,
  files: {},
  settings: {},
  error: null,
  success: null,
  loading: false,
  detailLabel: null,
  uploaded: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_CAGES:
      return {
        ...state,
        error: null,
        loading: false,
        item: null,
        files: null,
        success: false,
        list: action.payload.list,
        settings: action.payload.settings,
      };
    case types.GET_CAGE:
      return {
        ...state,
        error: null,
        loading: false,
        item: action.payload.cage,
        files: action.payload.files,
        settings: action.payload.settings,
      };
    case types.GET_CAGE_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case types.GET_CAGE_LABEL_DETAIL: {
      let detailLabel = action.payload.cageDetail;
      detailLabel.files = action.payload.files || {};
      return {
        ...state,
        detailLabel: detailLabel,
      };
    }

    case types.POST_CAGE:
      return {
        ...state,
        error: null,
        loading: false,
        item: action.payload.cage,
        success: 'created',
      };
    case types.POST_CAGE_NOTE:
      return {
        ...state,
        error: null,
        loading: false,
        item: action.payload.cage,
      };
    case types.PUT_CAGE:
      return {
        ...state,
        error: null,
        loading: false,
        item: action.payload.cage,
        success: 'updated',
      };
    case types.CREATE_CAGE_DETAIL:
    case types.PUT_CAGE_DETAIL:
      let { files } = state;
      const { cage, cageDetail, photoUrl, cageUrl, labelUrl } = action.payload;

      let file = files[cage.code];
      file.photoUrl = photoUrl || file.photoUrl;
      file.cageUrl = cageUrl || file.cageUrl;
      file.labelUrl = labelUrl || file.labelUrl;
      files[cage.code] = file;
      return {
        ...state,
        error: null,
        loading: false,
        item: cage,
        files: files,
        detail: cageDetail,
      };
    case types.PUT_CAGE_STATUS:
      if (state.item) {
        state.item.status = action.payload.status || state.item.status;
      }
      return {
        ...state,
        error: null,
        loading: false,
        success: 'status-updated',
      };
    case types.PUT_CAGE_FINISH:
      return {
        ...state,
        success: null,
      };
    case types.FILE_UPLOAD:
      return {
        ...state,
        uploaded: true,
      };
    case types.DELETE_CAGE:
      return {
        ...state,
        error: null,
        loading: false,
        item: null,
      };
    default:
      return state;
  }
}
