import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InspectionListTableItem from './InspectionListTableItem';
import { statusOptions } from '../../settings';
const InspectionListTable = ({
  data,
  filters,
  onChange,
  settings,
  auth,
  inspectInspection,
  checkInspection,
  manage,
  showModalConfirm,
  setModalCage,
  side,
  rd,
  onChangeList,
}) => {
  const listLevelsFilters =
    filters.levels && filters.levels.length > 0 ? filters.levels : [];
  const listStatusFilters =
    filters.status && filters.status.length > 0 ? filters.status : [];
  let { listStatus, listThemes, listGroups, listLevels } = settings;
  const listLevelsSelect =
    listLevels && listLevels.length > 0
      ? listLevels.map((item) => {
          return {
            level: item,
            checked: listLevelsFilters.includes(item) ? true : false,
          };
        })
      : [];
  const listStatusSelect =
    listStatus && listStatus.length > 0
      ? listStatus.map((item) => {
          return {
            status: item,
            checked: listStatusFilters.includes(item) ? true : false,
          };
        })
      : [];
  const [showListStatus, setshowListStatus] = useState(false);
  const [showListLevels, setshowListLevels] = useState(false);
  const groupFilters = filters.group ? filters.group : 'DEFAULT';
  const role = auth && auth.user && auth.user.role ? auth.user.role : 'VIEWER';
  const tblFilters = (
    <tr>
      <th width="6%">
        <select
          className="form-control form-control-sm"
          name="group"
          value={filters.group}
          onChange={(e) => onChange(e, side)}
        >
          <option value="">( all )</option>
          {listGroups &&
            listGroups.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
        </select>
        <select
          className="form-control form-control-sm"
          name="themeName"
          value={filters.themeName}
          onChange={(e) => onChange(e, side)}
        >
          <option value="">( all )</option>
          {listThemes &&
            listThemes.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
        </select>
      </th>
      <th width="8%">
        <input
          type="text"
          className="form-control form-control-sm"
          placeholder="Manager"
          name="manager"
          defaultValue={filters.manager}
          onChange={(e) => onChange(e, side)}
        />
        <input
          type="text"
          className="form-control form-control-sm"
          placeholder="Validator"
          name="validator"
          defaultValue={filters.validator}
          onChange={(e) => onChange(e, side)}
        />
      </th>

      <th width="8%">
        <input
          type="text"
          className="form-control form-control-sm"
          placeholder="QA"
          name="qa"
          disabled={rd && role === 'QA' ? rd : false}
          defaultValue={filters.qa}
          onChange={(e) => onChange(e, side)}
        />
        <input
          type="text"
          className="form-control form-control-sm"
          placeholder="inspector"
          name="inspector"
          disabled={rd && role === 'AGENT' ? rd : false}
          defaultValue={filters.inspector}
          onChange={(e) => onChange(e, side)}
        />
      </th>
      <th width="9%">
        <div className="d-block" style={{ position: 'relative' }}>
          <div
            className="select form-control form-control-sm"
            onClick={() => setshowListStatus(!showListStatus)}
            style={{
              cursor: 'pointer',
            }}
          >
            <div
              className="d-flex"
              style={{
                width: '145px',
                overflow: 'hidden',
              }}
            >
              {listStatusFilters.length > 0
                ? listStatusFilters.map((item, i) => {
                    const statusOption = statusOptions[item] || {};
                    return (
                      <div
                        key={`statusShow_${i}`}
                        className={`badge badge-${statusOption.style} d-inline-block mr-1`}
                      >
                        {item}
                      </div>
                    );
                  })
                : 'ALL'}
            </div>
            <div style={{ position: 'absolute', top: '15%', right: '5px' }}>
              {showListStatus ? (
                <i className="ml-1 fas fa-arrow-down"></i>
              ) : (
                <i className="ml-1 fas fa-arrow-up"></i>
              )}
            </div>
          </div>
          {showListStatus ? (
            <div
              className="list-status"
              style={{
                position: 'absolute',
                width: '100%',
                padding: '5px 5px',
                background: '#fff',
                zIndex: '99',
                top: '33px',
                borderRadius: '10px',
              }}
            >
              <div className="row container-fluid">
                {listStatusSelect.map((s, i) => {
                  const statusOption = statusOptions[s.status] || {};
                  return (
                    <div
                      key={`status_${i}`}
                      className="custom-control custom-checkbox col-12"
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`status_set_${i}`}
                        value={s.status}
                        checked={s.checked}
                        onChange={(e) => onChangeList(e, side, 'status')}
                      />
                      <label
                        className={`custom-control-label text-xsm text-uppercase badge badge-${statusOption.style}`}
                        htmlFor={`status_set_${i}`}
                      >
                        {s.status.toUpperCase()}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </th>

      <th width="12%">
        <input
          type="text"
          className="form-control form-control-sm"
          name="sessionID"
          placeholder="sessionID"
          defaultValue={filters.sessionID}
          onChange={(e) => onChange(e, side)}
        />
        <div className="d-block" style={{ position: 'relative' }}>
          <div
            className="select form-control form-control-sm"
            onClick={() => setshowListLevels(!showListLevels)}
            style={{
              cursor: 'pointer',
            }}
          >
            <div
              className="d-flex"
              style={{
                width: '145px',
                overflow: 'hidden',
              }}
            >
              {listLevelsFilters.length > 0
                ? listLevelsFilters.map((item, i) => {
                    return (
                      <div
                        key={`statusShow_${i}`}
                        className={`badge badge-warning d-inline-block mr-1`}
                      >
                        {item}
                      </div>
                    );
                  })
                : 'ALL'}
            </div>
            <div style={{ position: 'absolute', top: '15%', right: '5px' }}>
              {showListLevels ? (
                <i className="ml-1 fas fa-arrow-down"></i>
              ) : (
                <i className="ml-1 fas fa-arrow-up"></i>
              )}
            </div>
          </div>
          {showListLevels ? (
            <div
              className="list-status"
              style={{
                position: 'absolute',
                width: '100%',
                padding: '5px 5px',
                background: '#fff',
                zIndex: '99',
                top: '33px',
                borderRadius: '10px',
              }}
            >
              <div className="row container-fluid">
                {listLevelsSelect.map((s, i) => {
                  return (
                    <div
                      key={`level_${i}`}
                      className="custom-control custom-checkbox col-12"
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`level_set_${i}`}
                        value={s.level}
                        checked={s.checked}
                        onChange={(e) => onChangeList(e, side, 'level')}
                      />
                      <label
                        className={`custom-control-label text-xsm text-uppercase badge badge-warning`}
                        htmlFor={`level_set_${i}`}
                      >
                        {s.level}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </th>
      <th width="8%" />
      <th width="15%">
        <div className="btn-group d-flex" style={{ alignItems: 'center' }}>
          <input
            type="text"
            className="form-control form-control-sm"
            name="vin"
            placeholder="vin"
            defaultValue={filters.vin}
            onChange={(e) => onChange(e, side)}
          />
          <div className="custom-control custom-switch ml-1">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`sameVin${side}`}
              name="sameVin"
              checked={
                filters.sameVin && filters.sameVin === 'on' ? true : false
              }
              onChange={(e) => onChange(e, side)}
            />
            <label
              className="custom-control-label"
              htmlFor={`sameVin${side}`}
            ></label>
          </div>
        </div>
      </th>
      <th width="15%" />
      <th width="11%" />
      <th width="8%" />
    </tr>
  );

  let tblBody;
  if (data.length > 0) {
    tblBody = data.map((item) => {
      return (
        <InspectionListTableItem
          key={`Inspection_item_${item._id}`}
          data={item}
          auth={auth}
          groupFilters={groupFilters}
          inspectInspection={inspectInspection}
          checkInspection={checkInspection}
          manage={manage}
          showModalConfirm={showModalConfirm}
          setModalCage={setModalCage}
          rd={rd}
        />
      );
    });
  } else {
    tblBody = (
      <tr>
        <td colSpan="10" className="text-secondary">
          <b>No inspection found.</b>
        </td>
      </tr>
    );
  }

  return (
    <table id="Inspection_list_table" className="table table-sm table-bordered">
      <thead className="thead-light">{tblFilters}</thead>
      <tbody>{tblBody}</tbody>
    </table>
  );
};

InspectionListTable.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.object,
  onChange: PropTypes.func,
};

export default InspectionListTable;
