import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAgentStats } from '../operations';
import {
	ResponsiveContainer,
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
} from 'recharts';
import { DateRangePicker } from '../../ui/components/Form';
import Permission from '../../ui/containers/Permission';

const toHour = (value) => {
	return (value / 3600).toFixed(1);
};

const AgentStats = (props) => {
	const { id } = props.match.params;
	const { user, getAgentStats } = props;
	const { agentStats } = user;
	const agent = agentStats.agent || {};
	const dateStats = agentStats.byDate || {};
	const totalTime =
		agentStats.onDuty && agentStats.onInspect
			? agentStats.onDuty + agentStats.onInspect
			: 0;
	const [query, setQuery] = useState(Object);
	//const [errors, setErrors] = useState(Object);
	const { userRole } = props.auth.user;
	useEffect(() => {
		if (id) {
			getAgentStats({ id, query });
		}
		// eslint-disable-next-line
	}, []);

	const onChangeScheduleData = (e) => {
		setQuery({ ...query, [e.target.name]: e.target.value });
	};

	const onViewStats = (e) => {
		e.preventDefault();
		const range = query.range || {};
		let finalQuery = {};
		if (range.from) {
			finalQuery['filterStartDate'] = range.from;
		}
		if (range.to) {
			finalQuery['filterDate'] = range.to;
		}
		getAgentStats({ id, query: finalQuery });
	};

	const data = React.useMemo(
		() =>
			Object.keys(dateStats).map((key) => {
				const value = dateStats[key];
				const timeDuty = value.onDuty || 0;
				const timeInspect = value.onInspect || 0;
				return {
					name: key,
					duty: toHour(timeDuty),
					inspect: toHour(timeInspect),
					total: toHour(timeDuty + timeInspect),
				};
			}),
		[dateStats]
	);

	return (
		<div id="Agent_teams">
			{userRole !== 'AGENT' ? (
				<div className="container-fluid">
					<h2 className="subtitle">
						<small>
							<Link to="/agents" className="text-logan">
								<i className="fa fa-arrow-left" />
								<span>Our Agents</span>
							</Link>
						</small>
						<br />
						<span>Agent Stats: {agent && agent.username}</span>
					</h2>
					<div className="row">
						<div className="col-12 col-md-4">
							<DateRangePicker
								name="range"
								onChange={onChangeScheduleData}
								//error={errors.range}
								maxDate={new Date()}
							></DateRangePicker>
						</div>
						<div className="col-12 col-md-8">
							<div className="mb-1">&nbsp;</div>
							<button
								className="btn btn-warning"
								onClick={onViewStats}
							>
								View Stats
							</button>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h3 className="card-title mb-0">
										Work Time{' '}
										<span className="float-right">
											Total: {toHour(totalTime)} HRS
										</span>
									</h3>
								</div>
								<div className="card-body">
									{data.length > 0 && (
										<ResponsiveContainer
											width="100%"
											aspect={16.0 / 6.0}
										>
											<BarChart
												data={data}
												margin={{
													top: 5,
													right: 30,
													left: 20,
													bottom: 5,
												}}
												barSize={20}
												style={{ fontSize: '.75rem' }}
											>
												<CartesianGrid strokeDasharray="3 3" />
												<XAxis
													dataKey="name"
													padding={{
														left: 10,
														right: 10,
													}}
												/>
												<YAxis
													label={{
														value: 'hours',
														angle: -90,
														position: 'insideLeft',
													}}
													unit="hrs"
												/>
												<Tooltip />
												<Legend
													verticalAlign="top"
													height={36}
												/>
												<Bar
													dataKey="inspect"
													fill="#F65058FF"
												/>
												<Bar
													dataKey="duty"
													fill="#FBDE44FF"
												/>
												<Bar
													dataKey="total"
													fill="#28334AFF"
												/>
											</BarChart>
										</ResponsiveContainer>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<Permission />
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps, { getAgentStats })(AgentStats);
