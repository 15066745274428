import React, { useEffect, useState } from 'react';
import Select from 'react-select';
const ModalSetCage = (props) => {
	const {
		hideModal,
		dataItem,
		getActiveCages,
		cages,
		putInspectionCage,
		setautoRefresh,
	} = props;
	const vehicle = dataItem.vehicle || false;
	const [currentCage, setcurrentCage] = useState({});
	const [cageList, setcageList] = useState([]);
	const [inputValue, setinputValue] = useState(false);
	useEffect(() => {
		if (dataItem.cage && !inputValue) {
			const thisCage = dataItem.cage;
			setcurrentCage({
				label: `(${thisCage.bodyType}) ${thisCage.year} ${thisCage.make} ${thisCage.model}`,
				value: thisCage._id,
			});
		}
		if (
			cages &&
			cages.list &&
			cages.list.data &&
			cages.list.data.length > 0
		) {
			for (let i = 0; i < cages.list.data.length; i++) {
				const cage = cages.list.data[i];
				cageList.push({
					label: `(${cage.bodyType}) ${cage.year} ${cage.make} ${cage.model}`,
					value: cage._id,
				});
			}
			setcageList(cageList);
		}
	}, [dataItem.cage, cages, cageList, inputValue]);
	const offModal = () => {
		hideModal();
		setautoRefresh(true);
	};
	const onInputChange = (text) => {
		setinputValue(true);
		if (text && text.length > 2) {
			getActiveCages({ keyword: text });
		}
	};
	const onChangeCage = (e) => {
		setinputValue(true);
		setcurrentCage({
			label: e.currentCage.label,
			value: e.currentCage.value,
		});
	};
	const onSetCage = (e) => {
		e.preventDefault();
		if (dataItem && dataItem._id && currentCage && currentCage.value) {
			putInspectionCage(dataItem._id, currentCage.value);
			setautoRefresh(true);
			hideModal();
		}
	};
	return (
		<div className={`modal inspect show-modal`} id="myModal">
			<div className="modal-dialog modal-lg" role="document">
				<div className="modal-content text-normal">
					<div className="modal-header">
						<h5
							className="modal-title"
							id="Inspection_cage_edit_title"
						>
							Change Inspection Cage
						</h5>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							onClick={offModal}
						>
							<span aria-hidden="true">
								<i className="fa fa-times" />
							</span>
						</button>
					</div>
					<div className="modal-body">
						<h2 className="subtitle">
							{dataItem.inspectionID} / {dataItem.sessionID}
						</h2>
						{vehicle ? (
							<div>
								<div className="display-5 text-head text-logan text-uppercase mb-1">
									Vehicle
								</div>
								<h5 className="text-head">
									{vehicle.year} {vehicle.make}
									{vehicle.model}
								</h5>
								{vehicle.bodyType}
							</div>
						) : null}

						<div className="display-5 text-head text-logan text-uppercase mb-1">
							Select New Cage
						</div>
						<div className="row mb-3">
							<Select
								id="Cage_select"
								value={currentCage}
								options={cageList}
								placeholder={`Select cage for the inspection`}
								isClearable={false}
								className="col-8 react-select-container"
								classNamePrefix="react-select"
								onChange={(e) => {
									onChangeCage({ currentCage: e });
								}}
								onInputChange={onInputChange}
							/>
							<div>
								<button
									className="btn btn-outline-secondary btn-expand ml-2"
									type="button"
									onClick={onSetCage}
								>
									Set This Cage
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalSetCage;
