import * as types from './types';

const getSettings = (data) => ({
	type: types.GET_SETTINGS,
	payload: { ...data },
});

const setSetting = (data) => ({
	type: types.SET_SETTING,
	payload: { ...data },
});

const setPassword = (data) => ({
	type: types.SET_PASSWORD,
	payload: { ...data },
});
const setNotification = (data) => ({
	type: types.SET_NOTIFICATION,
	payload: { ...data },
});
export default {
	getSettings,
	setSetting,
	setPassword,
	setNotification,
};
