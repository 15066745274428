import * as types from './types';

const getMissionDone = (data) => ({
	type: types.GET_MISSION,
	payload: {
		...data,
	},
});
const getAdminTaskDone = (data) => ({
	type: types.GET_ADMIN_TASK,
	payload: {
		...data,
	},
});
const acceptImage = (data) => ({
	type: types.ACCEPT_IMAGE,
	payload: {
		...data,
	},
});
const finishImage = (data) => ({
	type: types.FINISH_IMAGE,
	payload: {
		...data,
	},
});
const adminFinishDone = (data) => ({
	type: types.FINISH_IMAGE,
	payload: {
		...data,
	},
});
const rejectImage = (data) => ({
	type: types.REJECT_IMAGE,
	payload: {
		...data,
	},
});
const hasError = (e) => ({
	type: types.HAS_ERROR,
	payload: {
		...e,
	},
});
const infoUser = (data) => ({
	type: types.INFO_USER,
	payload: {
		...data,
	},
});
const sumaryTaskUser = (data) => ({
	type: types.SUMARY_TASK_USER,
	payload: {
		...data,
	},
});
const resetInspection = () => ({
	type: types.RESET_INSPECTION,
});
const setTime = (time) => ({
	type: types.SET_TIME,
	payload: time,
});
const timeOut = (data) => ({
	type: types.TIME_OUT,
	payload: { ...data },
});
const getDamagesDone = (data) => ({
	type: types.GET_DAMAGES,
	payload: {
		...data,
	},
});
const clearListDamagesDone = () => ({
	type: types.CLEAR_LIST_DAMAGES,
});
export default {
	getMissionDone,
	hasError,
	rejectImage,
	acceptImage,
	finishImage,
	resetInspection,
	setTime,
	timeOut,
	adminFinishDone,
	infoUser,
	sumaryTaskUser,
	getDamagesDone,
	clearListDamagesDone,
	getAdminTaskDone,
};
