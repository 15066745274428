import React from 'react';
import PropTypes from 'prop-types';
import UserListTableItem from './UserListTableItem';

const UserListTable = ({
  data,
  onSort,
  filters,
  onChange,
  timezone,
  listRole,
  listShifts,
  user,
  putAgent,
  showModalConfirm,
}) => {
  const tblHeaders = (
    <div style={{ display: 'flex', fontWeight: 'bold', opacity: '0.7' }}>
      <div
        style={{
          paddingLeft: '10px',
          width: '8%',
        }}
      >
        Role
      </div>
      <div
        style={{
          width: '12%',
        }}
      >
        <span>User Name</span>
        <div
          className="float-right mr-3 d-inline-block"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => onSort('username')}
        >
          {filters.sorts && filters.sorts.username ? (
            filters.sorts.username === 1 ? (
              <i className="ml-1 fas fa-arrow-down"></i>
            ) : (
              <i className="ml-1 fas fa-arrow-up"></i>
            )
          ) : (
            <i className="fas fa-sort"></i>
          )}
        </div>
      </div>
      <div
        style={{
          width: '5%',
        }}
      >
        Shift
      </div>
      <div
        style={{
          width: '15%',
        }}
      >
        <span>Status</span>
        <div
          className="float-right mr-3 d-inline-block"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => onSort('action')}
        >
          {filters.sorts && filters.sorts.action ? (
            filters.sorts.action === 1 ? (
              <i className="ml-1 fas fa-arrow-down"></i>
            ) : (
              <i className="ml-1 fas fa-arrow-up"></i>
            )
          ) : (
            <i className="fas fa-sort"></i>
          )}
        </div>
      </div>
      <div
        style={{
          width: '20%',
        }}
      >
        Last Task
      </div>
      <div
        style={{
          width: '20%',
        }}
      >
        Groups
      </div>
      <div
        style={{
          width: '15%',
        }}
      >
        Last Seen
      </div>
      <div
        style={{
          width: '3%',
        }}
      >
        Action
      </div>
    </div>
  );
  const listAction = [
    'Online',
    'Logout',
    'Waiting',
    'Inspecting',
    'Checking',
    'Cross-Checking',
    'Validating',
  ];
  let tblBody;
  if (data.length > 0) {
    tblBody = data.map((item) => {
      return (
        <UserListTableItem
          key={`User_item_${item._id}`}
          data={item}
          timezone={timezone}
          showModalConfirm={showModalConfirm}
          putAgent={putAgent}
          role={user.role}
        />
      );
    });
  } else {
    tblBody = (
      <div>
        <div colSpan="5" className="text-secondary">
          <b>No account found.</b>
        </div>
      </div>
    );
  }

  return (
    <div className="Agents mt-3">
      <div className="groupSearch mb-3">
        <div
          className="searchUserName mr-3"
          style={{
            position: 'relative',
            width: '22%',
            display: 'inline-block',
          }}
        >
          <input
            style={{
              display: 'block',
              borderRadius: '7px',
              height: '45px',
            }}
            type="text"
            className="form-control"
            name="username"
            defaultValue={filters.username}
            onChange={onChange}
            placeholder="User Name"
          />
          <i
            className="fas fa-search"
            style={{
              position: 'absolute',
              top: '13px',
              right: '15px',
              fontSize: '17px',
            }}
          ></i>
        </div>
        {['SUPER', 'ADMIN', 'TRAINER'].includes(user.role) ? (
          <div className="searchRole" style={{ display: 'inline-block' }}>
            <select
              className="form-control"
              name="role"
              onChange={onChange}
              value={filters.role ? filters.role : ''}
              style={{ height: '45px', borderRadius: '7px' }}
            >
              <option key={`Loc_111`} value="">
                ALL
              </option>
              {listRole &&
                listRole.map((loc) => (
                  <option key={`Loc_${loc.value}`} value={loc.value}>
                    {loc.label}
                  </option>
                ))}
            </select>
          </div>
        ) : null}
        <div className="searchStatus ml-2" style={{ display: 'inline-block' }}>
          <select
            className="form-control"
            name="action"
            onChange={onChange}
            value={filters.action ? filters.action : ''}
            style={{ height: '45px', borderRadius: '7px' }}
          >
            <option key={`action_All`} value={''}>
              ALL
            </option>
            {listAction &&
              listAction.map((action) => (
                <option key={`status_${action}`} value={action}>
                  {action === 'Login' || action === 'Online'
                    ? 'ON'
                    : action === 'Logout'
                    ? 'OFF'
                    : action === 'Waiting'
                    ? 'WAITING'
                    : action === 'Inspecting'
                    ? 'INSPECTING'
                    : action === 'Checking'
                    ? 'Checking'
                    : action === 'Validating'
                    ? 'Validating'
                    : 'OFF'}
                </option>
              ))}
          </select>
        </div>
        {['SUPER', 'ADMIN', 'TRAINER'].includes(user.role) ? (
          <div
            className="search-shift ml-2"
            style={{ display: 'inline-block' }}
          >
            <select
              className="form-control"
              name="shift"
              onChange={onChange}
              value={filters.shift ? filters.shift : ''}
              style={{ height: '45px', borderRadius: '7px' }}
            >
              <option key={`shift_1111111`} value="">
                ALL
              </option>
              {listShifts &&
                listShifts.map((loc) => (
                  <option key={`shift_${loc.value}`} value={loc.value}>
                    {loc.label}
                  </option>
                ))}
            </select>
          </div>
        ) : null}
      </div>
      <div className="header mb-2">{tblHeaders}</div>
      <div className="body">{tblBody}</div>
    </div>
  );
};

UserListTable.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.object,
  onChange: PropTypes.func,
};

export default UserListTable;
