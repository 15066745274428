import React from 'react';
import PropTypes from 'prop-types';
import CageListTableItem from './CageListTableItem';
import {
  listMake,
  listBodyType,
  listYear,
  listStatusAdmin,
  listStatusAgent,
  listGroups,
} from '../../settings';

const CageListTable = ({ data, filters, sorts, onChange, onSort, user }) => {
  const tblHeaders = (
    <tr>
      <th width="7%" />
      <th
        width="6%"
        onClick={onSort}
        data-key="group"
        data-value={sorts.group || ''}
        className={`clickable ${sorts.group ? 'has-sort' : ''}`}
      >
        Type
      </th>
      <th
        width="10%"
        onClick={onSort}
        data-key="make"
        data-value={sorts.make || ''}
        className={`clickable ${sorts.make ? 'has-sort' : ''}`}
      >
        Make
      </th>
      <th
        width="15%"
        onClick={onSort}
        data-key="model"
        data-value={sorts.model || ''}
        className={`clickable ${sorts.model ? 'has-sort' : ''}`}
      >
        Model
      </th>
      <th
        width="7%"
        onClick={onSort}
        data-key="year"
        data-value={sorts.year || ''}
        className={`clickable ${sorts.year ? 'has-sort' : ''}`}
      >
        Year
      </th>
      <th width="10%">Body</th>
      <th width="15%">Trim</th>
      <th
        width="10%"
        onClick={onSort}
        data-key="code"
        data-value={sorts.code || ''}
        className={`clickable ${sorts.code ? 'has-sort' : ''}`}
      >
        Code
      </th>
      <th width="10%">Status</th>
      <th width="10%" />
    </tr>
  );

  let selectYears = [];
  for (let i = listYear.max; i >= listYear.min; i--) {
    selectYears.push(
      <option key={`Year_${i}`} value={i}>
        {i}
      </option>,
    );
  }

  const tblFilters = (
    <tr>
      <th />
      <th>
        <select
          className="form-control form-control-sm"
          name="group"
          defaultValue={filters.group}
          onChange={onChange}
        >
          <option value="">( all )</option>
          {listGroups.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </th>
      <th>
        <select
          className="form-control form-control-sm"
          name="make"
          defaultValue={filters.make}
          onChange={onChange}
        >
          <option value="">( all )</option>
          {listMake.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </th>
      <th>
        <input
          type="text"
          className="form-control form-control-sm"
          name="model"
          defaultValue={filters.model}
          onChange={onChange}
        />
      </th>
      <th>
        <select
          className="form-control form-control-sm"
          name="year"
          defaultValue={filters.year}
          onChange={onChange}
        >
          <option value="">( all )</option>
          {selectYears}
        </select>
      </th>
      <th>
        <select
          className="form-control form-control-sm"
          name="bodyType"
          defaultValue={filters.bodyType}
          onChange={onChange}
        >
          <option value="">( all )</option>
          {listBodyType.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </th>
      <th>
        <input
          type="text"
          className="form-control form-control-sm"
          name="trim"
          defaultValue={filters.trim}
          onChange={onChange}
        />
      </th>
      <th>
        <input
          type="text"
          className="form-control form-control-sm"
          name="code"
          defaultValue={filters.code}
          onChange={onChange}
        />
      </th>
      <th>
        <select
          className="form-control form-control-sm"
          name="status"
          defaultValue={filters.status}
          onChange={onChange}
        >
          <option value="">( all )</option>
          {user.role !== 'AGENT'
            ? listStatusAdmin.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))
            : listStatusAgent.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
        </select>
      </th>
    </tr>
  );

  let tblBody;
  if (data.length > 0) {
    tblBody = data.map((item) => {
      return (
        <CageListTableItem
          key={`Cage_item_${item._id}`}
          data={item}
          user={user}
        />
      );
    });
  } else {
    tblBody = (
      <tr>
        <td colSpan="9" className="text-secondary">
          <b>No cage found.</b>
        </td>
      </tr>
    );
  }

  return (
    <table id="Cage_list_table" className="table table-sm table-bordered">
      <thead className="thead-dark">{tblHeaders}</thead>
      <thead className="thead-light">{tblFilters}</thead>
      <tbody>{tblBody}</tbody>
    </table>
  );
};

CageListTable.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.object,
  onChange: PropTypes.func,
};

export default CageListTable;
