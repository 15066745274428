import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ModalShowErr from '../../inspect/containers/components/ModalShowErr';
import { statusProcess, getMission } from './../../inspect/operations';
import { logout } from '../../auth/operations';
const NotiInspect = (props) => {
	let { inspection } = props;
	let { status } = props.inspection;
	let [color, setcolor] = useState('');
	const [showErr, setshowErr] = useState(false);
	const [messErr, setmessErr] = useState('');
	const [showWarning, setshowWarning] = useState(false);
	let unAuthor =
		inspection &&
		inspection.error &&
		inspection.error.response &&
		inspection.error.response.data
			? inspection.error.response.data
			: null;
	const error =
		inspection &&
		inspection.error &&
		inspection.error.response &&
		inspection.error.response.data &&
		inspection.error.response.data.code
			? inspection.error.response.data.code
			: '';
	let timer;
	let onLineOut = async () => {
		props.statusStart();
		clearInterval(timer);
	};
	useEffect(() => {
		if (status === 'Process') {
			setcolor('#2ed573');
		} else if (status === 'Waiting') {
			setcolor('#ffa502');
			// eslint-disable-next-line
			timer = setInterval(() => {
				props.getMission();
			}, 2000);
			if (unAuthor === 'Unauthorized' || error === 'SESSION_EXPIRED') {
				setmessErr('YOUR ACCOUNT HAS BEEN LOGIN SOMEWHERE ELSE');
				setshowErr(true);
				clearInterval(timer);
			}
			if (error === 'SHIFT_OFF' || error === 'NO_SCHEDULE') {
				const mess =
					inspection &&
					inspection.error &&
					inspection.error.response &&
					inspection.error.response.data &&
					inspection.error.response.data.message
						? inspection.error.response.data.message
						: '??????';
				clearInterval(timer);
				setmessErr(mess);
				setshowWarning(true);
			}
			if (
				props.inspection.task.task &&
				props.inspection.task.taskSlot &&
				props.inspection.task.taskSlot.photoCode
			) {
				props.statusProcess();
			}
		} else {
			setcolor('#321640');
		}
		return () => {
			clearInterval(timer);
		};
	}, [color, status, props.inspection.task.task, error, unAuthor]);
	return (
		<div id="Noti" style={{ right: '100px', background: `${color}` }}>
			<i
				className={`fas fa-bug ${
					status === 'Waiting' ? 'rotate' : null
				}`}
			></i>
			{status === 'Process' && (
				<div className="noti-bell">
					<i className="fas fa-bell"></i>
				</div>
			)}
			{showErr ? (
				<ModalShowErr
					messErr={messErr}
					resetError={() => props.logout()}
				/>
			) : null}
			{showWarning ? (
				<ModalShowErr
					messErr={messErr}
					resetError={() => onLineOut()}
				/>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ui: state.ui,
	inspection: state.inspect,
});

export default connect(mapStateToProps, {
	statusProcess,
	getMission,
	logout,
})(NotiInspect);
