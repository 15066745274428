import actions from './actions';
import * as client from '../../utils/client';
let agentAPI =
  process.env.REACT_APP_AGENT_BASE_URL || 'https://agent.paveapi.com';
export const getSettings = () => {
  return (dispatch) =>
    client.exec('get', `${agentAPI}/api/users/getSettings`, {}, function (res) {
      dispatch(actions.getSettings(res));
    });
};

export const setPassword = (data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/users/setPassword`,
      data,
      function (res) {
        dispatch(actions.setPassword(res));
      },
    );
};
export const setNotification = (data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/users/updateNotification`,
      data,
      function (res) {
        dispatch(actions.setNotification(res));
      },
    );
};
export const setSetting = (data) => {
  return (dispatch) =>
    client.exec('put', `${agentAPI}/api/users/setSetting`, data, function () {
      dispatch(actions.setSetting(data));
    });
};
