import React from 'react';
import PropTypes from 'prop-types';

const InspectionViewReport = ({ data, show }) => {
  const damages = data?.inspection?.detected_damages?.filter(
    (item) => item.status !== 'repaired',
  );
  const grading =
    data.inspection && data.inspection.grading ? data.inspection.grading : {};
  let damageView = [];
  for (let i = 0; i < damages.length; i++) {
    const damage = damages[i];
    const damageClass =
      damage.user_response === 'reject' ? 'text-logan text-strike' : '';
    damageView.push(
      <tr key={`Damage_${i}`} className={damageClass}>
        <td className="text-head text-secondary">{damage.grade_score}</td>
        <td>
          {damage.component} {damage.label}
        </td>
        <td>{damage.damage_group}</td>
        <td>
          <small>{damage.material || ''}</small>
        </td>
        <td>
          <small>{damage.description || damage.label}</small>
        </td>
        <td>
          <small>{damage.tolerance}</small>
        </td>
        <td>
          {damage.repair_method}
          <br />
          {damage.repair_type}
        </td>
        <td>{damage.unit_measure}</td>
      </tr>,
    );
  }
  return (
    <div id="Inspection_view_report" className="card">
      <div
        className="card-header clickable"
        id="Inspection_view_report_head"
        data-toggle="collapse"
        data-target="#Inspection_view_report_content"
        aria-expanded="true"
        aria-controls="#Inspection_view_report_content"
      >
        Report Data
      </div>
      <div
        id="Inspection_view_report_content"
        className={`collapse ${show ? 'show' : ''}`}
        aria-labelledby="Inspection_view_report_head"
      >
        <div className="card-body">
          <div className="row mb-4">
            <div className="col">
              <h6 className="small text-logan text-uppercase mb-1">
                Grading Standard A
              </h6>
              <div className="display-4 text-primary text-uppercase">
                {grading.standard_A}
              </div>
            </div>
            <div className="col">
              <h6 className="small text-logan text-uppercase mb-1">
                Grading Standard B
              </h6>
              <div className="display-4 text-primary text-uppercase">
                {grading.standard_B}
              </div>
            </div>
            <div className="col">
              <h6 className="small text-logan text-uppercase mb-1">
                Grading Standard C
              </h6>
              <div className="display-4 text-primary text-uppercase">
                {grading.standard_C}
              </div>
            </div>
          </div>
          <table id="Detected_damages" className="table table-sm">
            <thead>
              <tr>
                <th />
                <th>Detected Damages</th>
                <th>Group</th>
                <th width="5%">Material</th>
                <th width="20%">Description</th>
                <th width="20%">Tolerance</th>
                <th>Repair</th>
                <th>Unit Measure</th>
              </tr>
            </thead>
            <tbody>{damageView}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

InspectionViewReport.propTypes = {
  data: PropTypes.object.isRequired,
};

InspectionViewReport.defaultProps = {
  show: true,
};

export default InspectionViewReport;
