import React from 'react';

const SpinnerIcon = () => {
  return (
    <div id="Spinner_icon">
      <div>
        <i className="fas fa-car" />
        <i className="fas fa-user-secret" />
        <i className="fas fa-image" />
        <div />
      </div>
    </div>
  );
};
export default SpinnerIcon;
