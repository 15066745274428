import React, { useEffect, useState } from 'react';
const DataTrainer = (props) => {
  const { data } = props;
  const listData = data.listData ? data.listData : false;
  const [dataTrainer, setdataTrainer] = useState([]);
  useEffect(() => {
    let trainerList = [];
    if (typeof listData === 'object') {
      Object.keys(listData).map((key) => {
        const item = listData[key];
        return trainerList.push({
          username: key,
          inspector: item.inspector,
          qa: item.qa,
          support: item.support,
          verified: item.verified,
          review: item.review,
        });
      });
    }
    setdataTrainer(trainerList);
  }, [data]);
  return (
    <div className="mt-2">
      <div className="table">
        <div
          className="d-flex"
          style={{
            padding: '5px 10px',
            fontSize: '12px',
          }}
        >
          <div className="text-head" style={{ width: '10%' }}></div>
          <div className="text-head" style={{ width: '18%' }}>
            Verified
          </div>
          <div className="text-head" style={{ width: '18%' }}>
            Inspector
          </div>
          <div className="text-head" style={{ width: '18%' }}>
            Qa
          </div>
          <div className="text-head" style={{ width: '18%' }}>
            Support
          </div>
          <div className="text-head" style={{ width: '18%' }}>
            Review
          </div>
        </div>
      </div>
      <div style={{ borderRadius: '15px' }} className="text-head">
        {dataTrainer.length > 0 ? (
          dataTrainer.map((item, index) => {
            return (
              <div
                key={`listdata_${index}`}
                className={`mb-2 d-flex `}
                style={{
                  padding: '9px 10px',
                  borderRadius: '15px',
                  alignItems: 'center',
                  position: 'relative',
                  background: '#fff',
                }}
              >
                <div className="text-head" style={{ width: '10%' }}>
                  {item.username}
                </div>
                <div className="text-head" style={{ width: '18%' }}>
                  {item.verified || 0}
                </div>
                <div className="text-head" style={{ width: '18%' }}>
                  {item.inspector || 0}
                </div>
                <div className="text-head" style={{ width: '18%' }}>
                  {item.qa || 0}
                </div>
                <div className="text-head" style={{ width: '18%' }}>
                  {item.support || 0}
                </div>
                <div className="text-head" style={{ width: '18%' }}>
                  {item.review || 0}
                </div>
              </div>
            );
          })
        ) : (
          <div>
            <div>
              <i>No data to display.</i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataTrainer;
