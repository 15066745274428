import { default as List } from './CageList';
import { default as View } from './CageView';
import { default as New } from './CageNew';
import { default as Delete } from './CageDelete';
import { default as CageLabel } from './CageLabel';
const Cage = {
	List,
	View,
	New,
	Delete,
	CageLabel,
};

export default Cage;
