import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CageListTableItem = ({ data, user }) => {
  return (
    <tr>
      <td>
        <div className="btn-group">
          <Link to={`/cageView/${data._id}`} className="btn btn-tiny btn-light">
            <i className="fa fa-edit" />
          </Link>
          {user.role !== 'AGENT' ? (
            <Link
              to={`/cageCreate/${data._id}`}
              className="btn btn-tiny btn-light"
            >
              <i className="fa fa-copy" />
            </Link>
          ) : null}
        </div>
      </td>
      <td>{data.group}</td>
      <td>{data.make}</td>
      <td>{data.model}</td>
      <td>{data.year}</td>
      <td>{data.bodyType}</td>
      <td>
        <small>{data.trim}</small>
      </td>
      <td>{data.code}</td>
      <td>{data.status}</td>
      <td className="text-right">
        <div className="badge badge-secondary ml-1">
          <i className="fa fa-pencil-alt" />
          <span className="ml-1">{data.cageNotes.length}</span>
        </div>
        {/* <div className="badge badge-secondary ml-1">
					<i className="fa fa-history" />
					<span className="ml-1">{data.cageHistories.length }</span>
				</div> */}
      </td>
    </tr>
  );
};

CageListTableItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CageListTableItem;
