import React from 'react';

const Modal = ({
	title,
	description,
	clickAccept,
	clickFinish,
	clickReject,
	clickNo
}) => {
	const checkFunc = () => {
		if (title === 'Finish') {
			return (
				<button
					type="button"
					className="btn btn-success"
					onClick={clickFinish}
				>
					{title}
				</button>
			);
		} else if (title === 'Accept') {
			return (
				<button
					type="button"
					className="btn btn-success"
					onClick={clickAccept}
				>
					{title}
				</button>
			);
		} else {
			return (
				<button
					type="button"
					className="btn btn-danger"
					onClick={clickReject}
				>
					{title}
				</button>
			);
		}
	};
	return (
		<div className="modal fade show show-modal-pub ">
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Image</h5>
						<button
							type="button"
							className="close"
							onClick={clickNo}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<p>{description}</p>
					</div>
					<div className="modal-footer">
						{checkFunc()}
						<button
							type="button"
							className="btn btn-primary"
							onClick={clickNo}
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Modal;
