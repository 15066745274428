import React from 'react';

const Action = (props) => {
  const { taskFailed, user, deleteTaskFailed, taskSetting, setqaData } = props;
  const role = user && user.role ? user.role : '';
  const username = user && user.username ? user.username : '';
  let listReason = [];
  if (
    taskSetting &&
    taskSetting.accepts.length > 0 &&
    taskSetting.rejects.length > 0
  ) {
    listReason = [...taskSetting.accepts, ...taskSetting.rejects];
  }
  const qa =
    taskFailed && taskFailed.qa && taskFailed.qa.username
      ? taskFailed.qa.username
      : '';
  const qaData = taskFailed && taskFailed.qaData ? taskFailed.qaData : false;
  if (role === 'AGENT') {
    return null;
  }
  if (qaData) {
    return null;
  }
  if (['ADMIN', 'QA'].includes(role) && qa !== username) {
    return null;
  }
  const updateMistake = (e) => {
    const value = e.target.value;
    if (!value) {
      setqaData(false);
      return;
    }
    const messageData = [
      {
        name:
          taskFailed && taskFailed.infor && taskFailed.infor.name
            ? taskFailed.infor.name
            : '',
        data: value,
        action: 'inspector',
      },
    ];
    const data = {
      messageData: messageData,
    };
    setqaData({ ...data });
  };

  return (
    <div className="group-button">
      <div
        className="title mb-2"
        style={{
          fontSize: '18px',
          background: '#321640',
          color: 'white',
          borderRadius: '6px',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Action
      </div>
      {listReason.length > 0 ? (
        <select
          onChange={(e) => updateMistake(e)}
          className="custom-select mb-2"
          name="status"
        >
          <option key="reject_99" value="">
            Default
          </option>
          {listReason.map((item, i) => {
            return (
              <option key={`reject_${i}`} value={`${item.description}`}>
                {item.name.replace(/_/g, ' ')}
              </option>
            );
          })}
        </select>
      ) : null}
      {taskFailed && taskFailed.infor.type === 'QC' && qa === user.username ? (
        <button
          type="button"
          onClick={() => deleteTaskFailed(taskFailed._id)}
          className={`btn btn-primary mb-2 mr-1`}
        >
          Skip
        </button>
      ) : null}
    </div>
  );
};

export default Action;
