import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import QaData from './QaData';
const TaskFailedTableItem = ({ data, timezone, deleteTaskFailed, user }) => {
  const ref = useRef(null);
  const [height, setheight] = useState();
  const [checkHeight, setcheckHeight] = useState(false);
  const [showData, setshowData] = useState(false);
  const newTab = (id) => {
    const link = window.location.origin;
    let url = link + `/inspectionView/${id}`;
    const win = window.open(url, '_blank');
    win.focus();
  };
  useEffect(() => {
    if (!checkHeight) {
      setheight(ref.current.clientHeight);
      setcheckHeight(true);
    }
  }, []);
  const createdAt = data.createdAt
    ? moment(data.createdAt).tz(timezone).format('DD/MM/YY hh:mm:ss A')
    : null;
  const finishedAt = data.finishedAt
    ? moment(data.finishedAt).tz(timezone).format('DD/MM/YY hh:mm:ss A')
    : null;
  const damage = () => {
    const qaData = data && data.qaData ? data.qaData : false;
    const vehicle = data && data.vehicle ? data.vehicle : false;
    const vehData = vehicle && vehicle.data ? vehicle.data : false;
    return <QaData user={user} qaData={qaData} vehData={vehData} view={true} />;
  };
  const status = (status) => {
    let icon;
    let backGround = '';
    let color = '';
    switch (data.status) {
      case 'INSPECTOR-REVIEWING':
        backGround = '#f1c40f';
        icon = <i className="fas fa-pen"></i>;
        color = 'white';
        break;
      case 'QA-REVIEWING':
        backGround = '#f1c40f';
        icon = <i className="fas fa-pen"></i>;
        color = 'white';
        break;
      case 'VERIFIED':
        backGround = '#2ecc71';
        icon = <i className="fas fa-check"></i>;
        color = 'white';
        break;
      case 'VERIFYING':
        backGround = '#321640';
        icon = <i className="fas fa-question"></i>;
        color = 'white';
        break;
      default:
        backGround = '#95a5a6';
        icon = <i className="fas fa-question"></i>;
        color = 'white';
        break;
    }
    return (
      <div className="status" style={{ display: 'inline-block' }}>
        <div
          style={{
            background: backGround,
            color: color,
            display: 'flex',
            fontSize: '17px',
            padding: '8px 12px',
            fontWeight: 'bold',
          }}
          className="badge text-uppercase"
        >
          {icon}
          <div className="status ml-2">{status ? status : 'Unknown'}</div>
        </div>
      </div>
    );
  };
  return (
    <div
      className="mb-2"
      style={{
        border: '1px solid #fff',
        background: '#fff',
        borderRadius: '10px',
        padding: '5px 0',
        height: showData ? '' : '120px',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          height: !checkHeight || showData ? '' : '100px',
          overflow: showData ? '' : 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '9%',
          }}
        >
          <div
            style={{
              fontSize: '15px',
              padding: '5px 7px',
              fontWeight: 'bold',
              borderRadius: '5px',
            }}
          >
            {data.qa && data.qa.username ? data.qa.username : ''}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '9%',
            fontSize: '15px',
          }}
        >
          {data.inspector && data.inspector.username
            ? data.inspector.username
            : ''}
        </div>
        <div
          style={{
            width: '12%',
          }}
        >
          <div className="btn btn-tiny btn-warning mr-1" disabled>
            <span className="text-sm">{data.infor.photoCode}</span>
          </div>
          <div
            style={{
              background: '#2ecc71',
              padding: '6px 10px',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
            className="badge badge-success text-uppercase mr-2"
          >
            {data.infor.name}
          </div>
        </div>
        <div
          style={{
            width: '15%',
          }}
        >
          {status(data.status)}
        </div>
        <div
          ref={ref}
          style={{
            width: '24%',
            padding: '0 15px 0 0',
          }}
        >
          {damage()}
        </div>
        <div
          style={{
            width: '12%',
            display: 'flex',
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
          className="text-primary"
          onClick={() => newTab(data.infor.sessionID)}
        >
          {data.infor.sessionID}
        </div>
        <div
          style={{
            width: '11%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {createdAt && (
            <small>
              <i className="fa fa-fw fa-play text-primary mr-2"></i>
              {createdAt}
            </small>
          )}

          {finishedAt && (
            <small>
              <i className="fa fa-fw fa-edit text-secondary mr-2"></i>
              {finishedAt}
            </small>
          )}
        </div>
        <div
          style={{
            width: '8%',
          }}
        >
          <div
            className="mr-1 mb-1"
            style={{
              padding: '3px 7px',
              borderRadius: '5px',
              background: '#321640',
              display: 'inline-block',
            }}
          >
            <Link
              to={`/performance/listTaskFailed/${data._id}`}
              className="text-head"
            >
              <i className="fas fa-eye text-white"></i>
            </Link>
          </div>
          {['TRAINER', 'SUPER'].includes(user.role) && (
            <div
              className="mr-1 mb-1"
              style={{
                padding: '3px 7px',
                borderRadius: '5px',
                background: 'red',
                cursor: 'pointer',
                display: 'inline-block',
              }}
              onClick={() => deleteTaskFailed(data._id)}
            >
              <i className="fas fa-times text-white"></i>
            </div>
          )}
          {height > 102 ? (
            <div
              className="mr-1 mb-1"
              style={{
                padding: '3px 7px',
                borderRadius: '5px',
                background: '#321640',
                cursor: 'pointer',
                display: 'inline-block',
              }}
              onClick={() => setshowData(!showData)}
            >
              {!showData ? (
                <i className="fas fa-angle-down text-white"></i>
              ) : (
                <i className="fas fa-angle-up text-white"></i>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TaskFailedTableItem;
