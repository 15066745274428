import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import { DateRangePicker } from '../../../ui/components/Form';
import moment from 'moment';
import { durationText } from '../../../../utils/string';
const defaultTimezone = 'UTC +0';

const StatsTable = (props) => {
  const { items, today, onChangeDate, type, onDownload } = props;
  const [list, setList] = useState(Array);
  const [order, setOrder] = useState({ key: 'total', type: 'desc' });
  const [query, setQuery] = useState(today);
  const [errors, setErrors] = useState(Object);
  const [maxDays, setMaxDays] = useState(365);
  useEffect(() => {
    let tmpList = [];
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        let dataReturn = {
          detected_damages: item?.detected_damages ?? {},
          username: item?.username,
          totalSession: item?.totalSession,
          totalTaskSlot: item?.totalTaskSlot,
          time: item.timeInspect,
          auto: item.auto,
        };
        if (type === 'CHECKER') {
          dataReturn = {
            ...dataReturn,
            time: item.timeCheck,
          };
        }
        const groups = item?.detected_damages?.groups ?? null;
        const listData = [];

        if (groups) {
          for (const key in groups) {
            let data = {};
            const listComponents = [];
            data.group = key;
            data.totalDamages = groups[key].totalDamages;
            data.totalDamagesInspect = groups[key].totalDamagesInspect;
            data.totalComponents = groups[key].totalComponents;
            if (type === 'CHECKER') {
              data.totalDamagesCheck = groups[key].totalDamagesCheck;
              data.totalDamagesReject = groups[key].totalDamagesReject;
            }
            const components = groups[key]?.components ?? null;
            if (components) {
              const listDamages = [];
              for (const keyComponent in components) {
                listComponents.push(keyComponent);
                const damages = components[keyComponent]?.damages ?? null;
                if (damages) {
                  for (const keyDamage in damages) {
                    listDamages.push(keyDamage);
                  }
                  data.damages = listDamages;
                }
              }
              data.components = listComponents;
            }
            listData.push({ ...data, show: false });
          }
        }
        tmpList.push({
          ...dataReturn,
          listData,
        });
      }

      tmpList = orderBy(tmpList, order.key, order.type);
      setList(tmpList);
    }
  }, [items, order]);
  const onChangeScheduleData = (e) => {
    setQuery({ ...query, [e.target.name]: e.target.value });
  };
  const date = new Date();
  const lastDay = new Date(date);
  const lastTime = new Date(date);
  lastDay.setDate(date.getDate() - 1);
  lastTime.setHours(date.getHours() - 1);
  const onViewStats = (e) => {
    e.preventDefault();
    const { from, to } = query.range || {};
    const start = moment(from || new Date());
    const end = moment(to || new Date());
    const days = end.diff(start, 'days');
    if (days > maxDays) {
      setErrors({
        ...errors,
        range: `Date range exceeded maximum of ${maxDays} days.`,
      });
    } else {
      setErrors({ ...errors, range: null });
      onChangeDate({ from, to });
    }
  };

  const toggleOrder = (key) => {
    if (key === order.key) {
      setOrder({
        ...order,
        type: order.type === 'desc' ? 'asc' : 'desc',
      });
    } else {
      setOrder({ key, type: 'desc' });
    }
  };
  const handleOnOffTab = (indexEachUser, group, indexEachTab) => {
    const listEachUser = list.map((item, indexList) => {
      const listDataEachTab = item.listData.map((itemData, indexData) => {
        return {
          ...itemData,
          show:
            indexList === indexEachUser &&
            indexData === indexEachTab &&
            itemData.group === group
              ? true
              : false,
        };
      });
      return {
        ...item,
        listData: listDataEachTab,
      };
    });
    setList(listEachUser);
  };
  return (
    <div>
      <p className="small text-muted font-italic mb-3">
        (*) Stats are generated based on "{defaultTimezone}" timezone. Maximum
        filter range is
        <span className="text-head" onClick={() => setMaxDays(maxDays)}>
          {maxDays} days
        </span>
        .
      </p>
      <div className="row">
        <div className="col-12 col-md-4">
          <DateRangePicker
            name="range"
            onChange={onChangeScheduleData}
            error={errors.range}
            maxDate={lastDay}
            maxTime={lastTime}
          ></DateRangePicker>
        </div>
        <div className="col-12 col-md-8">
          <div className="mb-1">&nbsp;</div>
          <button className="btn btn-warning" onClick={onViewStats}>
            View Stats
          </button>
          <button
            className="btn btn-dark ml-4"
            onClick={() => onDownload(list, type)}
          >
            Download
          </button>
        </div>
      </div>
      <div className="data">
        <div
          className="header d-flex text-head"
          style={{
            padding: '8px 0',
            alignItems: 'center',
            background: '#321640',
            color: '#fff',
            borderRadius: '5px',
          }}
        >
          <div
            style={{ width: '10%', padding: '0.3rem', paddingLeft: '10px' }}
            onClick={() => {
              toggleOrder('username');
            }}
          >
            Username
          </div>
          <div style={{ width: '7%', padding: '0.3rem', paddingLeft: '10px' }}>
            Sessions
          </div>
          <div style={{ width: '12%', padding: '0.3rem' }} data-key="status">
            {type === 'CHECKER' ? 'Time Check' : 'Time Inspect'}
          </div>
          <div style={{ width: '7%', padding: '0.3rem' }} data-key="status">
            Auto
          </div>
          <div style={{ width: '7%', padding: '0.3rem' }} data-key="status">
            TaskSlots
          </div>
          <div style={{ width: '58%', padding: '0.3rem' }}>Damages</div>
        </div>

        {list.map((item, indexList) => {
          return (
            <div
              className="item d-flex text-head mt-1 py-2"
              key={'inspector_' + indexList}
              style={{
                border: '2px solid #49496a',
                borderRadius: '10px',
              }}
            >
              <div
                className="d-flex align-items-center"
                style={{ width: '10%', padding: '0.3rem', paddingLeft: '10px' }}
              >
                <span className="mr-1">
                  <i className="fas fa-user-secret"></i>
                </span>
                <span>{item.username}</span>
              </div>
              <div
                className="d-flex align-items-center"
                style={{ width: '7%', padding: '0.3rem' }}
              >
                <span className="mr-1">
                  <i className="fas fa-clipboard-list"></i>
                </span>
                <span>{item.totalSession}</span>
              </div>
              <div
                className="d-flex align-items-center"
                style={{ width: '12%', padding: '0.3rem' }}
              >
                <span className="mr-1">
                  <i className="fas fa-user-clock"></i>
                </span>
                <span>{durationText(item.time)}</span>
              </div>
              <div
                className="d-flex align-items-center"
                style={{ width: '7%', padding: '0.3rem' }}
              >
                <span className="mr-1">
                  <i className="fas fa-robot"></i>
                </span>
                <span>{item.auto}</span>
              </div>
              <div
                className="d-flex align-items-center"
                style={{ width: '7%', padding: '0.3rem' }}
              >
                <span className="mr-1">
                  <i className="fas fa-user-edit"></i>
                </span>
                <span>{item.totalTaskSlot}</span>
              </div>
              <div
                style={{
                  width: '58%',
                }}
              >
                <div className="total">
                  <div className="d-block">
                    <div
                      className="badge badge-dark mb-1"
                      style={{
                        fontSize: '18px',
                      }}
                    >
                      Total
                    </div>
                  </div>
                  <div className="d-inline-block mr-3">
                    <div className="d-flex align-items-center">
                      <span className="badge badge-dark mr-2">
                        <span className="mr-1">
                          <i className="fas fa-layer-group"></i>
                        </span>
                        Groups
                      </span>

                      <span>{item?.detected_damages?.totalGroups ?? 0}</span>
                    </div>
                  </div>
                  <div className="d-inline-block mr-3">
                    <div className="d-flex align-items-center">
                      <span className="badge badge-dark mr-2">
                        <span className="mr-1">
                          <i className="fas fa-car-side"></i>
                        </span>
                        Components
                      </span>

                      <span>
                        {item?.detected_damages?.totalComponents ?? 0}
                      </span>
                    </div>
                  </div>
                  <div className="d-inline-block mr-3">
                    <div className="d-flex align-items-center">
                      <span className="badge badge-dark mr-2">
                        <span className="mr-1">
                          <i className="fas fa-house-damage"></i>
                        </span>
                        Damages
                      </span>

                      <span>{item?.detected_damages?.totalDamages ?? 0}</span>
                    </div>
                  </div>
                  <div className="d-inline-block mr-2">
                    <div className="d-flex align-items-center">
                      <span className="badge badge-dark mr-2">
                        <span className="mr-1">
                          <i className="fas fa-car-crash"></i>
                        </span>
                        Damages Inspect
                      </span>

                      <span>
                        {item?.detected_damages?.totalDamagesInspect ?? 0}
                      </span>
                    </div>
                  </div>
                  {type === 'CHECKER' ? (
                    <div className="d-inline-block mr-2">
                      <div className="d-inline-block mr-2">
                        <div className="d-flex align-items-center">
                          <span className="badge badge-dark mr-2">
                            <span className="mr-1">
                              <i className="fas fa-car-crash"></i>
                            </span>
                            Damages Check
                          </span>

                          <span>
                            {item?.detected_damages?.totalDamagesCheck ?? 0}
                          </span>
                        </div>
                      </div>
                      <div className="d-inline-block">
                        <div className="d-flex align-items-center">
                          <span className="badge badge-dark mr-2">
                            <span className="mr-1">
                              <i className="fas fa-car-crash"></i>
                            </span>
                            Damages Reject
                          </span>

                          <span>
                            {item?.detected_damages?.totalDamagesReject ?? 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="detail-damages mt-1">
                  <div className="row">
                    <div className="col-3">
                      <div
                        className="card-header"
                        id="dash-board-progress_head"
                        style={{
                          borderRadius: '10px',
                        }}
                      >
                        {item.listData.map((item, indexData) => {
                          let s = '';
                          if (['TIRE', 'WHEEL'].includes(item.group)) {
                            s = 'tire';
                          }
                          if (item.group === 'BODY') {
                            s = 'body';
                          }
                          if (item.group === 'PART') {
                            s = 'part';
                          }
                          if (item.group === 'INTERIOR') {
                            s = 'interior';
                          }
                          if (item.group === 'GLASS') {
                            s = 'glass';
                          }
                          return (
                            <div className="mb-2" key={`Group_` + indexData}>
                              <span
                                className={`menu d-block ${
                                  item.show ? 'active' : ''
                                }`}
                                onClick={() => {
                                  handleOnOffTab(
                                    indexList,
                                    item.group,
                                    indexData,
                                  );
                                }}
                              >
                                <div className="mr-2 d-inline">
                                  <img
                                    style={{
                                      width: '25px',
                                      display: 'inline-block mr-2',
                                    }}
                                    alt={s}
                                    src={`/img/groups/${s}.png`}
                                  ></img>
                                </div>
                                {item.group}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-9">
                      {item.listData.map((itemData, indexData) => {
                        if (!itemData.show) {
                          return null;
                        }
                        return (
                          <div key={`item_${itemData.group}_${indexData}`}>
                            <div className="total">
                              <div className="d-block">
                                <div
                                  className="badge badge-dark mb-1"
                                  style={{
                                    fontSize: '18px',
                                  }}
                                >
                                  Total
                                </div>
                              </div>
                              <div className="d-inline-block mr-3">
                                <div className="d-flex align-items-center">
                                  <span className="badge badge-dark mr-2">
                                    <span className="mr-1">
                                      <i className="fas fa-car-side"></i>
                                    </span>
                                    Components
                                  </span>

                                  <span>{itemData?.totalComponents ?? 0}</span>
                                </div>
                              </div>
                              <div className="d-inline-block mr-3">
                                <div className="d-flex align-items-center">
                                  <span className="badge badge-dark mr-2">
                                    <span className="mr-1">
                                      <i className="fas fa-house-damage"></i>
                                    </span>
                                    Damages
                                  </span>

                                  <span>{itemData?.totalDamages ?? 0}</span>
                                </div>
                              </div>
                              <div className="d-inline-block mr-2">
                                <div className="d-flex align-items-center">
                                  <span className="badge badge-dark mr-2">
                                    <span className="mr-1">
                                      <i className="fas fa-car-crash"></i>
                                    </span>
                                    Damages Inspect
                                  </span>

                                  <span>
                                    {itemData?.totalDamagesInspect ?? 0}
                                  </span>
                                </div>
                              </div>
                              {type === 'CHECKER' ? (
                                <div className="d-inline-block mr-2">
                                  <div className="d-inline-block mr-2">
                                    <div className="d-flex align-items-center">
                                      <span className="badge badge-dark mr-2">
                                        <span className="mr-1">
                                          <i className="fas fa-car-crash"></i>
                                        </span>
                                        Damages Check
                                      </span>

                                      <span>
                                        {itemData?.totalDamagesCheck ?? 0}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-inline-block">
                                    <div className="d-flex align-items-center">
                                      <span className="badge badge-dark mr-2">
                                        <span className="mr-1">
                                          <i className="fas fa-car-crash"></i>
                                        </span>
                                        Damages Reject
                                      </span>

                                      <span>
                                        {itemData?.totalDamagesReject ?? 0}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            <div className="list mt-2">
                              <div className="row">
                                <div className="col-6">
                                  <div>
                                    <span className="badge badge-dark mr-2">
                                      <span className="mr-1">
                                        <i className="fas fa-car-side"></i>
                                      </span>
                                      Components
                                    </span>
                                  </div>
                                  {itemData.components.map((item, index) => {
                                    return (
                                      <div
                                        key={`component_${item}_${index}`}
                                        className="badge badge-primary mr-1 mb-1"
                                      >
                                        {item}
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="col-6">
                                  <div>
                                    <span className="badge badge-dark mr-2">
                                      <span className="mr-1">
                                        <i className="fas fa-car-side"></i>
                                      </span>
                                      Damages
                                    </span>
                                  </div>
                                  {itemData.damages.map((item, index) => {
                                    return (
                                      <div
                                        key={`damage${item}_${index}`}
                                        className="badge badge-success mr-1 mb-1"
                                      >
                                        {item}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StatsTable;
