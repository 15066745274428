import * as types from './types';

const initialState = {
  agentStats: [],
  inspectors: [],
  checkers: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_AGENT_STATS:
      return {
        ...state,
        agentStats: action.payload,
      };
    case types.GET_INSPECTORS:
      return {
        ...state,
        inspectors: action.payload,
      };
    case types.GET_CHECKERS:
      return {
        ...state,
        checkers: action.payload,
      };
    default:
      return state;
  }
}
