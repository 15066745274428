import React from 'react';
import { Link } from 'react-router-dom';

const LinkIntrucstion = ({ link, icon, text }) => {
	return (
		<div className="box">
			<div className="box-move" />
			<Link to={link}>
				<div className="group">
					<i className={`${icon}`} />
					<span className="text-head">{text}</span>
				</div>
			</Link>
		</div>
	);
};

export default LinkIntrucstion;
