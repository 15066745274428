import React, { useState, useEffect } from 'react';
import _orderBy from 'lodash/orderBy';
import ModalMD from './ModalMD';
import ModalInspect from './ModalInspect';
import RejectData from '../../widgets/RejectData';
import ModalFinal from './ModalFinal';
import ProcessData from '../../widgets/ProcessData';

const DetailTask = (props) => {
  const {
    tasks,
    inspection,
    user,
    kickAgent,
    photosSetting,
    resetInspect,
    listImages,
    inspectAllTask,
    qcPassed,
    setshowValidateSuccess,
  } = props;
  const [listReject, setlistReject] = useState([]);
  const [listReport, setlistReport] = useState([]);
  const [taskSlotId, settaskSlotId] = useState();
  const [taskId, settaskId] = useState();
  const [showListReject, setshowListReject] = useState(false);
  const [showlistReport, setshowlistReport] = useState(false);
  const [showModalInspect, setshowModalInspect] = useState(false);
  const [showModalImage, setshowModalImage] = useState(false);
  const [showModalChangeTaskSlotStatus, setshowModalChangeTaskSlotStatus] =
    useState(false);
  const [modalOpenInspect, setmodalOpenInspect] = useState(false);
  const [dataTask, setdataTask] = useState({});
  const [dataValidate, setdataValidate] = useState({});
  const statusInspection = inspection?.status || '';

  const autoInspectAt = inspection.autoInspectAt
    ? new Date(inspection.autoInspectAt)
    : null;

  const endTime = autoInspectAt ? autoInspectAt.getTime() + 60000 : null;
  const time = new Date();
  const diff = endTime ? endTime - time.getTime() : null;
  const inSeconds = diff && diff > 0 ? Math.round(diff / 1000) : 0;
  const themeName = inspection?.inspectionThemes?.name ?? 'DEFAULT';

  const damage_table = inspection?.options?.damage_table ?? '';
  const itemReject = [];
  const itemReport = [];

  const qc_only_accept = inspection?.options?.qc_only_accept ?? null;
  const skip_vehicle_record_check =
    inspection?.options?.skip_vehicle_record_check ?? null;
  const skip_vehicle_data_check =
    inspection?.options?.skip_vehicle_data_check ?? null;
  const reject_vin_not_from_vehicle =
    inspection?.options?.reject_vin_not_from_vehicle ?? null;
  const qc_on_having_odometer =
    inspection?.options?.qc_on_having_odometer ?? null;

  const vehicle = inspection?.vehicle ?? null;
  const dataVehicle = vehicle?.data ?? null;
  for (let i = 0; i < listReport.length; i++) {
    const item = listReport[i];
    itemReport.push(
      <option key={`report_${i}`} value={`${item.name}`}>
        {item.name.replace(/_/g, ' ')}
      </option>,
    );
  }
  for (let i = 0; i < listReject.length; i++) {
    const item = listReject[i];
    if (
      qcPassed &&
      ['DEFAULT', 'DEF-1', 'DEF-2', 'DEF-3'].includes(inspection.group)
    ) {
      continue;
    }
    if (
      !qcPassed &&
      ['mismatched_record', 'mismatched_vehicle'].includes(item.name) &&
      ['AMZ', 'AMZ-1', 'AMZ-2', 'AMZ-3'].includes(inspection.group)
    ) {
      continue;
    }
    if (
      qcPassed &&
      !['mismatched_record', 'mismatched_vehicle'].includes(item.name)
    ) {
      continue;
    }
    if (skip_vehicle_data_check && item.name === 'mismatched_vehicle') {
      continue;
    }
    if (
      dataVehicle &&
      dataVehicle.odom_reading &&
      dataVehicle.odom_unit &&
      qc_on_having_odometer &&
      ['mismatched_vehicle', 'no_odometer'].includes(item.name)
    ) {
      continue;
    }
    if (skip_vehicle_record_check && item.name === 'mismatched_record') {
      continue;
    }
    if (
      !reject_vin_not_from_vehicle &&
      item.name === 'vin_not_from_vehicle' &&
      themeName !== 'CAPT'
    ) {
      continue;
    }
    itemReject.push(
      <option key={`reject_${i}`} value={`${item.name}`}>
        {item.name.replace(/_/g, ' ')}
      </option>,
    );
  }
  useEffect(() => {
    if (!showModalInspect) {
      resetInspect();
    }
  }, []);
  const showModalFinal = (task, url, agent, notGen, final) => {
    setdataTask({
      task: task,
      url: url,
      agent: agent ? agent : false,
      notGen: notGen ? notGen : false,
      final: final ? final : false,
      inspection: inspection,
    });
    let dataUpdate = {
      photo_id: task?.dataHitl?.photo_id ?? 0,
      photo_code: task?.dataHitl?.photo_code ?? 0,
    };
    const avn = task?.dataHitl?.avn_inspect ?? '';
    const avn_feedback = task?.dataHitl?.avn_feedback ?? 0;
    const vlp = task?.dataHitl?.vlp_inspect ?? '';
    const vlp_feedback = task?.dataHitl?.vlp_feedback ?? 0;
    if (avn) {
      dataUpdate = {
        ...dataUpdate,
        avn: avn,
      };
    }
    if (avn_feedback) {
      dataUpdate = {
        ...dataUpdate,
        avn_feedback: avn_feedback,
      };
    }
    if (vlp) {
      dataUpdate = {
        ...dataUpdate,
        vlp: vlp,
      };
    }
    if (vlp_feedback) {
      dataUpdate = {
        ...dataUpdate,
        vlp_feedback: vlp_feedback,
      };
    }
    setdataValidate({
      ...dataUpdate,
    });
    setshowModalImage(true);
  };
  const onAcceptTask = (taskSlotId) => {
    if (!taskSlotId) {
      return false;
    }
    const data = {};
    data['message'] = 'accepted';
    props.acceptTask(taskSlotId, data);
  };
  // Flow Invalid photos from AI
  const onChangeValidatePhoto = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (dataValidate[name] === value) {
      value = 0;
    }
    const data = {
      ...dataValidate,
      [name]: value,
    };
    if (value === 0 || value === '') {
      delete data[name];
    }
    setdataValidate(data);
  };

  const confirmValidatePhoto = async () => {
    const sessionID = inspection?.sessionID ?? '';
    const data = {
      dataValidate,
    };

    const res = await props.validatePhoto(sessionID, data);
    setdataValidate({});
    setshowModalImage(false);
    if (res.allDone) {
      setshowValidateSuccess();
    }
  };

  const hideModalFinal = () => {
    setdataValidate({});
    setshowModalImage(false);
  };
  //
  const showReport = (photoCode, taskId) => {
    if (!photoCode || !taskId) {
      return false;
    }
    const taskSettings =
      photosSetting &&
      photosSetting.reduce((obj, item) => {
        obj[item.photoCode] = item;
        return obj;
      }, {});
    const listReports =
      taskSettings[`${photoCode}`] && taskSettings[`${photoCode}`].rejects
        ? taskSettings[`${photoCode}`].rejects
        : null;

    setshowlistReport(true);
    setlistReport(listReports);
    settaskId(taskId);
  };
  const showReject = (photoCode, taskId) => {
    if (!photoCode || !taskId) {
      return false;
    }
    const taskSettings =
      photosSetting &&
      photosSetting.reduce((obj, item) => {
        obj[item.photoCode] = item;
        return obj;
      }, {});
    const listReject =
      taskSettings[`${photoCode}`] && taskSettings[`${photoCode}`].rejects
        ? taskSettings[`${photoCode}`].rejects
        : null;

    setshowListReject(true);
    setlistReject(listReject);
    settaskId(taskId);
  };
  const showInspect = (taskSlotId) => {
    if (!taskSlotId) {
      return false;
    }
    settaskSlotId(taskSlotId);
    setshowModalInspect(true);
  };
  const reportImage = (e) => {
    e.preventDefault();
    const taskId = e.target.taskId.value;
    const reason = e.target.reason.value;

    if (!taskId || !reason) {
      return false;
    }
    const data = {};
    data['message'] = reason;
    setshowlistReport(false);
    props.reportImage(taskId, data);
  };
  const rejectTask = (e) => {
    e.preventDefault();
    const taskId = e.target.taskId.value;
    const status = e.target.status.value;

    if (!taskId || !status) {
      return false;
    }
    const data = {};
    data['message'] = status;
    setshowListReject(false);
    props.rejectTask(taskId, data);
  };
  const onClickChangeStatusTaskSlot = (taskSlotId) => {
    if (!taskSlotId) {
      return false;
    }
    settaskSlotId(taskSlotId);
    setshowModalChangeTaskSlotStatus(true);
  };
  const changeStatusTaskSlot = (e) => {
    e.preventDefault();
    const taskSlotId = e.target.taskSlotId.value;
    const status = e.target.status.value;
    if (!taskSlotId || !status) {
      return false;
    }
    props.TaskSlotStatus(taskSlotId, { status });
    setshowModalChangeTaskSlotStatus(false);
  };
  const openAllTask = () => {
    setmodalOpenInspect(false);
    const sessionID = inspection.sessionID ? inspection.sessionID : '';
    inspectAllTask(sessionID);
  };
  const listPhotoCode = [
    tasks.filter((task) => task.photoCode === '01'),
    tasks.filter((task) => task.photoCode === '02'),
    tasks.filter((task) => task.photoCode === '03'),
    tasks.filter((task) => task.photoCode === '04'),
    tasks.filter((task) => task.photoCode === '05'),
    tasks.filter((task) => task.photoCode === '06'),
    tasks.filter((task) => task.photoCode === '07'),
    tasks.filter((task) => task.photoCode === '08'),
    tasks.filter((task) => task.photoCode === '09'),
    tasks.filter((task) => task.photoCode === '10'),
    tasks.filter((task) => task.photoCode === '11'),
    tasks.filter((task) => task.photoCode === '12'),
    tasks.filter((task) => task.photoCode === '13'),
    tasks.filter((task) => task.photoCode === '14'),
    tasks.filter((task) => task.photoCode === '15'),
    tasks.filter((task) => task.photoCode === '40'),
    tasks.filter((task) => task.photoCode === '70'),
  ];
  return (
    <div className="list_photoCode">
      {qc_only_accept ? (
        <div className="mb-2">
          <div
            className="badge badge-success text-uppercase mr-2 mb-1"
            style={{
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            <span className="text-head text-white">Accept All task</span>
          </div>
        </div>
      ) : null}

      <div className="mb-2">
        {autoInspectAt && (inSeconds || inSeconds === 0) ? (
          <div className="mb-2">
            <div
              className="badge badge-primary text-uppercase mr-2 mb-1"
              style={{
                fontWeight: 'bold',
                fontSize: '15px',
              }}
            >
              <span className="text-head text-white">Auto</span>
            </div>
            <div
              className="badge badge-primary text-uppercase  mb-1"
              style={{
                fontWeight: 'bold',
                fontSize: '15px',
              }}
            >
              <span className="text-head text-white">{inSeconds}</span>
            </div>
          </div>
        ) : null}
      </div>
      <div className="list_images">
        {listImages.length > 0 ? (
          <div
            className="information_image mt-2"
            style={{
              borderRadius: '30px',
              padding: '0px 2px',
            }}
          >
            {listImages.map((item, index) => {
              let hitl = false;
              const dataHitl = item?.dataHitl ?? false;
              if (dataHitl.avn || dataHitl.vlp) {
                hitl = true;
              }
              const invalidDone = dataHitl?.done ?? false;
              const invalid = dataHitl?.invalid ?? false;
              return (
                <div
                  className="mr-1 mb-1"
                  style={{
                    width: '14%',
                    display: 'inline-block',
                    position: 'relative',
                    border:
                      hitl && invalid
                        ? '3px solid red'
                        : hitl && !invalid
                        ? '3px solid green'
                        : '',
                    borderRadius: '10px',
                  }}
                  key={`imageList_${index}`}
                >
                  <div
                    className="photo"
                    style={{
                      position: 'absolute',
                      top: '-7px',
                      left: '-7px',
                      background: '#000',
                      padding: '1px 3px',
                      color: '#fff',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {item.photoCode}
                  </div>
                  {hitl ? (
                    <div>
                      <div
                        style={{
                          position: 'absolute',
                          top: '-15px',
                          left: '40%',
                        }}
                        className="invalid"
                      >
                        <div
                          className={`badge badge-${
                            invalid ? 'danger' : 'success'
                          } text-head`}
                        >
                          {invalid ? 'INVALID' : 'VALID'}
                        </div>
                      </div>
                      {invalidDone ? (
                        <div
                          style={{
                            position: 'absolute',
                            top: '-10px',
                            right: '0',
                          }}
                          className="badge badge-pill badge-success"
                        >
                          <i className=" fas fa-check-circle"></i>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {item.image_cap ? (
                    <img
                      onClick={() => {
                        showModalFinal(item, `${item.image_cap}.webp`);
                      }}
                      style={{
                        width: '100%',
                        borderRadius: '10px',
                        cursor: 'pointer',
                      }}
                      src={`${item.image_cap}.webp`}
                      alt="img-webp"
                    ></img>
                  ) : (
                    <div
                      className="not_image"
                      style={{
                        display: 'flex',
                        borderRadius: '10px',
                        width: '100%',
                        height: '150px',
                        background: '#000',
                        color: '#fff',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      <span>No Image</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      {listPhotoCode &&
        listPhotoCode.length > 0 &&
        listPhotoCode.map((item, index) => {
          if (item && item.length > 0) {
            let taskRejected = item.filter(
              (task) => task.status === 'REJECTED',
            );
            taskRejected = _orderBy(taskRejected, ['createdAt'], ['desc']);
            const taskAccepted = item.filter(
              (task) => task.status !== 'REJECTED',
            );
            const photoCode =
              taskAccepted[0]?.photoCode ?? taskRejected[0]?.photoCode;
            return (
              <div
                className="photoCode mt-3"
                key={`photo_code` + index}
                style={{
                  padding: '10px 15px',
                  border: '1px solid #675d7a40',
                  borderRadius: '25px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div style={{ width: '2%' }}>
                    <div
                      className="number"
                      style={{
                        display: 'inline-block',
                        padding: '3px 6px',
                        background: '#675d7a',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        color: '#fff',
                        borderRadius: '5px',
                      }}
                    >
                      {photoCode}
                    </div>
                  </div>

                  <div
                    className="process_photocode ml-2"
                    style={{
                      display: 'inline-block',
                      width: '98%',
                    }}
                  >
                    <ProcessData
                      taskAccepted={taskAccepted}
                      user={user}
                      inspection={inspection}
                      statusInspection={statusInspection}
                      themeName={themeName}
                      onClickChangeStatusTaskSlot={onClickChangeStatusTaskSlot}
                      showReport={showReport}
                      showReject={showReject}
                      showInspect={showInspect}
                      onAcceptTask={onAcceptTask}
                      showModalFinal={showModalFinal}
                      kickAgent={kickAgent}
                      damage_table={damage_table}
                      qcPassed={qcPassed}
                    />
                  </div>
                </div>
                <div className="rejected_photocode mt-3">
                  <RejectData
                    taskRejected={taskRejected}
                    user={user}
                    inspection={inspection}
                    statusInspection={statusInspection}
                    themeName={themeName}
                    showReject={showReject}
                    showInspect={showInspect}
                    onAcceptTask={onAcceptTask}
                    showModalFinal={showModalFinal}
                  />
                </div>
              </div>
            );
          }
          return null;
        })}
      <ModalMD
        show={showListReject}
        title="Reject Task"
        body={
          <div className="main-content">
            <form onSubmit={rejectTask}>
              <div className="form-group">
                <label className="text-bold">Status</label>
                <select className="custom-select small" name="status">
                  {itemReject}
                </select>
                <input
                  type="hidden"
                  value={taskId ? taskId : ''}
                  name="taskId"
                />
              </div>
              <button className="btn btn-warning">
                <span className="text-bold">OK</span>
              </button>
            </form>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setshowListReject(false)}
      />
      <ModalMD
        show={showlistReport}
        title="Reject Task"
        body={
          <div className="main-content">
            <form onSubmit={reportImage}>
              <div className="form-group">
                <label className="text-bold">Status</label>
                <select className="custom-select small" name="reason">
                  {itemReport}
                </select>
                <input
                  type="hidden"
                  value={taskId ? taskId : ''}
                  name="taskId"
                />
              </div>
              <button className="btn btn-warning">
                <span className="text-bold">OK</span>
              </button>
            </form>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setshowlistReport(false)}
      />
      <ModalMD
        show={showModalChangeTaskSlotStatus}
        title="Change status Task Slot"
        body={
          <div className="main-content">
            <form onSubmit={changeStatusTaskSlot}>
              <div className="form-group">
                <label className="text-bold">Status</label>
                <select className="custom-select small" name="status">
                  <option value="ACCEPTED">ACCEPTED</option>
                  <option disabled value="PENDING">
                    PENDING
                  </option>
                </select>
                <input type="hidden" value={taskSlotId} name="taskSlotId" />
              </div>
              <button className="btn btn-warning">
                <span className="text-bold">Change</span>
              </button>
            </form>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setshowModalChangeTaskSlotStatus(false)}
      />
      {showModalInspect && (
        <ModalInspect
          taskSlotID={taskSlotId}
          hideModal={() => setshowModalInspect(false)}
        />
      )}
      {showModalImage && (
        <ModalFinal
          tasks={tasks}
          vehicle={inspection.vehicle || {}}
          task={dataTask && dataTask.task ? dataTask.task : null}
          inspection={inspection}
          src={dataTask && dataTask.url ? dataTask.url : ''}
          agent={dataTask && dataTask.agent ? dataTask.agent : false}
          notGen={dataTask && dataTask.notGen ? dataTask.notGen : false}
          final={dataTask && dataTask.final ? dataTask.final : false}
          hideModal={() => hideModalFinal()}
          dataValidate={dataValidate}
          onChangeValidatePhoto={onChangeValidatePhoto}
          confirmValidatePhoto={confirmValidatePhoto}
        />
      )}
      <ModalMD
        show={modalOpenInspect}
        title="Completed"
        body={
          <div className="main-content">
            <div className="form-group">
              <div className="text-center">
                <span className="text-head" style={{ fontSize: '20px' }}>
                  Are you sure you want to open all task?
                </span>
              </div>
            </div>
            <div className="btn-group float-right">
              <button className="btn btn-warning" onClick={openAllTask}>
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  Yes
                </span>
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setmodalOpenInspect(false)}
              >
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  No
                </span>
              </button>
            </div>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setmodalOpenInspect(false)}
      />
    </div>
  );
};

export default DetailTask;
