import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getActiveCages, putInspectionCage } from '../../operations';
import Select from 'react-select';
import { get } from 'lodash';
import jQuery from 'jquery';
window.jQuery = jQuery;
require('bootstrap');

class InspectionCage extends Component {
	constructor(props) {
		super(props);
		const { item } = this.props.inspection;
		let currentCage = {};
		const thisCage = get(item, 'data.cage');
		if (thisCage) {
			currentCage = {
				label: `(${thisCage.bodyType}) ${thisCage.year} ${thisCage.make} ${thisCage.model}`,
				value: thisCage._id,
			};
		}

		this.state = {
			currentCage,
		};
	}

	UNSAFE_componentWillMount() {
		//this.props.getActiveCages({});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const cages = get(nextProps, 'inspection.cages.list.data') || [];
		let cageList = [];
		for (let i = 0; i < cages.length; i++) {
			const cage = cages[i];
			cageList.push({
				label: `(${cage.bodyType}) ${cage.year} ${cage.make} ${cage.model}`,
				value: cage._id,
			});
		}
		this.setState({ cageList });
	}

	onSetCage = (e) => {
		e.preventDefault();
		const { item } = this.props.inspection;
		const { currentCage } = this.state;
		if (item && item.data && currentCage && currentCage.value) {
			this.props.putInspectionCage(item.data._id, currentCage.value);
			jQuery('#Inspection_cage_modal').modal('hide');
		}
	};

	onChangeCage = (e) => {
		e.preventDefault();
		jQuery('#Inspection_cage_modal').modal('show');
	};

	onInputChange = (text) => {
		if (text) {
			this.props.getActiveCages({ keyword: text });
		}
	};

	render() {
		//const { item, cages } = this.props.inspection;
		const { currentCage, cageList } = this.state;
		// const cageList = get(cages, "list.data") || [];
		// const detectedCageList = get(item, "data.options.detectedCages") || [];

		// let cageOptions = [];
		// for (let i = 0; i < detectedCageList.length; i++) {
		//     const cage = detectedCageList[i];
		//     cageOptions.push({
		//         label: `(${cage.bodyType}) ${cage.year} ${cage.make} ${cage.model}`,
		//         value: cage._id
		//     });
		// }
		// for (let i = 0; i < cageList.length; i++) {
		//     const cage = cageList[i];
		//     cageOptions.push({
		//         label: `(${cage.bodyType}) ${cage.year} ${cage.make} ${cage.model}`,
		//         value: cage._id
		//     });
		// }

		return (
			<div id="Inspection_cage_edit" className="d-inline mx-2">
				<button
					className="btn btn-md btn-warning"
					style={{ paddingBottom: '2px' }}
					onClick={this.onChangeCage}
				>
					<i className="fa fa-pen" />
				</button>
				<div
					className="modal fade"
					id="Inspection_cage_modal"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="Inspection_cage_edit_title"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content text-normal">
							<div className="modal-header">
								<h5
									className="modal-title"
									id="Inspection_cage_edit_title"
								>
									Change Inspection Cage
								</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">
										<i className="fa fa-times" />
									</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="display-5 text-head text-logan text-uppercase mb-1">
									Select New Cage
								</div>
								<div className="row mb-3">
									<Select
										id="Cage_select"
										value={currentCage}
										options={cageList}
										placeholder={`Select cage for the inspection`}
										isClearable={false}
										className="col-8 react-select-container"
										classNamePrefix="react-select"
										onChange={(e) => {
											this.setState({ currentCage: e });
										}}
										onInputChange={this.onInputChange}
									/>
									<div className="col-4">
										<button
											className="btn btn-outline-secondary btn-expand"
											type="button"
											onClick={this.onSetCage}
										>
											Set This Cage
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	inspection: state.inspection,
});

export default connect(mapStateToProps, { getActiveCages, putInspectionCage })(
	InspectionCage
);
