import React from 'react';
import Timeline from '../components/partials/Timeline';
import InspectData from './InspectData';

const ProcessData = (props) => {
  const {
    taskAccepted,
    themeName,
    inspection,
    statusInspection,
    user,
    onAcceptTask,
    showReject,
    showInspect,
    showModalFinal,
    kickAgent,
    onClickChangeStatusTaskSlot,
    damage_table,
    qcPassed,
    showReport,
  } = props;
  return (
    taskAccepted &&
    taskAccepted.length > 0 &&
    taskAccepted.map((task, index) => {
      return (
        <div className="ml-2" key={`processPhoto_` + index}>
          <div className="process" style={{ display: 'flex' }}>
            <div
              className="groupImage"
              style={{
                display: 'flex',
                borderRadius: '5px',
                width: '4%',
              }}
            >
              <div className="image">
                <div
                  className="btn-group"
                  style={{
                    background: '#32164017',
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                    width: '100%',
                  }}
                >
                  {task.image_cap && (
                    <button
                      className="btn btn-tiny btn-light"
                      onClick={() => {
                        showModalFinal(task, `${task.image_cap}.webp`);
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12px',
                          textAlign: 'center',
                        }}
                      >
                        Webp
                      </span>
                    </button>
                  )}
                  {task.image_cap && (
                    <button
                      className="btn btn-tiny btn-light"
                      onClick={() => {
                        showModalFinal(task, task.image_cap);
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12px',
                          textAlign: 'center',
                        }}
                      >
                        Img
                      </span>
                    </button>
                  )}
                </div>
                {[
                  '04',
                  '05',
                  '07',
                  '08',
                  '09',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                ].includes(task.photoCode) && (
                  <button
                    className="btn btn-tiny btn-block btn-light btn-lb-dark mt-1"
                    onClick={() =>
                      showModalFinal(task, task.image_cap, true, true)
                    }
                  >
                    <span
                      style={{
                        fontSize: '12px',
                        textAlign: 'center',
                      }}
                    >
                      Full
                    </span>
                  </button>
                )}

                {task.annotated_images && (
                  <button
                    className="btn btn-tiny btn-light btn-lb-dark mt-1"
                    onClick={() =>
                      showModalFinal(
                        task,
                        task.annotated_images,
                        false,
                        false,
                        true,
                      )
                    }
                    style={{
                      width: '100%',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12px',
                        textAlign: 'center',
                      }}
                    >
                      Final
                    </span>
                  </button>
                )}

                <div
                  className="photoId"
                  style={{
                    background: '#675d7a',
                    color: '#fff',
                    textAlign: 'center',
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                    fontWeight: 'bold',
                  }}
                >
                  {task.photo_id}
                </div>
              </div>
            </div>
            <div
              className="informationTask"
              style={{
                display: 'inline-block',
                width: '37%',
              }}
            >
              {task.status !== 'IN-QUEUE' &&
              task.status !== 'WAITING' &&
              task.taskSlots.length > 0 ? (
                <Timeline
                  showReport={showReport}
                  qcPassed={qcPassed}
                  task={task}
                  onClickChangeStatusTaskSlot={onClickChangeStatusTaskSlot}
                  showInspect={showInspect}
                  statusInspection={statusInspection}
                  inspection={inspection}
                  showReject={showReject}
                  onAcceptTask={onAcceptTask}
                  kickAgent={kickAgent}
                  user={user}
                  themeName={themeName}
                />
              ) : (
                <div className="">
                  <div className="row">
                    <div className="col-5 text-right">
                      <div
                        className="status"
                        style={{
                          display: 'inline-block',
                          padding: '13px 15px',
                          borderRadius: '5px',
                          background: 'rgb(103, 93, 122)',
                          color: '#fff',
                          fontWeight: 'bold',
                        }}
                      >
                        {task.status}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="inspectdata ml-2" style={{ width: '59%' }}>
              <InspectData
                data={task}
                vehicle={inspection.vehicle || {}}
                damage_table={damage_table}
              />
            </div>
          </div>
        </div>
      );
    })
  );
};

export default ProcessData;
