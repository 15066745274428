import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ButtonNote = ({ path }) => {
    return (
        <Link to={path} className="btn btn-tiny btn-note">
            <i className="fa fa-sticky-note text-warning" />
        </Link>
    );
};

ButtonNote.propTypes = {
    onClick: PropTypes.func
};

ButtonNote.defaultProps = {
    path: "/cages"
};

export default ButtonNote;
