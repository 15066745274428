import React, { useRef, useState, useEffect } from 'react';
import { orderBy } from 'lodash';
const ListData = (props) => {
  let { data } = props;
  const [showData, setshowData] = useState(false);
  const ref = useRef(null);
  const [list, setList] = useState([]);
  const listData = data.listData ? data.listData : false;
  useEffect(() => {
    let tmpList = [];
    let sort = {
      key: 'point',
      type: 'desc',
    };
    if (typeof listData === 'object') {
      Object.keys(listData).map((key) => {
        const item = listData[key];
        return tmpList.push({
          user: key,
          ...item,
        });
      });
    }
    tmpList = orderBy(tmpList, sort.key, sort.type);
    setList(tmpList);
  }, [data]);
  return (
    <div
      id="statistic"
      className="data "
      style={{
        height: showData ? '' : '350px',
      }}
    >
      <div
        style={{
          height: showData ? '' : '305px',
          overflow: showData ? '' : 'hidden',
        }}
      >
        <div className="mt-1" ref={ref}>
          <div className="mb-4">
            <div
              className="d-flex"
              style={{
                padding: '5px 10px',
              }}
            >
              <div style={{ width: '10%' }}></div>
              <div className="text-head" style={{ width: '10%' }}>
                Username
              </div>
              <div style={{ width: '70%' }}></div>
              <div className="text-head" style={{ width: '10%' }}>
                Total
              </div>
            </div>
          </div>
          <div style={{ borderRadius: '15px' }} className="text-head">
            {Array.isArray(list) && list.length > 0 ? (
              list.map((item, index) => {
                let background = '#fff';
                let textColor = 'text-dark';
                let showIcon = false;
                let icon = '';
                switch (index) {
                  case 0:
                    showIcon = true;
                    background = '#d63031';
                    textColor = 'text-white';
                    icon = 'fas fa-skull';
                    break;
                  case 1:
                    background = '#e17055';
                    textColor = 'text-white';
                    break;
                  case 2:
                    background = '#fdcb6e';
                    textColor = 'text-dark';
                    break;
                  default:
                    break;
                }

                return (
                  <div
                    key={`statistic${index}`}
                    className={`mb-2 d-flex ${textColor}`}
                    style={{
                      padding: '9px 10px',
                      borderRadius: '15px',
                      background: background,
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    {showIcon ? (
                      <div
                        className="icon"
                        style={{
                          position: 'absolute',
                          left: '10px',
                          top: '-35px',
                          fontSize: '39px',
                          color: '#000',
                        }}
                      >
                        <i className={icon}></i>
                      </div>
                    ) : null}

                    <div
                      style={{ width: '10%', justifyContent: 'center' }}
                      className="d-flex"
                    >
                      <div
                        className={`d-flex text-white`}
                        style={{
                          position: 'relative',
                          width: '30px',
                          height: '30px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          background: '#321640',
                          borderRadius: '40%',
                        }}
                      >
                        {index + 1}
                      </div>
                    </div>
                    <div style={{ width: '10%' }}>{item.user}</div>
                    <div style={{ width: '70%' }}></div>
                    <div style={{ width: '10%' }}>
                      <div className="point">{item.point ? item.point : 0}</div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <div>
                  <i>No data to display.</i>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {list && list.length > 5 ? (
        <div
          className="mt-2 d-flex"
          style={{
            justifyContent: 'center',
          }}
        >
          <div
            onClick={() => setshowData(!showData)}
            style={{
              padding: '10px 20px',
              borderRadius: '40%',
              background: '#321640',
              cursor: 'pointer',
              display: 'inline-block',
            }}
          >
            {!showData ? (
              <i className="fas fa-angle-down text-white"></i>
            ) : (
              <i className="fas fa-angle-up text-white"></i>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ListData;
