import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const TextFieldGroup = ({
  name,
  placeholder,
  value,
  label,
  error,
  info,
  type,
  onChange,
  onFocus,
  disabled,
}) => {
  return (
    <div className="form-group ">
      {label && (
        <label className="display-7 text-white text-head text-uppercase">
          {label}
        </label>
      )}
      <input
        style={{ height: '50px', fontSize: '18px' }}
        type={type}
        className={classnames('form-control', {
          'is-invalid': error,
        })}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled ? 'disabled' : ''}
        autoComplete={name}
      />
      {info && <small className="form-text text-muted">{info}</small>}
      {error && (
        <div style={{ fontSize: '16px' }} className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

TextFieldGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  disabled: PropTypes.string,
};

TextFieldGroup.defaultProps = {
  type: 'text',
};

export default TextFieldGroup;
