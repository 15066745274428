import * as types from './types';

const initialState = {
	loaded: false,
	list: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case types.GET_SETTINGS:
		case types.SET_SETTING:
			return {
				...state,
				loaded: true,
				list: {
					...state.list,
					...action.payload
				}
			};
		default:
			return state;
	}
}
