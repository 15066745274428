import React from 'react';
import PropTypes from 'prop-types';
import AccordionItem from '../widgets/AccordionItem';

const CageViewInfo = ({
  data,
  show,
  onChange,
  onChangeStatus,
  success,
  settings,
  user,
}) => {
  const { listBodyType, listMake, listYear, listGroups } = settings;
  let listStatus = [
    { label: 'REQUEST-REVIEW', class: 'btn-success' },
    { label: 'PROCESS-PHOTO', class: 'btn-success' },
    { label: 'PROCESS-CAGE', class: 'btn-success' },
    { label: 'PROCESS-LABEL', class: 'btn-success' },
  ];
  if (user.role !== 'AGENT') {
    listStatus = [
      { label: 'REQUEST-REVIEW', class: 'btn-success' },
      { label: 'ACTIVE', class: 'btn-success' },
      { label: 'CONFIRM', class: 'btn-success' },
      { label: 'ARCHIVE', class: 'btn-secondary' },
      { label: 'DRAFT', class: 'btn-info' },
      { label: 'PROCESS-PHOTO', class: 'btn-success' },
      { label: 'DELETE', class: 'btn-danger' },
    ];
  }
  let selectYears = [];
  for (let i = listYear.max; i >= listYear.min; i--) {
    selectYears.push(
      <option key={`Year_${i}`} value={i}>
        {i}
      </option>,
    );
  }
  const bodyContent = (
    <div key="Cage_options" id="Cage_options">
      <div className="row">
        <div className="col-12 col-lg-4">
          <h3 className="display-6 text-secondary">Actions</h3>
          <div className="btn-list">
            {listStatus.map((item, index) => {
              if (user.role === 'VIEWER') {
                return null;
              }
              const type = item;
              const btnKey = item + '-' + type + index;
              return (
                <button
                  key={btnKey}
                  className={`btn btn-sm ${type.class} mb-2`}
                  onClick={(e) => {
                    onChangeStatus(type.label, e);
                  }}
                >
                  {type.label}
                </button>
              );
            })}
          </div>
        </div>
        <div className="col-12 col-lg-3">
          <h3 className="display-6 text-secondary">Status</h3>
          <div className="display-5 text-logan text-head">{data.status}</div>
        </div>
        <div className="col-6 col-lg-5">
          <h3 className="display-6 text-secondary">Auto Values</h3>
          <div className="badge-group">
            <div className="badge badge-secondary">
              Type: <b>{data.group}</b>
            </div>
            <div className="badge badge-secondary ml-2">
              Class: <b>{data.class}</b>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Make</h3>
          {user.role !== 'AGENT' ? (
            <select
              className={`form-control form-control-sm ${
                success === 'updated' && data.updatedField === 'make'
                  ? 'is-success'
                  : ''
              }`}
              name="make"
              value={data.make || ''}
              onChange={(e) => {
                onChange(true, e);
              }}
            >
              {listMake.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          ) : (
            <div>{data.make}</div>
          )}
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Model</h3>
          {user.role !== 'AGENT' ? (
            <input
              type="text"
              className={`form-control form-control-sm ${
                success === 'updated' && data.updatedField === 'model'
                  ? 'is-success'
                  : ''
              }`}
              name="model"
              value={data.model || ''}
              onChange={(e) => {
                onChange(false, e);
              }}
              onBlur={(e) => {
                onChange(true, e);
              }}
            />
          ) : (
            <div>{data.model}</div>
          )}
        </div>
        <div className="col-6 col-lg-1">
          <h3 className="display-6 text-secondary">Year</h3>
          {user.role !== 'AGENT' ? (
            <select
              className={`form-control form-control-sm ${
                success === 'updated' && data.updatedField === 'year'
                  ? 'is-success'
                  : ''
              }`}
              name="year"
              value={data.year || ''}
              onChange={(e) => {
                onChange(true, e);
              }}
            >
              {selectYears}
            </select>
          ) : (
            <div>{data.year}</div>
          )}
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Body Type</h3>
          {user.role !== 'AGENT' ? (
            <select
              className={`form-control form-control-sm ${
                success === 'updated' && data.updatedField === 'bodyType'
                  ? 'is-success'
                  : ''
              }`}
              name="bodyType"
              value={data.bodyType || ''}
              onChange={(e) => {
                onChange(true, e);
              }}
            >
              {listBodyType.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          ) : (
            <div>{data.bodyType}</div>
          )}
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Trim &amp; Configuration</h3>
          {user.role !== 'AGENT' ? (
            <input
              type="text"
              className={`form-control form-control-sm ${
                success === 'updated' && data.updatedField === 'trim'
                  ? 'is-success'
                  : ''
              }`}
              name="trim"
              value={data.trim || ''}
              onChange={(e) => {
                onChange(false, e);
              }}
              onBlur={(e) => {
                onChange(true, e);
              }}
            />
          ) : (
            <div>{data.trim}</div>
          )}
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Platform Code</h3>
          {user.role !== 'AGENT' ? (
            <input
              type="text"
              className={`form-control form-control-sm ${
                success === 'updated' && data.updatedField === 'code'
                  ? 'is-success'
                  : ''
              }`}
              name="code"
              value={data.code || ''}
              onChange={(e) => {
                onChange(false, e);
              }}
              onBlur={(e) => {
                onChange(true, e);
              }}
            />
          ) : (
            <div>{data.code}</div>
          )}
        </div>
        <div className="col-6 col-lg-1">
          <h3 className="display-6 text-secondary">Group</h3>
          {user.role !== 'AGENT' ? (
            <select
              className={`form-control form-control-sm ${
                success === 'updated' && data.updatedField === 'group'
                  ? 'is-success'
                  : ''
              }`}
              name="group"
              value={data.group || ''}
              onChange={(e) => {
                onChange(true, e);
              }}
            >
              {listGroups.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          ) : (
            <div>{data.group}</div>
          )}
        </div>
      </div>
    </div>
  );
  return (
    <AccordionItem
      id="info"
      buttonLabel="Information"
      bodyContent={bodyContent}
      show={show}
    />
  );
};

CageViewInfo.propTypes = {
  data: PropTypes.object.isRequired,
};

CageViewInfo.defaultProps = {
  show: false,
};

export default CageViewInfo;
