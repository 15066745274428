import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCageLabelDetail, putCageLabel } from '../operations';
import SVG from 'react-inlinesvg';
import $ from 'jquery';
import AccordionItem from './widgets/AccordionItem';
import { FileModal } from './widgets';
import '../../../_sass/select-search.scss';
import Select from 'react-select';
import { materials } from '../settings/material';

window.$ = $;

class CageLabel extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();
    this.selectBox = {};
  }

  defaultState() {
    return {
      folder: null,
      imageSrc: '',
      svgSrc: '',
      bodyType: '',
      partLabelOptions: [],
      partLabels: {},
      labelData: [],
      parts: {},
      partsPart: {},
      partsBody: {},
      partsGlass: {},
      partsWheel: {},
      partsTire: {},
    };
  }
  UNSAFE_componentWillMount() {
    const { item } = this.props.cage;
    if (item && item.cageDetails.length > 0) {
      this.props.getCageLabelDetail(this.props.match.params.id, 'Label');
    } else {
      this.props.history.push(`/cageView/${this.props.match.params.id}`);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.cage.detailLabel) {
      const { item, detailLabel } = nextProps.cage;
      let dataSvgSrc;
      let dataImgSrc;
      if (detailLabel.files) {
        const files = detailLabel.files;
        dataSvgSrc = !files.cage.fallback ? files.cage.url : false;
        dataImgSrc = files.photo ? files.photo.url : false;
      }
      if (!dataSvgSrc) {
        return;
      }
      const labelData = detailLabel ? detailLabel.labelData : [];
      let partLabelOptions = [];
      let partLabels = {};
      const partData = require(`../settings/cage_label.json`);
      if (partData) {
        for (const groupKey in partData) {
          const groupLabels = partData[groupKey];
          let groupPartOptions = [];
          for (const partKey in groupLabels) {
            groupPartOptions.push({
              label: partKey,
              value: partKey,
              group: groupKey,
            });
            partLabels[partKey] = {
              label: partKey,
              group: groupKey,
            };
          }
          partLabelOptions.push({
            label: groupKey,
            options: groupPartOptions,
          });
        }
      }

      this.setState({
        svgSrc: dataSvgSrc,
        imageSrc: dataImgSrc,
        bodyType: item.bodyType,
        labelData: labelData,
        partLabelOptions: partLabelOptions,
        listMaterials: materials,
        partLabels: partLabels,
      });
    }
  }
  componentDidUpdate() {
    this.svgUpdate();
  }

  returnCage = (
    idCageDetail,
    partsPart,
    partsWheel,
    partsTire,
    partsBody,
    partsGlass,
    parts,
  ) => {
    this.labelDone(
      'finish',
      idCageDetail,
      partsPart,
      partsWheel,
      partsTire,
      partsBody,
      partsGlass,
      parts,
    );
  };

  labelDone = (
    action = 'save',
    idCageDetail,
    partsPart,
    partsWheel,
    partsTire,
    partsBody,
    partsGlass,
    parts,
  ) => {
    const { item } = this.props.cage;
    let partsObj = {
      ...partsPart,
      ...partsWheel,
      ...partsTire,
      ...partsBody,
      ...partsGlass,
      ...parts,
    };
    let arrLabel = [];
    for (let item in partsObj) {
      arrLabel.push(partsObj[item]);
    }
    const data = {
      type: 'Label',
      labelAction: action,
      labelData: arrLabel,
      cageId: item._id,
    };
    let history = action === 'finish' ? this.props.history : false;
    this.props.putCageLabel(idCageDetail, data, history);
  };

  onSetLabel(e, type, selected, id, groupMaterial) {
    if (selected && selected.value) {
      let { parts, partsPart, partsWheel, partsTire, partsBody, partsGlass } =
        this.state;
      let label = false;
      let material = false;
      if (type === 'label') {
        label = selected.value;
      }
      if (type === 'material') {
        material = selected.value;
      }
      let group = selected.group ? selected.group : groupMaterial;
      const myArr = [
        parts,
        partsPart,
        partsBody,
        partsTire,
        partsGlass,
        partsWheel,
      ];
      for (let i = 0; i < myArr.length; i++) {
        if (myArr[i][id]) {
          myArr[i][id].id = id;
          myArr[i][id].label = label
            ? label
            : myArr[i][id].label
            ? myArr[i][id].label
            : '';
          myArr[i][id].group = group
            ? group
            : myArr[i][id].group
            ? myArr[i][id].group
            : '';

          myArr[i][id].material = material
            ? material
            : myArr[i][id].material
            ? myArr[i][id].material
            : '';
          if (group === 'PART') {
            if (myArr[i] !== partsPart) {
              partsPart[id] = myArr[i][id];
              delete myArr[i][id];
            } else {
              partsPart[id] = myArr[i][id];
            }
            this.setState({
              partsPart: partsPart,
            });
            break;
          } else if (group === 'BODY') {
            if (myArr[i] !== partsBody) {
              partsBody[id] = myArr[i][id];
              delete myArr[i][id];
            } else {
              partsBody[id] = myArr[i][id];
            }
            this.setState({
              partsBody: partsBody,
            });
            break;
          } else if (group === 'WHEEL') {
            if (myArr[i] !== partsWheel) {
              partsWheel[id] = myArr[i][id];
              delete myArr[i][id];
            } else {
              partsWheel[id] = myArr[i][id];
            }
            this.setState({
              partsWheel: partsWheel,
            });
            break;
          } else if (group === 'TIRE') {
            if (myArr[i] !== partsTire) {
              partsTire[id] = myArr[i][id];
              delete myArr[i][id];
            } else {
              partsTire[id] = myArr[i][id];
            }
            this.setState({
              partsTire: partsTire,
            });
            break;
          } else if (group === 'GLASS') {
            if (myArr[i] !== partsGlass) {
              partsGlass[id] = myArr[i][id];
              delete myArr[i][id];
            } else {
              partsGlass[id] = myArr[i][id];
            }
            this.setState({
              partsGlass: partsGlass,
            });
            break;
          } else {
            myArr[i][id].label = myArr[i][id].id;
            myArr[i][id].group = 'UNDEFINED';
            parts[id] = myArr[i][id];
            this.setState({
              parts: parts,
            });
            break;
          }
        }
      }
      this.setState({
        parts: parts,
      });

      const { detailLabel } = this.props.cage;
      this.labelDone(
        'save',
        detailLabel._id,
        partsPart,
        partsWheel,
        partsTire,
        partsBody,
        partsGlass,
        parts,
      );
    }
  }

  onHighlight(id) {
    const pathId = $('path');
    let arrPathId = [];
    let myId = `#Part_${id}`;
    for (let i = 0; i < pathId.length; i++) {
      arrPathId.push(pathId[i].id);
    }

    for (let i = 0; i < arrPathId.length; i++) {
      if (id === arrPathId[i]) {
        if ($(myId) && id) {
          arrPathId.splice(i, 1);
          $('#Part_' + id).css('background', '#ffc107');
          $('#' + id).css('opacity', 1);
        }
        for (let j = 0; j < arrPathId.length; j++) {
          if (arrPathId[j]) {
            $('#Part_' + arrPathId[j]).css('background', '#fff');
            $('#' + arrPathId[j]).css('opacity', 0.5);
          }
        }
      }
    }
  }
  onDeletePart(id, e) {
    e.preventDefault();
    const { detailLabel } = this.props.cage;

    let { parts, partsPart, partsWheel, partsGlass, partsTire, partsBody } =
      this.state;
    [
      partsPart,
      partsWheel,
      partsGlass,
      partsTire,
      partsGlass,
      partsBody,
    ].forEach((item) => {
      if (item[id]) {
        item[id].label = item[id].id;
        item[id].group = 'UNDEFINED';
        parts[id] = item[id];
        delete item[id];
        this.labelDone(
          'save',
          detailLabel._id,
          partsPart,
          partsWheel,
          partsTire,
          partsBody,
          partsGlass,
          parts,
        );
        this.setState({
          parts: parts,
          partsPart: partsPart,
          partsWheel: partsWheel,
          partsGlass: partsGlass,
          partsTire: partsTire,
          partsBody: partsBody,
        });
      }
    });
  }
  onDeleteAll(e) {
    e.preventDefault();
    const { detailLabel } = this.props.cage;
    let { parts, partsPart, partsWheel, partsGlass, partsTire, partsBody } =
      this.state;
    [
      partsPart,
      partsWheel,
      partsGlass,
      partsTire,
      partsGlass,
      partsBody,
    ].forEach((item) => {
      for (let path in item) {
        parts[path] = item[path];
        parts[path] = {
          id: path,
          label: path,
          group: 'UNDEFINED',
        };
        delete item[path];
      }

      this.setState({
        parts: parts,
        partsPart: partsPart,
        partsWheel: partsWheel,
        partsGlass: partsGlass,
        partsTire: partsTire,
        partsBody: partsBody,
      });

      this.labelDone(
        'save',
        detailLabel._id,
        partsPart,
        partsWheel,
        partsTire,
        partsBody,
        partsGlass,
        parts,
      );
    });
  }
  formatOptionLabel(options) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ color: '#fff' }} className="badge badge-success">
          {options.label === 'Painted' ? (
            <i className="fas fa-fill-drip"></i>
          ) : options.label === 'Textured Surface' ? (
            <i className="fas fa-recycle"></i>
          ) : options.label === 'Chrome' ? (
            <i className="far fa-gem"></i>
          ) : options.label === 'Alloy Wheel' ? (
            <i className="fas fa-drum-steelpan"></i>
          ) : options.label === 'Plastic Cover' ? (
            <i className="fas fa-record-vinyl"></i>
          ) : options.label === 'Steel Wheel' ? (
            <i className="fas fa-dumbbell"></i>
          ) : options.label === 'Glass' ? (
            <i className="fas fa-glass-whiskey"></i>
          ) : options.label === 'Steel' ? (
            <i className="fas fa-dharmachakra"></i>
          ) : null}
        </div>
        <span className="ml-2"> {options.label}</span>
      </div>
    );
  }
  svgLoader() {
    let {
      labelData,
      parts,
      partsPart,
      partsWheel,
      partsTire,
      partsBody,
      partsGlass,
    } = this.state;
    const paths = $('path');
    const g = $('g');
    g.css('opacity', 1);

    if (labelData) {
      for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        for (let j = 0; j < labelData.length; j++) {
          if (path.id === labelData[j].id) {
            path.setAttribute('data-name', labelData[j].label);
            path.setAttribute('group', labelData[j].group);
            path.setAttribute('material', labelData[j].material);
          }
        }
      }
    }
    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];
      let pathId = '';
      if (path.id) {
        pathId = path.id;
      }
      let pathName = '';
      if (path.dataset.name) {
        pathName = path.dataset.name;
        pathName = pathName.replace(' ', '_');
      }
      let pathGroup = path.getAttribute('group');
      let pathMaterial = path.getAttribute('material');
      if (pathId === pathName && pathId !== '') {
        let pathInfo = parts[pathId] || {};
        parts[pathId] = pathInfo;
        path.setAttribute('opacity', 0.5);
        pathInfo.material = pathMaterial;
      }
      if (pathGroup === 'PART' && pathName !== pathId) {
        let pathInfo = partsPart[pathId] || {};
        pathInfo.id = pathId;
        pathInfo.label = pathName;
        pathInfo.group = pathGroup;
        pathInfo.material = pathMaterial;
        partsPart[pathId] = pathInfo;
        path.setAttribute('opacity', 0.5);
      }
      if (pathGroup === 'BODY' && pathName !== pathId) {
        let pathInfo = partsBody[pathId] || {};
        pathInfo.id = pathId;
        pathInfo.label = pathName;
        pathInfo.group = pathGroup;
        pathInfo.material = pathMaterial;
        partsBody[pathId] = pathInfo;
        path.setAttribute('opacity', 0.5);
      }
      if (pathGroup === 'WHEEL' && pathName !== pathId) {
        let pathInfo = partsWheel[pathId] || {};
        pathInfo.id = pathId;
        pathInfo.label = pathName;
        pathInfo.group = pathGroup;
        pathInfo.material = pathMaterial;
        partsWheel[pathId] = pathInfo;
        path.setAttribute('opacity', 0.5);
      }
      if (pathGroup === 'GLASS' && pathName !== pathId) {
        let pathInfo = partsGlass[pathId] || {};
        pathInfo.id = pathId;
        pathInfo.label = pathName;
        pathInfo.material = pathMaterial;
        pathInfo.group = pathGroup;
        partsGlass[pathId] = pathInfo;
        path.setAttribute('opacity', 0.5);
      }
      if (pathGroup === 'TIRE' && pathName !== pathId) {
        let pathInfo = partsTire[pathId] || {};
        pathInfo.id = pathId;
        pathInfo.label = pathName;
        pathInfo.material = pathMaterial;
        pathInfo.group = pathGroup;
        partsTire[pathId] = pathInfo;
        path.setAttribute('opacity', 0.5);
      }
    }

    this.setState({
      parts: parts,
      partsPart: partsPart,
      partsWheel: partsWheel,
      partsGlass: partsGlass,
      partsTire: partsTire,
      partsBody: partsBody,
    });
    const _ = this;
    $('path').on('click', function (e) {
      _.onHighlight(e.target.id, e);
      const focusObj = _.selectBox['LabelSelect_' + e.target.id] || false;
      if (focusObj) {
        focusObj.focus();
      }
      $('#Part_' + e.target.id).focus();
    });
  }
  svgUpdate() {
    const { parts, partsPart, partsWheel, partsGlass, partsTire, partsBody } =
      this.state;

    [parts, partsPart, partsWheel, partsGlass, partsTire, partsBody].forEach(
      (item) => {
        const groupColor = {
          PART: 'rgba(239, 23, 102, 1)',
          BODY: 'rgba(39, 208, 137, 1)',
          GLASS: 'rgba(0, 191, 255, 1)',
          WHEEL: 'rgba(241, 121, 11, 1)',
          TIRE: 'rgba(254, 206, 0, 1)',
          UNDEFINED: 'rgba(127, 127, 127, 1)',
        };
        const undefinedColor = 'rgba(127, 127, 127, 1)';
        for (const part in item) {
          const partInfo = item[part];
          const color =
            partInfo.group && groupColor[partInfo.group]
              ? groupColor[partInfo.group]
              : undefinedColor;
          $('#' + part).attr('fill', color);
        }
      },
    );
  }

  mappingSelectItem = (id, options, part, group, listMaterials, material) => {
    let defaultValue = false;
    let defaultMaterial = false;
    if (material) {
      defaultMaterial = {
        label: material,
        value: material,
        group: group,
      };
    }
    if (part) {
      const { partLabels } = this.state;
      const selectedPart = partLabels[part] || false;
      if (selectedPart) {
        defaultValue = {
          label: selectedPart.label,
          value: selectedPart.value,
          group: group,
        };
      }
    }
    if (part) {
      return (
        <li
          key={`Part_${id}`}
          id={`Part_${id}`}
          className="list-group-item"
          onClick={this.onHighlight.bind(this, id)}
        >
          <Select
            ref={(ref) => {
              this.selectBox[`LabelSelect_${id}`] = ref;
            }}
            id={`LabelSelect_${id}`}
            defaultValue={defaultValue}
            options={options}
            //defaultMenuIsOpen={true}
            placeholder={`Label for ${id}`}
            isClearable={false}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={(e) => this.onSetLabel(this, 'label', e, id)}
          />
          <i
            className="fas fa-times delete-label"
            onClick={(e) => {
              this.onDeletePart(id, e);
            }}
            aria-hidden="true"
          />
          <Select
            ref={(ref) => {
              this.selectBox[`Material_${id}`] = ref;
            }}
            id={`Material_${id}`}
            defaultValue={defaultMaterial}
            options={listMaterials}
            formatOptionLabel={this.formatOptionLabel}
            //defaultMenuIsOpen={true}
            placeholder={`Material for ${id}`}
            isClearable={false}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={(e) => this.onSetLabel(this, 'material', e, id, group)}
          />
        </li>
      );
    } else {
      return (
        <li
          key={`Part_${id}`}
          id={`Part_${id}`}
          className="list-group-item"
          onClick={this.onHighlight.bind(this, id)}
        >
          <Select
            ref={(ref) => {
              this.selectBox[`LabelSelect_${id}`] = ref;
            }}
            id={`LabelSelect_${id}`}
            defaultValue={defaultValue}
            options={options}
            //defaultMenuIsOpen={true}
            placeholder={`Label for ${id}`}
            isClearable={false}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={(e) => this.onSetLabel(this, 'label', e, id)}
          />
        </li>
      );
    }
  };

  render() {
    const { item, detailLabel } = this.props.cage;
    let taskProcessView;
    let svgCage;
    let mappingDashboard = [];
    let {
      parts,
      partsPart,
      partsWheel,
      partsTire,
      partsBody,
      partsGlass,
      svgSrc,
      imageSrc,
    } = this.state;

    if (this.state.svgSrc) {
      svgCage = (
        <SVG
          id="Cagebox"
          className="svg-hoverable"
          src={svgSrc}
          style={{
            pointerEvent: 'none',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          uniquifyIDs={false}
          onLoad={() => {
            this.svgLoader();
          }}
        ></SVG>
      );
    }

    const { partLabelOptions, listMaterials } = this.state;

    const partUndefined = Object.keys(parts);
    let viewPartIdsItem = [];
    for (let i = 0; i < partUndefined.length; i++) {
      viewPartIdsItem.push(
        this.mappingSelectItem(partUndefined[i], partLabelOptions),
      );
    }
    //PART
    let viewPart = [],
      viewBody = [],
      viewWheel = [],
      viewGlass = [],
      viewTire = [];
    [partsPart, partsWheel, partsGlass, partsTire, partsBody].forEach(
      (item) => {
        const partKeys = Object.keys(item);

        for (let i = 0; i < partKeys.length; i++) {
          let label = item[partKeys[i]].label;
          let group = item[partKeys[i]].group;
          let material = item[partKeys[i]].material;
          if (group === 'PART') {
            viewPart.push(
              this.mappingSelectItem(
                partKeys[i],
                partLabelOptions,
                label,
                group,
                listMaterials,
                material,
              ),
            );
          }
          if (group === 'BODY') {
            viewBody.push(
              this.mappingSelectItem(
                partKeys[i],
                partLabelOptions,
                label,
                group,
                listMaterials,
                material,
              ),
            );
          }
          if (group === 'WHEEL') {
            viewWheel.push(
              this.mappingSelectItem(
                partKeys[i],
                partLabelOptions,
                label,
                group,
                listMaterials,
                material,
              ),
            );
          }
          if (group === 'TIRE') {
            viewTire.push(
              this.mappingSelectItem(
                partKeys[i],
                partLabelOptions,
                label,
                group,
                listMaterials,
                material,
              ),
            );
          }
          if (group === 'GLASS') {
            viewGlass.push(
              this.mappingSelectItem(
                partKeys[i],
                partLabelOptions,
                label,
                group,
                listMaterials,
                material,
              ),
            );
          }
        }
      },
    );
    mappingDashboard = (
      <div id="MappingDashboard" className="list-damage">
        <div id="Part_ids_list" className="group-card accordion">
          <AccordionItem
            id="undefined"
            buttonLabel="Undefined"
            bodyContent={viewPartIdsItem}
            show={viewPartIdsItem.length > 0}
            length={viewPartIdsItem.length}
            parent="#Part_ids_list"
          />
          <AccordionItem
            id="part"
            buttonLabel="PART"
            bodyContent={viewPart}
            show={viewPart.length > 0}
            length={viewPart.length}
            parent="#Part_ids_list"
          />
          <AccordionItem
            id="body"
            buttonLabel="Body"
            bodyContent={viewBody}
            show={viewBody.length > 0}
            length={viewBody.length}
            parent="#Part_ids_list"
          />
          <AccordionItem
            id="wheel"
            buttonLabel="Wheel"
            bodyContent={viewWheel}
            show={viewWheel.length > 0}
            length={viewWheel.length}
            parent="#Part_ids_list"
          />
          <AccordionItem
            id="tire"
            buttonLabel="Tire"
            bodyContent={viewTire}
            show={viewTire.length > 0}
            length={viewTire.length}
            parent="#Part_ids_list"
          />
          <AccordionItem
            id="glass"
            buttonLabel="Glass"
            bodyContent={viewGlass}
            show={viewGlass.length > 0}
            length={viewGlass.length}
            parent="#Part_ids_list"
          />
        </div>
      </div>
    );
    taskProcessView = (
      <div id="ImageProcessor" className="fullContainer">
        <div className="align-self-center w-100">{svgCage}</div>
      </div>
    );
    let photoModal = [];
    if (imageSrc) {
      photoModal.push(
        <button
          type="button"
          key={`Photo_2_modal_button`}
          className={'btn btn-sm btn-primary btn-photo ml-3'}
          data-toggle="modal"
          data-target={`#Cage_02_modal`}
        >
          Photo Car
        </button>,
      );
      photoModal.push(
        <FileModal
          key={`Photo_2_modal`}
          title="Photo"
          src={imageSrc}
          id={`Cage_02`}
        />,
      );
    }
    return (
      <div id="CageProcess">
        <div className="row">
          <div className="col-lg-8 col-xl-9">
            <div className="cage-label">
              <h2 className="subtitle">
                <small>
                  <Link
                    to={item._id ? `/cageView/${item._id}` : `/cages`}
                    className="text-logan"
                  >
                    <i className="fa fa-arrow-left" />
                    <span>{`${item.year} ${item.make} ${item.model}`}</span>
                  </Link>
                </small>
                <br />
                {detailLabel ? detailLabel.code + ' / ' + detailLabel.name : ''}
                {photoModal}
              </h2>
              {taskProcessView}
            </div>
          </div>
          <div className="col-lg-4 col-xl-3">
            <div className="label-data">
              {mappingDashboard}
              <button
                type="button"
                className={'btn btn-danger btn-delete-all '}
                onClick={(e) => this.onDeleteAll(e)}
              >
                Delete All
              </button>
              <button
                type="button"
                className={'btn btn-primary btn-label '}
                onClick={() =>
                  this.returnCage(
                    detailLabel._id,
                    partsPart,
                    partsWheel,
                    partsTire,
                    partsBody,
                    partsGlass,
                    parts,
                  )
                }
              >
                Label Done
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  cage: state.cage,
});

export default connect(mapStateToProps, { getCageLabelDetail, putCageLabel })(
  CageLabel,
);
