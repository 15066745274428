import React from 'react';

const TimelineNode = ({
  label = {},
  duration = {},
  pic = {},
  progress,
  changeAction,
  editTaskSlot,
  onRejectTask,
  onReportImage,
  kickAgent,
  plusTime,
}) => {
  const alignClass =
    pic && pic.value && duration && (duration.value || duration.value === 0)
      ? 'd-flex justify-content-between'
      : 'text-right';
  return (
    <div className="process-timeline-node row">
      <div className="col-4 text-right">
        {changeAction}
        <div
          className={`badge badge-${
            label.style || 'secondary'
          } text-head text-uppercase`}
        >
          {label.value || '--'}
        </div>
        {label.position && (
          <div className="badge badge-warning text-head text-uppercase ml-1">
            {label.position === 'front' ? 'F' : 'R'}
          </div>
        )}
        {editTaskSlot}
        {onRejectTask}
        {onReportImage}
      </div>

      <div className={`col-4 ${alignClass}`}>
        {pic && pic.value && (
          <div>
            <div
              className={`badge badge-${pic.style || 'secondary'}`}
              style={{ textTransform: 'lowercase' }}
            >
              {pic.value || '--'}
            </div>
            {plusTime ? <i className=" ml-1 fas fa-user-clock"></i> : null}
            {kickAgent}
          </div>
        )}
        {duration && (duration.value || duration.value === 0) && (
          <div className={`badge badge-${duration.style || 'secondary'}`}>
            {duration.value || duration.value === 0
              ? duration.value + (duration.unit || ' s')
              : '--'}
          </div>
        )}
      </div>
      <div className="col-3">{progress}</div>
    </div>
  );
};
export default TimelineNode;
