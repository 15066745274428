import React from 'react';
import PropTypes from 'prop-types';
import AccordionItem from '../widgets/AccordionItem';

const CageCreateInfo = ({ data, show, onChange, settings, errors }) => {
  const { listBodyType, listMake, listYear } = settings;

  let selectYears = [];
  for (let i = listYear.max; i >= listYear.min; i--) {
    selectYears.push(
      <option key={`Year_${i}`} value={i}>
        {i}
      </option>,
    );
  }

  console.log('data: ', data);

  const bodyContent = (
    <div key="Cage_options" id="Cage_options">
      <div className="row mt-3">
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Make</h3>
          <select
            className={`form-control form-control-sm ${
              errors.make ? 'is-invalid' : ''
            }`}
            name="make"
            value={data.make || ''}
            onChange={onChange}
          >
            <option value="">( select make )</option>
            {listMake &&
              listMake.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
          </select>
          {errors.make && (
            <div className="invalid-feedback mt-1">{errors.make}</div>
          )}
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Model</h3>
          <input
            type="text"
            className={`form-control form-control-sm ${
              errors.model ? 'is-invalid' : ''
            }`}
            name="model"
            value={data.model || ''}
            onChange={onChange}
          />
          {errors.model && (
            <div className="invalid-feedback mt-1">{errors.model}</div>
          )}
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Year</h3>
          <select
            className={`form-control form-control-sm ${
              errors.year ? 'is-invalid' : ''
            }`}
            name="year"
            value={data.year || ''}
            onChange={onChange}
          >
            <option value="">( select year )</option>
            {selectYears}
          </select>
          {errors.year && (
            <div className="invalid-feedback mt-1">{errors.year}</div>
          )}
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Body Type</h3>
          <select
            className={`form-control form-control-sm ${
              errors.bodyType ? 'is-invalid' : ''
            }`}
            name="bodyType"
            value={data.bodyType || ''}
            onChange={onChange}
          >
            <option value="">( select body type )</option>
            {listBodyType &&
              listBodyType.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
          </select>
          {errors.bodyType && (
            <div className="invalid-feedback mt-1">{errors.bodyType}</div>
          )}
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Trim &amp; Configuration</h3>
          <input
            type="text"
            className={`form-control form-control-sm`}
            name="trim"
            value={data.trim || ''}
            onChange={onChange}
          />
        </div>
        <div className="col-6 col-lg-2">
          <h3 className="display-6 text-secondary">Platform Code</h3>
          <input
            type="text"
            className={`form-control form-control-sm`}
            name="code"
            value={data.code || ''}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
  return (
    <AccordionItem
      id="info"
      buttonLabel="Information"
      bodyContent={bodyContent}
      show={show}
    />
  );
};

CageCreateInfo.propTypes = {
  data: PropTypes.object.isRequired,
};

CageCreateInfo.defaultProps = {
  show: false,
};

export default CageCreateInfo;
