import React from 'react';
import AdminInspect from '../../../../inspect/containers/AdminInspect';

const ModalInspect = (props) => {
	const taskSlotID = props.taskSlotID ? props.taskSlotID : false;
	const { hideModal } = props;
	return (
		<div className={`modal inspect show-modal`} id="myModal">
			<div className="modal-dialog modal-inspect">
				<div className="modal-content">
					<div className="modal-body">
						<AdminInspect
							hideModal={hideModal}
							taskSlotID={taskSlotID}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalInspect;
