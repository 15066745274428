import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAgents, deleteAgent, putAgent } from '../operations';
import { UserListTable } from './components';
import Pagination from '../../../components/common/Pagination';
import Permission from '../../ui/containers/Permission';
import CountComponent from '../../ui/containers/CountComponent';
import ModalMD from '../../inspections/containers/components/partials/ModalMD';

const AgentList = (props) => {
  const { user } = props.auth;
  const { list } = props.user;
  const { listRole, listShifts } = props.user.settings;
  let [filters, setfilters] = useState({});
  let [limit, setlimit] = useState(10);
  let [page, setpage] = useState(1);
  const [modalConfirm, setmodalConfirm] = useState(false);
  const [userDelete, setuserDelete] = useState({});
  const pages = list && list.pages ? list.pages : 1;
  const count = list && list.count ? list.count : 1;
  const listCounts = [
    {
      count: list && list.countWaiting ? list.countWaiting : '0',
      backGround: '#3498db',
      text: 'Waiting',
      icon: 'fas fa-piggy-bank',
    },
    {
      count: list && list.countOnline ? list.countOnline : '0',
      backGround: '#2ecc71',
      text: 'Online',
      icon: 'fas fa-grin-wink',
    },
    {
      count: list && list.countInspecting ? list.countInspecting : '0',
      backGround: '#f1c40f',
      text: 'Inspecting',
      icon: 'fas fa-running',
    },
    {
      count: list && list.countChecking ? list.countChecking : '0',
      backGround: '#f1c40f',
      text: 'Checking',
      icon: 'fas fa-search',
    },
  ];
  const timezone =
    user && user.options && user.options.timezone
      ? user.options.timezone
      : 'Asia/Ho_Chi_Minh';
  useEffect(() => {
    let listAgents;
    listAgents = setInterval(() => {
      props.getAgents(
        {
          filters,
          page: page,
          limit: limit,
        },
        false,
      );
    }, 5000);

    return () => {
      clearInterval(listAgents);
    };
    // eslint-disable-next-line
  }, [limit, page, filters]);
  const onPageChange = (e) => {
    e.preventDefault();
    if (e.target.getAttribute('data-value')) {
      limit = parseInt(e.target.getAttribute('data-value') || limit, 10);
    }
    if (e.target.getAttribute('data-page')) {
      page = parseInt(e.target.getAttribute('data-page'), 10);
    } else if (e.target.value) {
      page = parseInt(e.target.value, 10);
    }
    props.getAgents({
      page: page,
      limit: limit,
      filters: filters,
    });
    setpage(page);
    setlimit(limit);
  };
  const onSort = (name) => {
    let filter = filters;
    filter.sorts = {
      [name]: filter['sorts'] && filter['sorts'][name] === 1 ? -1 : 1,
    };
    console.log(filter);
    props.getAgents({
      filters: filter,
      limit: limit,
    });
    setfilters(filter);
  };
  const deleteAgent = async () => {
    const data = {};
    data['id'] = userDelete.id;
    const success = await props.deleteAgent(data);
    if (success) {
      setmodalConfirm(false);
      props.getAgents({
        filters,
        limit: limit,
        page: page,
      });
    }
  };
  const showModalConfirm = (id, username) => {
    setuserDelete({
      id: id,
      username: username,
    });
    setmodalConfirm(true);
  };
  const putAgent = async (data, id) => {
    const success = await props.putAgent(id, data);
    if (success) {
      props.getAgents({
        filters,
        limit: limit,
      });
    }
  };

  const onChange = (e) => {
    let filter = filters;
    filter[e.target.name] = e.target.value;
    props.getAgents({
      filters: filter,
      limit: limit,
    });
    setpage(1);
    setfilters(filter);
  };
  return (
    <div id="User_list">
      {['ADMIN', 'SUPER', 'TRAINER'].includes(user.role) ? (
        <div className="container-fluid">
          <h2 className="subtitle">Our Agents</h2>
          <div className="buttons">
            <Link to="/agentCreate" className="btn btn-success">
              <i className="fa fa-lg fa-plus" />
              <span>Add new agent</span>
            </Link>
          </div>
          <div className="buttons">
            <Link to="/agentSkills" className="btn btn-success mr-2">
              <i className="fa fa-lg fa-pen" />
              <span>Set Users</span>
            </Link>
          </div>
          <Pagination
            size={limit}
            records={count}
            pages={pages}
            page={page}
            action={onPageChange}
          />
          <CountComponent listCounts={listCounts} />
          <UserListTable
            onSort={onSort}
            showModalConfirm={showModalConfirm}
            data={list.data}
            filters={filters}
            onChange={onChange}
            timezone={timezone}
            listRole={listRole}
            listShifts={listShifts}
            user={user}
            deleteAgent={deleteAgent}
            putAgent={putAgent}
          />
        </div>
      ) : (
        <Permission />
      )}
      <ModalMD
        show={modalConfirm}
        title="Set Standard"
        body={
          <div className="main-content">
            <div className="form-group">
              <div className="text-center">
                <span className="text-head" style={{ fontSize: '20px' }}>
                  {`Are u sure that u want to delete this ${userDelete.username} Agent`}
                </span>
              </div>
            </div>
            <div className="btn-group float-right">
              <button className="btn btn-warning" onClick={deleteAgent}>
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  Yes
                </span>
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setmodalConfirm(false)}
              >
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  No
                </span>
              </button>
            </div>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setmodalConfirm(false)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  setting: state.setting,
});

export default connect(mapStateToProps, { getAgents, deleteAgent, putAgent })(
  AgentList,
);
