import React from 'react';
import PropTypes from 'prop-types';

const AccordionItem = ({ buttonLabel, bodyContent, id, show }) => {
	return (
		<div className="card">
			<div
				className={`card-header clickable ${show}`}
				id={`Accordion_${id}_head`}
				aria-expanded={show}
			>
				{buttonLabel}
			</div>
			<div
				id={`Accordion_${id}_content`}
				className={`collapse ${show ? 'show' : ''}`}
				aria-labelledby={`Accordion_${id}_head`}
				data-parent="#Cage_item_accordion"
			>
				<div className="card-body">{bodyContent}</div>
			</div>
		</div>
	);
};

AccordionItem.propTypes = {
	buttonLabel: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	show: PropTypes.bool,
	parent: PropTypes.string,
};

AccordionItem.defaultProps = {
	show: false,
	parent: '',
};

export default AccordionItem;
