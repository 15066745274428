import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getInspection,
  clearInspection,
  clearSuccess,
  completeInspection,
} from '../operations';
import { InspectionViewInfo, InspectionViewReport } from './components';
import InspectionViewInspect from './components/InspectionViewInspect';

const InspectionView = (props) => {
  const [autoRefresh, setautoRefresh] = useState(true);
  const [loadFirstTime, setloadFirstTime] = useState(false);
  const { inspection, auth } = props;
  const { item, settings } = inspection;
  const validator = item?.data?.validator;
  const inspector = item?.data?.inspector;
  const qa = item?.data?.qa;
  const manager = item?.data?.manager;
  const themeName = item?.data?.inspectionThemes?.name;

  const themeColor = item?.data?.inspectionThemes?.themeColor;

  let content = [];
  let timer;
  const onCompleteInspection = () => {
    if (item && item.data && item.data.inspectionID) {
      props.completeInspection(item.data.inspectionID);
    }
  };
  const onToggle = () => {
    const state = !autoRefresh;
    setautoRefresh(state);
  };

  const newTab = (id, type) => {
    const link = window.location.origin;
    let url;
    if (type === 'inspection') {
      url = link + `/inspectionView/${id}`;
    } else if (type === 'cage') {
      url = link + `/cageView/${id}`;
    }
    const win = window.open(url, '_blank');
    win.focus();
  };
  if (item && item.data) {
    content.push(
      <InspectionViewInfo
        setautoRefresh={() => setautoRefresh(false)}
        key="Inspection_info"
        item={item.data || {}}
        tasks={item.tasks || []}
        user={(auth && auth.user) || {}}
        settings={settings}
        onCompleteInspection={onCompleteInspection}
        newTab={newTab}
      />,
    );
    content.push(
      <InspectionViewInspect
        key="Inspection_inspect"
        photosSetting={item.photosSetting || []}
        tasks={item.tasks || []}
        inspection={item.data || {}}
        adminAction={inspection.adminAction || {}}
        settings={settings}
        show={true}
      />,
    );
    if (['FINISHED', 'COMPLETED'].includes(item.data.status)) {
      content.push(
        <InspectionViewReport
          key="Inspection_report"
          data={item.data.reportData || {}}
          settings={settings}
        />,
      );
    }
  }
  useEffect(() => {
    return () => {
      props.clearInspection();
    };
  }, []);
  useEffect(() => {
    if (!loadFirstTime) {
      props.getInspection(props.match.params.id, true);
      setloadFirstTime(true);
    }
    if (loadFirstTime && inspection.success) {
      props.clearSuccess();
      const status = item.data.status;
      if (status === 'COMPLETED') {
        setautoRefresh(false);
      }
    }
    if (autoRefresh) {
      timer = setInterval(() => {
        props.getInspection(props.match.params.id, false);
      }, 5000);
    } else {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [inspection.success, autoRefresh]);
  return (
    <div id="Inspection_item">
      <div className="container-fluid">
        <h2 className="subtitle">
          <small>
            <Link to="/inspections" className="text-logan">
              <i className="fa fa-arrow-left" />
              <span>Inspection Manager</span>
            </Link>
          </small>
          <br />
          <div className="d-flex align-items-center">
            {item && item.data && item.data.group ? (
              <div
                style={{ fontSize: '18px' }}
                className={`badge mr-2 ${
                  ['DEFAULT', 'DEF-1', 'DEF-2', 'DEF-3'].includes(
                    item.data.group,
                  )
                    ? 'badge-success'
                    : 'badge-primary'
                }`}
              >
                {item.data.group}
              </div>
            ) : null}
            {item && item.data && item.data.inspectionID
              ? item.data.inspectionID
              : ''}
            /
            {item && item.data && item.data.sessionID
              ? item.data.sessionID
              : ''}
            {item &&
            item.data &&
            item.data.options &&
            item.data.options.priority_level ? (
              <div
                style={{ fontSize: '18px' }}
                className={`badge ${
                  ['F1'].includes(item.data.options.priority_level)
                    ? 'badge-warning'
                    : 'badge-dark'
                } ml-2`}
              >
                {item.data.options.priority_level}
              </div>
            ) : null}
            {item && item.data && item.data.holding ? (
              <div
                style={{ fontSize: '18px' }}
                className={`badge badge-warning ml-2`}
              >
                Holding
              </div>
            ) : null}
            {item && item.data && item?.data?.skipVin ? (
              <div
                style={{ fontSize: '18px' }}
                className={`badge badge-danger ml-2`}
              >
                Skip Vin
              </div>
            ) : null}
          </div>

          <div className="actions">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="Refresh"
                name="autoRefresh"
                checked={autoRefresh}
                onChange={onToggle}
              />
              <label className="custom-control-label" htmlFor="Refresh">
                Refresh
              </label>
            </div>
          </div>
        </h2>
        <div className="mb-1 d-flex align-items-center">
          <div className="d-inline text-head mr-1">
            <span className="text-uppercase mr-1">Theme</span>
            <div
              className={`badge text-uppercase badge-primary py-2`}
              style={{ background: `${themeColor}` }}
            >
              <span>{themeName}</span>
            </div>
          </div>
          {validator ? (
            <div className="d-inline text-head mr-1 ">
              <div className="badge badge-warning mr-1 py-2">Validator</div>
              <span>{validator}</span>
            </div>
          ) : null}
          {inspector ? (
            <div className="d-inline text-head mr-1 ">
              <div className="badge badge-danger mr-1 py-2">Inspector</div>
              <span>{inspector}</span>
            </div>
          ) : null}

          {qa ? (
            <div className="d-inline text-head mr-1">
              <div className="badge badge-dark mr-1 py-2">QA</div>
              <span>{qa}</span>
            </div>
          ) : null}

          {manager ? (
            <div className="d-inline text-head mr-1">
              <div className="badge badge-dark mr-1 py-2">Manager</div>
              <span className="">{manager}</span>
            </div>
          ) : null}
        </div>

        <div id="Inspection_panels" className="accordion">
          {content}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  inspection: state.inspection,
  setting: state.setting,
  auth: state.auth,
  ui: state.ui,
});

export default connect(mapStateToProps, {
  getInspection,
  clearInspection,
  clearSuccess,
  completeInspection,
})(InspectionView);
