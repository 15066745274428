import actions from './actions';
import * as client from '../../utils/client';
let agentAPI =
  process.env.REACT_APP_AGENT_BASE_URL || 'https://agent.paveapi.com';
export const getAllCages = (params, loading = true) => {
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/cages`,
      params,
      function (res) {
        dispatch(actions.getAllCages(res));
      },
      loading,
    );
};

export const getCage = (id) => {
  return (dispatch) =>
    client.exec('get', `${agentAPI}/api/cages/${id}`, {}, function (res) {
      dispatch(actions.getCage(res));
    });
};

export const getCageSettings = () => {
  return (dispatch) =>
    client.exec('get', `${agentAPI}/api/cages/settings`, {}, function (res) {
      dispatch(actions.getCageSettings(res));
    });
};

export const postCage = (data, history) => {
  return (dispatch) =>
    client.exec('post', `${agentAPI}/api/cages`, data, function (res) {
      dispatch(actions.postCage(res));
      if (history && res && res.cage && res.cage._id) {
        history.push(`/cageView/${res.cage._id}`);
      }
    });
};

export const postCageNote = (id, data) => {
  return (dispatch) =>
    client.exec(
      'post',
      `${agentAPI}/api/cages/${id}/notes`,
      data,
      function (res) {
        dispatch(actions.postCageNote(res));
      },
    );
};

export const putCage = (id, data) => {
  return (dispatch) =>
    client.exec('put', `${agentAPI}/api/cages/${id}`, data, function (res) {
      dispatch(actions.putCage(res));
    });
};

export const createCageDetail = (data) => {
  return (dispatch) =>
    client.exec(
      'post',
      `${agentAPI}/api/cages/details/create`,
      data,
      function (res) {
        dispatch(actions.createCageDetail(res));
      },
    );
};
export const putCageDetail = (id, data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/cages/details/${id}`,
      data,
      function (res) {
        dispatch(actions.putCageDetail(res));
      },
    );
};

export const putCageStatus = (id, data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/cages/${id}/status`,
      data,
      function (res) {
        dispatch(actions.putCageStatus(res));
      },
    );
};

export const deleteCage = (id, history) => {
  return (dispatch) =>
    client.exec('delete', `${agentAPI}/api/cages/${id}`, {}, function (res) {
      dispatch(actions.deleteCage(res));
      if (history) {
        history.push('/cages');
      }
    });
};
export const getCageLabelDetail = (id, action) => {
  return (dispatch) => {
    client.exec(
      'get',
      `${agentAPI}/api/cages/details/${id}`,
      { action: action },
      function (res) {
        dispatch(actions.getCageLabelDetailDone(res));
      },
    );
  };
};
export const uploadFile = (id, data) => {
  return (dispatch) => {
    client.exec(
      'post',
      `${agentAPI}/api/cages/details/upload/${id}`,
      data,
      function (res) {
        dispatch(actions.uploadFile(res.data));
      },
      true,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  };
};
export const putCageLabel = (id, putData, history = false) => {
  return (dispatch) => {
    client.exec(
      'put',
      `${agentAPI}/api/cages/details/${id}`,
      putData,
      function (res) {
        dispatch(actions.putCageLabelDone(res.data));
        if (history && putData.cageId) {
          history.push(`/cageView/${putData.cageId}`);
        }
      },
    );
  };
};
