import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import inspection from './app/inspections';
import inspect from './app/inspect';
import dashboard from './app/dashboard';
import cage from './app/cages';
import auth from './app/auth';
import user from './app/users';
import ui from './app/ui';
import taskFailed from './app/taskFailed';
import setting from './app/settings';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistStore, persistReducer } from 'redux-persist';
const rootReducer = combineReducers({
  ui,
  auth,
  cage,
  dashboard,
  inspection,
  user,
  setting,
  inspect,
  taskFailed,
});
const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};
const pReducer = persistReducer(persistConfig, rootReducer);
const initialState = {};
const middleware = [thunk];
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  // other store enhancers if any
);
export const store = createStore(pReducer, initialState, enhancer);
export const persistor = persistStore(store);
