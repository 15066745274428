import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';

import {
  CageViewInfo,
  CageViewDetail,
  CageViewNotes,
  CageViewHistory,
} from './components';
import {
  getCage,
  putCage,
  createCageDetail,
  putCageDetail,
  putCageStatus,
  postCageNote,
  uploadFile,
} from '../operations';

import Permission from '../../ui/containers/Permission';

const CageView = (props) => {
  const {
    getCage,
    putCage,
    createCageDetail,
    putCageDetail,
    putCageStatus,
    // postCageNote,
    uploadFile,
  } = props;

  const { id } = useParams();
  const cage = useSelector((state) => state.cage);
  const auth = useSelector((state) => state.auth);

  const [state, setState] = useState({
    make: null,
    model: null,
    year: null,
    bodyType: null,
    trim: null,
    code: null,
    updatedField: null,
    upload: false,
    note: { errors: {} },
  });

  useEffect(() => {
    getCage(id);
  }, [id]);

  useEffect(() => {
    if (cage.item) {
      const item = cage.item;
      setState((prevState) => ({
        ...prevState,
        make: item.make,
        model: item.model,
        year: item.year,
        bodyType: item.bodyType,
        trim: item.trim,
        code: item.code,
      }));
    }
  }, [cage.item]);

  const onChange = (update = false, e) => {
    const { value, name } = e.target;

    const updateState = { ...state, [name]: value };

    setState(updateState);

    if (update) {
      letUpdate(updateState);
    }
  };

  const onRemoveUser = (id, type, e) => {
    e.preventDefault();

    putCageDetail(id, {
      labelAction: 'remove',
      type: type,
    });
  };

  const onChangeStatus = (action = 'next', e) => {
    e.preventDefault();
    putCageStatus(id, { action });
  };

  const onUpload = (id, type, e) => {
    const { item } = cage;
    const file = e.target.files[0];
    if (id && file) {
      setState((prevState) => ({ ...prevState, upload: true }));
      const formData = new FormData();
      formData.append('type', type);
      formData.append('file', file);
      uploadFile(id, formData, item._id);
    }
  };

  // const onSubmitNote = (e) => {
  //   e.preventDefault();
  //   const required = ['type', 'message'];
  //   const { note } = state;
  //   let errors = {};
  //   required.forEach((key) => {
  //     if (!note[key]) {
  //       errors[key] = 'This is a required field';
  //     }
  //   });
  //   if (Object.keys(errors).length > 0) {
  //     setState((prevState) => ({ ...prevState, note: { errors: errors } }));
  //     return false;
  //   }
  //   postCageNote(id, note);
  // };

  // const onChangeNote = (e) => {
  //   e.preventDefault();
  //   let noteData = state.note;

  //   const { name, value } = e.target;
  //   noteData[name] = value;
  //   setState((prevState) => ({ ...prevState, note: noteData }));
  // };

  const letUpdate = (data) => {
    putCage(id, data);
  };

  const _createCageDetail = (photoCode) => {
    const { title } = state;
    const { item } = cage;
    const data = {
      name: title,
      photoCode: photoCode,
      cageId: item._id,
    };
    createCageDetail(data);
  };

  // const onToggle = (e) => {
  //   const newState = !state[e.target.name];
  //   setState((prevState) => ({ ...prevState, [e.target.name]: newState }));
  // };

  const { item, files, success } = cage;
  const { user } = auth;
  let data = {
    ...item,
    ...state,
  };
  let heading;
  let content = [];
  let successCallout;

  if (item) {
    heading = `${item.year} ${item.make} ${item.model}`;
    if (success === 'created') {
      successCallout = (
        <div
          className="alert alert-success alert-dismissible fade show my-3"
          role="alert"
        >
          Model cage <strong className="text-info">{heading}</strong> is
          successfully created.
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }

    content.push(
      <CageViewInfo
        key="Info"
        data={data}
        show={true}
        onChange={onChange}
        onChangeStatus={onChangeStatus}
        success={success}
        settings={cage.settings}
        user={user}
      />,
    );

    content.push(
      <CageViewDetail
        key="Detail"
        inforCage={data}
        data={item.cageDetails || []}
        cage={item}
        files={files}
        show={true}
        onUpload={onUpload}
        user={user}
        onRemoveUser={onRemoveUser}
        onChange={onChange}
        createCageDetail={_createCageDetail}
      />,
    );

    content.push(
      <CageViewNotes
        key="Notes"
        data={item.cageNotes || []}
        show={true}
        settings={cage.settings}
      />,
    );

    content.push(
      <CageViewHistory
        key="History"
        show={true}
        data={item.cageHistories || []}
      />,
    );
  }

  if (['AGENT', 'TRAINEE'].includes(user.role)) {
    return <Permission />;
  }

  return (
    <div id="Cage_item">
      <div className="container-fluid">
        <h2 className="subtitle">
          <small>
            <Link to="/cages" className="text-logan">
              <i className="fa fa-arrow-left" />
              <span>Cage Manager</span>
            </Link>
          </small>
          <br />
          {heading}
        </h2>
        {successCallout}
        <div className="accordion" id="Cage_item_accordion">
          {content}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cage: state.cage,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getCage,
  putCage,
  createCageDetail,
  putCageDetail,
  putCageStatus,
  postCageNote,
  uploadFile,
})(CageView);
