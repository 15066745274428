import * as types from './types';
const initialState = {
  item: {},
  cages: [],
  settings: {},
  taskChange: null,
};
const findIndex = require('lodash/findIndex');

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_INSPECTIONS_LEFT:
      return {
        ...state,
        item: null,
        left: {
          ...action.payload,
        },
        dataSameVin: {},
      };
    case types.GET_INSPECTIONS_RIGHT:
      return {
        ...state,
        item: null,
        right: {
          ...action.payload,
        },
        dataSameVin: {},
      };
    case types.GET_INSPECTIONS_RANDOM:
      return {
        ...state,
        item: null,
        rd: {
          ...action.payload,
        },
        dataSameVin: {},
      };
    case types.GET_ADMIN_ACTION:
      return {
        ...state,
        adminAction: { ...action.payload },
      };
    case types.GET_INSPECTION:
      return {
        ...state,
        ...action.payload,
        success: true,
      };
    case types.CLEAR_SUCCESS:
      return {
        ...state,
        success: false,
      };
    case types.CLEAR_INSPECTION:
      return {
        ...state,
        item: {},
        success: false,
      };
    case types.GET_ACTIVE_CAGES:
      return {
        ...state,
        cages: { ...action.payload },
      };
    case types.PUT_INSPECTION_CAGE:
      return {
        ...state,
        ...action.payload,
      };
    case types.CHANGE_STATUS_TASK_SLOT:
      return {
        ...state,
        taskChange: { ...action.payload },
      };
    case types.COMPLETE_INSPECTION:
      return {
        ...state,
        item: action.payload.item || {},
      };
    case types.GET_PROCESS_PHOTO: {
      const newTask = action.payload.task;
      let tasks = state.item.tasks || [];
      const taskIndex = findIndex(tasks, { id: newTask.id });
      if (taskIndex) {
        tasks[taskIndex].image_cap = newTask.image_cap;
      }
      state.list.tasks = tasks;
      return {
        ...state,
      };
    }
    case types.GET_SESSION_RD:
      return {
        ...state,
        dataRd: action.payload,
      };
    default:
      return state;
  }
}
