import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCage, deleteCage } from '../operations';

const CageDelete = ({ cage, getCage, deleteCage }) => {
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  const [year, setYear] = useState(null);
  const [bodyType, setBodyType] = useState(null);
  const [trim, setTrim] = useState(null);
  const [code, setCode] = useState(null);

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getCage(id);
    }
  }, [id, getCage]);

  useEffect(() => {
    if (cage.item) {
      const item = cage.item;
      setIsDuplicate(`${item.year} ${item.make} ${item.model}`);
      setMake(item.make);
      setModel(item.model);
      setYear(item.year);
      setBodyType(item.bodyType);
      setTrim(item.trim);
      setCode(item.code);
    }
  }, [cage]);

  const onSubmit = (e) => {
    e.preventDefault();
    deleteCage(id, history);
  };

  const item = { isDuplicate, make, model, year, bodyType, trim, code };

  return (
    <div id="Cage_item">
      <div className="container-fluid">
        <h2 className="subtitle">
          <small>
            <Link to="/cages" className="text-logan">
              <i className="fa fa-arrow-left" />
              <span>Cage Manager</span>
            </Link>
          </small>
          <br />
          <span>
            Are you sure to delete this model cage:{' '}
            <span className="text-danger">
              {item.year} {item.make} {item.model}
            </span>
          </span>
        </h2>
        <div className="btn-group mt-4">
          <Link to="/cages" className="btn btn-secondary">
            <i className="fa fa-angle-left" />
            <span>Let's go back</span>
          </Link>
          <button className="btn btn-danger" onClick={onSubmit}>
            <i className="fa fa-times" />
            <span>Delete It</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cage: state.cage,
});

export default connect(mapStateToProps, { getCage, deleteCage })(CageDelete);
