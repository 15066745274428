const materials = [
  {
    label: 'Painted',
    value: 'Painted',
  },
  {
    label: 'Textured Surface',
    value: 'Textured Surface',
  },
  {
    label: 'Chrome',
    value: 'Chrome',
  },
  {
    label: 'Steel',
    value: 'Steel',
  },
  {
    label: 'Alloy Wheel',
    value: 'Alloy Wheel',
  },
  {
    label: 'Plastic Cover',
    value: 'Plastic Cover',
  },
  {
    label: 'Steel Wheel',
    value: 'Steel Wheel',
  },
  {
    label: 'Glass',
    value: 'Glass',
  },
];
module.exports = {
  materials,
};
