import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TextFieldGroup from '../../../components/common/TextFieldGroup';
import { postAgent, getUserSettings } from '../operations';

class AgentNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: {
        username: '',
        password: '',
        email: '',
        first: '',
        last: '',
        location: 'HCM',
        role: 'AGENT',
        sex: 'Male',
        gourps: [],
      },
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getUserSettings();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors && nextProps.errors.data) {
      this.setState({ errors: nextProps.errors.data });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.postAgent(this.state.item, this.props.history);
  }

  onFocus(e) {
    const { errors } = this.state;
    errors[e.target.name] = '';
    this.setState({ errors: errors });
  }

  onChange(e) {
    let { item } = this.state;
    item[e.target.name] = e.target.value;
    this.setState({ item: item });
  }

  render() {
    const { item, errors } = this.state;
    const { listLocation, listRole, listSex } = this.props.user.settings;
    const { auth } = this.props;
    const role = auth && auth.user && auth.user.role ? auth.user.role : '';
    return (
      <div id="Agent_item">
        <div className="container-fluid">
          <h2 className="subtitle">
            <small>
              <Link to="/agents" className="text-logan">
                <i className="fa fa-arrow-left" />
                <span>Our Agent</span>
              </Link>
            </small>
            <br />
            <span>Create New Agent</span>
          </h2>
          <form onSubmit={this.onSubmit} autoComplete="noPrefillPlease">
            <div className="card">
              <div className="card-header">Account Information</div>
              <div className={`show`}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-lg-3">
                      <TextFieldGroup
                        label="Username"
                        placeholder="Please enter account username"
                        name="username"
                        value={item.username}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        error={errors.username}
                        autoComplete="off"
                      />
                      <TextFieldGroup
                        label="Password"
                        placeholder="Please enter account password"
                        name="password"
                        value={item.password}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        error={errors.password}
                        autoComplete="off"
                      />
                      <TextFieldGroup
                        label="Email"
                        type="email"
                        placeholder="e.g. agent@pave.bot"
                        name="email"
                        value={item.email}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        error={errors.email}
                      />
                      <label className="display-6 text-secondary text-head">
                        Sex
                      </label>
                      <select
                        className="form-control"
                        name="sex"
                        onChange={this.onChange}
                        value={item.sex}
                      >
                        {listSex &&
                          listSex.map((loc) => (
                            <option key={`sex_${loc}`} value={loc}>
                              {loc}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-12 col-lg-3">
                      <TextFieldGroup
                        label="First Name"
                        placeholder="e.g. Hairy"
                        name="first"
                        value={item.first}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        error={errors.first}
                      />
                      <TextFieldGroup
                        label="Last Name"
                        placeholder="e.g. Swordsman"
                        name="last"
                        value={item.last}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        error={errors.last}
                      />
                      <label className="display-6 text-secondary text-head">
                        Location
                      </label>
                      <select
                        className="form-control"
                        name="location"
                        onChange={this.onChange}
                        value={item.location}
                      >
                        <option value="">( select agent location )</option>
                        {listLocation &&
                          listLocation.map((loc) => (
                            <option key={`Loc_${loc.value}`} value={loc.value}>
                              {loc.label}
                            </option>
                          ))}
                      </select>
                    </div>
                    {role === 'SUPER' && (
                      <div className="col-12 col-lg-3">
                        <label className="display-6 text-secondary text-head">
                          Role
                        </label>
                        <select
                          className="form-control"
                          name="role"
                          onChange={this.onChange}
                          value={item.role}
                        >
                          <option value="">( select role agent )</option>
                          {listRole &&
                            listRole.map((loc) => (
                              <option
                                key={`Loc_${loc.value}`}
                                value={loc.value}
                              >
                                {loc.label}
                              </option>
                            ))}
                        </select>
                        <label className="display-6 text-secondary text-head mt-3">
                          Official
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-group my-4">
              <button className="btn btn-success" type="submit">
                <i className="fa fa-upload" />
                <span>Submit</span>
              </button>
              <Link to="/agents" className="btn btn-secondary">
                <i className="fa fa-times" />
                <span>Cancel</span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { getUserSettings, postAgent })(
  AgentNew,
);
