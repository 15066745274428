import actions from './actions';
import * as client from '../../utils/client';
let agentAPI =
	process.env.REACT_APP_AGENT_BASE_URL || 'https://agent.paveapi.com';
export const clearErrors = () => {
	return (dispatch) => dispatch(actions.clearErrors());
};

export const clearResponse = () => {
	return (dispatch) => dispatch(actions.clearResponse());
};

export const clearAll = () => {
	return (dispatch) => dispatch(actions.clearAll());
};
export const setNotiAdmin = (action) => {
	return (dispatch) => dispatch(actions.setNotiAdminDone(action));
};
export const getInspections = (params, loading = true) => {
	return (dispatch) =>
		client.exec(
			'get',
			`${agentAPI}/api/inspections/admin`,
			params,
			function (res) {
				dispatch(actions.getInspections(res));
			},
			loading
		);
};
