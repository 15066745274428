import * as types from './types';

const initialState = {
  list: {
    data: [],
    count: 0,
    pages: 0,
    current: 1,
    limit: 10,
    filters: {},
    sorts: {},
  },
  statistic: {},
  item: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_TASKFAILED:
      return {
        ...state,
        loading: false,
        item: {},
        ...action.payload,
      };
    case types.GET_TASKFAILED:
      return {
        ...state,
        loading: false,
        item: { ...action.payload },
      };
    case types.STATISTIC:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case types.CONFIRM:
      return {
        ...state,
        item: { ...state.item, ...action.payload },
        loading: false,
      };
    default:
      return state;
  }
}
