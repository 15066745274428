import * as types from './types';

const getAgentStats = (data) => ({
  type: types.GET_AGENT_STATS,
  payload: { ...data },
});
const getStatisticInspectors = (data) => ({
  type: types.GET_INSPECTORS,
  payload: { ...data },
});
const getStatisticCheckers = (data) => ({
  type: types.GET_CHECKERS,
  payload: { ...data },
});

export default {
  getAgentStats,
  getStatisticInspectors,
  getStatisticCheckers,
};
