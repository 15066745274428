import * as types from './types';
const initialState = {
	status: 'Start',
	adminTask: {},
	error: null,
	loading: false,
	timeCount: 60,
	timeOut: {},
	photoCode: 0,
	infoUser: {},
	sumaryTask: {},
	listDamages: {},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_MISSION:
			return {
				...state,
				adminTask: action.payload,
				error: null,
			};
		case types.GET_DAMAGES:
			return {
				...state,
				listDamages: action.payload,
			};
		case types.CLEAR_LIST_DAMAGES:
			return {
				...state,
				listDamages: {},
			};
		case types.SUMARY_TASK_USER:
			return {
				...state,
				sumaryTask: action.payload,
				error: null,
			};
		case types.GET_ADMIN_TASK:
			return {
				...state,
				adminTask: action.payload,
				error: null,
			};
		case types.INFO_USER:
			return {
				...state,
				infoUser: action.payload,
			};
		case types.TIME_OUT:
			return {
				...state,
				timeOut: action.payload,
			};
		case types.SET_TIME:
			return {
				...state,
				timeCount: action.payload,
			};
		case types.COUNT_TIME:
			return {
				...state,
				timeCount: state.timeCount - 1,
			};
		case types.HAS_ERROR:
			return {
				...state,
				error: action.payload,
			};
		case types.STATUS_START:
			return {
				...state,
				status: 'Start',
				task: {},
				error: null,
				loading: false,
				timeCount: 60,
				timeOut: {},
				listDamages: {},
			};
		case types.STATUS_WAIT:
			return {
				...state,
				status: 'Waiting',
				error: null,
				loading: false,
				timeCount: 60,
				timeOut: {},
				listDamages: {},
			};
		case types.STATUS_PROCESS:
			return {
				...state,
				status: 'Process',
			};
		case types.ACCEPT_IMAGE:
		case types.FINISH_IMAGE:
		case types.REJECT_IMAGE:
			const adminTask = state.adminTask || {};
			return {
				...state,
				adminTask: {
					...adminTask,
					...action.payload,
				},
				listDamages: {},
			};
		case types.CLEAR_MESSAGE:
			return {
				...state,
				adminTask: {
					...state.adminTask,
					message: '',
				},
			};
		case types.RESET_INSPECTION:
			return {
				...state,
				status: 'Start',
				error: null,
				loading: false,
				timeCount: 60,
				timeOut: {},
				infoUser: {},
				sumaryTask: {},
				adminTask: {},
				listDamages: {},
			};
		default:
			return state;
	}
}
