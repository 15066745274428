import React from 'react';

const Testing = () => {
  return (
    <span className="ribbon">
      <span>TEST MODE</span>
    </span>
  );
};

export default Testing;
