import { default as List } from './TaskFailedList';
import { default as View } from './TaskFailedView';
import { default as Performance } from './Performance';
import { default as Statistic } from './Statistic';
export default {
  List,
  View,
  Performance,
  Statistic,
};
