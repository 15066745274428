import actions from './actions';
import * as client from '../../utils/client';
let agentAPI =
  process.env.REACT_APP_AGENT_BASE_URL || 'https://agent.paveapi.com';
export const getUserSettings = () => {
  return (dispatch) =>
    client.exec('get', `${agentAPI}/api/users/settings`, {}, function (res) {
      dispatch(actions.getSettings(res));
    });
};
export const getAgents = (params, loading) => {
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/users/agents`,
      params,
      function (res) {
        dispatch(actions.getAgents(res));
      },
      loading,
    );
};
export const updateUsers = (data) => {
  return () =>
    client.exec(
      'put',
      `${agentAPI}/api/users/updateUsers`,
      data,
      function (res) {
        return res.status;
      },
      true,
    );
};

export const getAgent = (id) => {
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/users/agents/${id}`,
      {},
      function (res) {
        dispatch(actions.getAgent(res));
      },
    );
};

export const postAgent = (data, history) => {
  return (dispatch) =>
    client.exec('post', `${agentAPI}/api/users/agents`, data, function (res) {
      dispatch(actions.postAgent(res));
      if (history) {
        history.push('/agents');
      }
    });
};
export const clearSuccess = () => {
  return (dispatch) => dispatch(actions.clearSuccess());
};
export const putAgent = (id, data) => {
  return (dispatch) =>
    client.exec(
      'put',
      `${agentAPI}/api/users/agents/${id}`,
      data,
      function (res) {
        dispatch(actions.putAgent(res));
      },
    );
};
export const deleteAgent = (data) => {
  return (dispatch) =>
    client.exec(
      'delete',
      `${agentAPI}/api/users/deleteAgent`,
      data,
      function (res) {
        dispatch(actions.deleteAgent(res));
      },
      true,
    );
};

export const getAgentHistory = (params) => {
  return (dispatch) =>
    client.exec('get', `${agentAPI}/api/users/history`, params, function (res) {
      dispatch(actions.getAgentHistory(res));
    });
};

export const getAgentStats = (data) => {
  const { id, query } = data;
  if (!id) return (dispatch) => dispatch(actions.setAgentProcessError());
  return (dispatch) =>
    client.exec(
      'get',
      `${agentAPI}/api/users/agents/stats/` + id,
      query,
      function (res) {
        dispatch(actions.setAgentStats(res));
      },
    );
};
