import * as types from './types';

const getInspection = (data) => ({
  type: types.GET_INSPECTION,
  payload: { ...data },
});

const getInspectionsLeft = (data) => ({
  type: types.GET_INSPECTIONS_LEFT,
  payload: { ...data },
});
const getInspectionsRight = (data) => ({
  type: types.GET_INSPECTIONS_RIGHT,
  payload: { ...data },
});
const getInspectionsRd = (data) => ({
  type: types.GET_INSPECTIONS_RANDOM,
  payload: { ...data },
});
const clearInspection = () => ({
  type: types.CLEAR_INSPECTION,
});
const clearSuccess = () => ({
  type: types.CLEAR_SUCCESS,
});
const updateVehicleApi = (data) => ({
  type: types.UPDATE_VEHICLE,
  payload: { ...data },
});
const getActiveCages = (data) => ({
  type: types.GET_ACTIVE_CAGES,
  payload: { ...data },
});
const getAdminAction = (data) => ({
  type: types.GET_ADMIN_ACTION,
  payload: { ...data },
});
const putInspectionCage = (data) => ({
  type: types.PUT_INSPECTION_CAGE,
  payload: { ...data },
});

const TaskSlotStatus = (data) => ({
  type: types.CHANGE_STATUS_TASK_SLOT,
  payload: { ...data },
});

const completeInspection = (data) => ({
  type: types.COMPLETE_INSPECTION,
  payload: { ...data },
});
const inspectAll = (data) => ({
  type: types.INSPECT_ALL,
  payload: { ...data },
});
const changeStatusInspection = (data) => ({
  type: types.CHANGE_STT_INSPECTION,
  payload: { ...data },
});
const rejectTask = (data) => ({
  type: types.REJECT_TASK,
  payload: { ...data },
});
const reportImage = (data) => ({
  type: types.REPORT_IMAGE,
  payload: { ...data },
});
const acceptTask = (data) => ({
  type: types.ACCEPT_TASK,
  payload: { ...data },
});
const kickAgent = (data) => ({
  type: types.KICK_AGENT,
  payload: { ...data },
});
const getProcessPhoto = (data) => ({
  type: types.GET_PROCESS_PHOTO,
  payload: { ...data },
});
const inspectInspection = (data) => ({
  type: types.INSPECT_INPSPECTION,
  payload: { ...data },
});
const checkInspection = (data) => ({
  type: types.CHECK_INSPECTION,
  payload: { ...data },
});
const manage = (data) => ({
  type: types.MANAGE_INSPECTION,
  payload: { ...data },
});
const holding = (data) => ({
  type: types.HOLDING,
  payload: { ...data },
});
const putSkipVin = (data) => ({
  type: types.SKIP_VIN,
  payload: { ...data },
});
const validatePhoto = (data) => ({
  type: types.VALIDATE_PHOTO,
  payload: { ...data },
});
const invalid = (data) => ({
  type: types.INVALID,
  payload: { ...data },
});
const qcPassed = (data) => ({
  type: types.QCPASSED,
  payload: { ...data },
});
const adminSetStandard = (data) => ({
  type: types.ADMIN_SET_STANDARD,
  payload: { ...data },
});
const getSessionRd = (data) => ({
  type: types.GET_SESSION_RD,
  payload: { ...data },
});
export default {
  getInspection,
  getInspectionsLeft,
  getInspectionsRight,
  getInspectionsRd,
  clearInspection,
  clearSuccess,
  getActiveCages,
  putInspectionCage,
  TaskSlotStatus,
  completeInspection,
  changeStatusInspection,
  reportImage,
  rejectTask,
  acceptTask,
  kickAgent,
  getProcessPhoto,
  updateVehicleApi,
  inspectInspection,
  checkInspection,
  manage,
  holding,
  putSkipVin,
  invalid,
  validatePhoto,
  qcPassed,
  adminSetStandard,
  inspectAll,
  getAdminAction,
  getSessionRd,
};
