import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCage, getCageSettings, postCage } from '../operations';
import { CageCreateInfo } from './components';

const CageNew = () => {
  const [item, setItem] = useState({
    isDuplicate: false,
    make: null,
    model: null,
    year: null,
    bodyType: null,
    trim: null,
    code: null,
  });

  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const cage = useSelector((state) => state.cage);
  const { settings } = cage;

  useEffect(() => {
    if (id) {
      dispatch(getCage(id));
    } else {
      dispatch(getCageSettings());
    }
  }, [id]);

  useEffect(() => {
    if (cage.item) {
      const item = cage.item;
      setItem({
        isDuplicate: `${item.year} ${item.make} ${item.model}`,
        make: item.make,
        model: item.model,
        year: item.year,
        bodyType: item.bodyType,
        trim: item.trim,
        code: item.code,
      });
    }
  }, [cage.item]);

  const onChange = (e) => {
    e.preventDefault();

    const name = e.target.name;

    const value = e.target.value;

    setItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const postData = item;
    let errors = {};
    let requireFields = ['make', 'model', 'year', 'bodyType'];
    requireFields.forEach((k) => {
      if (!postData[k]) {
        errors[k] = 'This is a required field.';
      }
    });
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return false;
    }
    dispatch(postCage(postData, history));
  };

  let cageForm;
  if (Object.keys(settings).length > 0) {
    cageForm = (
      <CageCreateInfo
        data={item}
        show={true}
        onChange={onChange}
        errors={errors}
        settings={settings}
      />
    );
  }
  return (
    <div id="Cage_item">
      <div className="container-fluid">
        <h2 className="subtitle">
          <small>
            <Link to="/cages" className="text-logan">
              <i className="fa fa-arrow-left" />
              <span>Cage Manager</span>
            </Link>
          </small>
          <br />
          <span>
            Create new model cage for{' '}
            <span className="text-info">
              {item.year} {item.make} {item.model}
            </span>
          </span>
        </h2>
        {item.isDuplicate && (
          <div className="text-secondary mb-3">
            duplicate from {item.isDuplicate}
          </div>
        )}
        {cageForm}
        <div className="btn-group my-4">
          <button className="btn btn-success" onClick={onSubmit}>
            <i className="fa fa-upload" />
            <span>Submit</span>
          </button>
          <Link to="/cages" className="btn btn-secondary">
            <i className="fa fa-times" />
            <span>Cancel</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CageNew;
