import Alert from './Alert';
import Sidebar from './Sidebar';
import Toolbar from './Toolbar';
import Loading from './Loading';
import Notification from './Notification';
import Permission from './Permission';
import NotiInspect from './NotiInspect';
export default {
  Alert,
  Sidebar,
  Toolbar,
  Loading,
  Notification,
  Permission,
  NotiInspect,
};
