import React from 'react';

const InputText = (props) => {
	const { type, label, name, value, placeholder, error, onChange } = props;

	if (!['text', 'number', 'email'].includes(type)) {
		return null;
	}

	return (
		<div className="form-group">
			{label && (
				<label className="text-head text-uppercase text-logan display-7">
					{label}
				</label>
			)}
			<input
				type={type}
				name={name}
				value={value}
				className={`form-control ${error && 'is-invalid'}`}
				onChange={(event) => {
					onChange(event);
				}}
				placeholder={placeholder}
			></input>
			{error && <div className="invalid-feedback">{error}</div>}
		</div>
	);
};

InputText.defaultProps = {
	type: 'text',
};

export default InputText;
