import * as types from './types';

const getSettings = (data) => ({
  type: types.GET_SETTINGS,
  payload: { ...data },
});
const getAgents = (data) => ({
  type: types.GET_AGENTS,
  payload: { ...data },
});
const getAgent = (data) => ({
  type: types.GET_AGENT,
  payload: { ...data },
});
const postAgent = (data) => ({
  type: types.POST_AGENT,
  payload: { ...data },
});
const putAgent = (data) => ({
  type: types.PUT_AGENT,
  payload: { ...data },
});
const clearSuccess = () => ({
  type: types.CLEAR_SUCCESS,
});
const deleteAgent = (data) => ({
  type: types.DELETE_AGENT,
  payload: { ...data },
});
const getAgentHistory = (data) => ({
  type: types.GET_AGENT_HISTORY,
  payload: data,
});

const getAgentShifts = (data) => ({
  type: types.GET_AGENT_SHIFTS,
  payload: data,
});

const getAgentShift = (data) => ({
  type: types.GET_AGENT_SHIFT,
  payload: data,
});

const setAgentShift = (data) => ({
  type: types.SET_AGENT_SHIFT,
  payload: data,
});

const updateAgentShift = (data) => ({
  type: types.UPDATE_AGENT_SHIFT,
  payload: data,
});

const setAgentProcessError = () => ({
  type: types.SET_AGENT_PROCESS_ERROR,
});

const setAgentSchedule = (data) => ({
  type: types.SET_AGENT_SCHEDULE,
  payload: data,
});

const setAgentStats = (data) => ({
  type: types.SET_AGENT_STATS,
  payload: data,
});

export default {
  getSettings,
  getAgents,
  getAgent,
  postAgent,
  putAgent,
  clearSuccess,
  deleteAgent,
  getAgentHistory,
  getAgentShifts,
  getAgentShift,
  setAgentShift,
  updateAgentShift,
  setAgentProcessError,
  setAgentSchedule,
  setAgentStats,
};
