import { connect } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { InspectionListTable } from './components';
import SVG from 'react-inlinesvg';
import {
  getInspectionsRd,
  getActiveCages,
  putInspectionCage,
  adminSetStandard,
  getSessionRd,
  updateAction,
} from '../operations';
import { logout } from '../../auth/operations';
import ModalSetCage from './components/partials/ModalSetCage';
import Pagination from '../../../components/common/Pagination';
import ModalMD from './components/partials/ModalMD';
import Permission from '../../ui/containers/Permission';
const InspectionsRd = (props) => {
  const { auth, inspection } = props;
  const username =
    auth && auth.user && auth.user.username ? auth.user.username : '';
  const role = auth && auth.user && auth.user.role ? auth.user.role : '';
  const dataRd = inspection && inspection.dataRd ? inspection.dataRd : null;
  const { rd, cages } = inspection;
  const handleSettings = rd && rd.settings ? rd.settings : {};
  let [limitRd, setlimitRd] = useState(10);
  let [pageRd, setpageRd] = useState(1);
  const dataInspectionsRd = rd && rd.list ? rd.list : false;
  const pagesHandle =
    dataInspectionsRd && dataInspectionsRd.pages ? dataInspectionsRd.pages : 1;
  const countHandle =
    dataInspectionsRd && dataInspectionsRd.count ? dataInspectionsRd.count : 1;
  const [filtersRd, setfiltersRd] = useState({});
  const [autoRefresh, setautoRefresh] = useState(true);
  const [actionValidate, setactionValidate] = useState(false);
  const [showModalSetCage, setshowModalSetCage] = useState(false);
  const [dataItem, setdataItem] = useState({});
  const [modalConfirm, setmodalConfirm] = useState(false);
  const [modalStart, setmodalStart] = useState(false);
  const [dataUpdate, setdataUpdate] = useState({});
  const [autoRd, setautoRd] = useState(false);
  const [runOneTime, setrunOneTime] = useState(false);
  const beforeunloadListener = useRef(null);

  useEffect(() => {
    if (autoRd) {
      beforeunloadListener.current = () => {
        props.logout();
        return 'Yes';
      };
      window.addEventListener('beforeunload', beforeunloadListener.current);
    } else {
      if (beforeunloadListener.current) {
        window.removeEventListener(
          'beforeunload',
          beforeunloadListener.current,
        );
      }
    }

    return () => {
      if (autoRd) {
        const elementMenu = document.getElementById('listMenu');
        if (elementMenu) {
          elementMenu.classList.remove('disable');
        }
      }

      window.removeEventListener('beforeunload', beforeunloadListener.current);
    };
  }, [autoRd]);
  useEffect(() => {
    if (!runOneTime) {
      if (role === 'AGENT') {
        setfiltersRd({
          inspector: username,
        });
      }
      if (role === 'QA') {
        setfiltersRd({
          qa: username,
        });
      }
      setrunOneTime(true);
    }
  }, [runOneTime]);
  useEffect(() => {
    const timer = setInterval(() => {
      if (autoRefresh) {
        props.getInspectionsRd(
          {
            page: pageRd,
            limit: limitRd,
            filters: filtersRd,
          },
          false,
        );
      }
    }, 5000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [limitRd, pageRd, filtersRd, autoRefresh]);
  useEffect(() => {
    let timerRdInspection;
    if (autoRd) {
      timerRdInspection = setInterval(() => {
        props.getSessionRd(role, actionValidate);
      }, 8000);
    } else {
      clearInterval(timerRdInspection);
    }
    return () => {
      clearInterval(timerRdInspection);
    };
  }, [autoRd]);
  const updateActionUser = (status) => {
    const data = {};
    data['status'] = status;
    const elementMenu = document.getElementById('listMenu');
    if (status === 'Online') {
      setautoRd(false);
      if (elementMenu) {
        elementMenu.classList.remove('disable');
      }
    } else {
      if (elementMenu) {
        elementMenu.classList.add('disable');
      }
      setautoRd(true);
    }
    setmodalStart(false);
    props.updateAction(data);
  };
  const onChangeList = (e, side, type) => {
    const filter = filtersRd;
    let listFilter = [];
    if (type === 'status') {
      listFilter =
        filter.status && filter.status.length > 0 ? filter.status : [];
    }
    if (type === 'level') {
      listFilter =
        filter.levels && filter.levels.length > 0 ? filter.levels : [];
    }
    const checkIndexExists = listFilter.findIndex(
      (item) => item === e.target.value,
    );
    if (checkIndexExists !== -1) {
      listFilter.splice(checkIndexExists, 1);
    } else {
      listFilter.push(e.target.value);
    }
    if (type === 'status') {
      filter.status = listFilter;
    }
    if (type === 'level') {
      filter.levels = listFilter;
    }
    setfiltersRd(filter);
    props.getInspectionsRd({
      limit: limitRd,
      filters: filter,
    });
  };
  const onChange = (e) => {
    const filter = filtersRd;
    if (e.target.name === 'sameVin') {
      if (filter[e.target.name] === 'on') {
        filter[e.target.name] = 'off';
      } else {
        filter[e.target.name] = e.target.value;
      }
    } else {
      filter[e.target.name] = e.target.value;
    }
    setfiltersRd(filter);
    props.getInspectionsRd({
      limit: limitRd,
      filters: filter,
    });
  };
  const onPageChange = (e) => {
    e.preventDefault();
    if (e.target.getAttribute('data-value')) {
      limitRd = parseInt(e.target.getAttribute('data-value') || limitRd, 10);
    }
    if (
      e.target.getAttribute('data-page') &&
      e.target.getAttribute('data-page') >= 1
    ) {
      pageRd = parseInt(e.target.getAttribute('data-page'), 10);
    }
    props.getInspectionsRd({
      page: pageRd,
      limit: limitRd,
      filters: filtersRd,
    });
    setpageRd(pageRd);
    setlimitRd(limitRd);
  };
  const onToggleValidate = () => {
    const filter = filtersRd;
    if (actionValidate) {
      filter.qa = username;
      filter.validator = '';
    } else {
      filter.qa = '';
      filter.validator = username;
    }
    setfiltersRd(filter);
    props.getInspectionsRd({
      page: pageRd,
      limit: limitRd,
      filters: filter,
    });
    setpageRd(pageRd);
    setlimitRd(limitRd);
    setactionValidate(!actionValidate);
  };
  const setModalCage = (data) => {
    setshowModalSetCage(true);
    setautoRefresh(false);
    setdataItem(data);
  };
  const showModalConfirm = (sessionID, standard) => {
    const data = {};
    data['sessionID'] = sessionID;
    data['standard'] = !standard;
    setdataUpdate(data);
    setmodalConfirm(true);
  };
  const adminSetStandard = () => {
    setmodalConfirm(false);
    props.adminSetStandard(dataUpdate);
  };
  return ['AGENT', 'QA'].includes(role) ? (
    <div id="Inspection_list">
      <div className="container-fluid">
        <div
          className="header d-flex text-head mt-2 mb-3"
          style={{
            padding: '8px 0',
            alignItems: 'center',
            background: '#321640',
            color: '#fff',
            borderRadius: '5px',
          }}
        >
          <div style={{ width: '6%', padding: '0.3rem', paddingLeft: '10px' }}>
            Group / Theme
          </div>
          <div style={{ width: '8%', padding: '0.3rem', paddingLeft: '10px' }}>
            Manager
          </div>
          <div style={{ width: '8%', padding: '0.3rem' }} data-key="status">
            QA / Inspector
          </div>
          <div style={{ width: '9%', padding: '0.3rem' }} data-key="status">
            Status
          </div>
          <div
            style={{ width: '12%', padding: '0.3rem' }}
            data-key="inspectionID"
          >
            ID
          </div>
          <div style={{ width: '8%', padding: '0.3rem' }}> Photos </div>
          <div style={{ width: '15%', padding: '0.3rem' }}> Vehicle </div>
          <div style={{ width: '15%', padding: '0.3rem' }}> Detected Cage </div>
          <div style={{ width: '11%', padding: '0.3rem' }}> Date/Time </div>
          <div style={{ width: '8%', padding: '0.3rem' }}> Duration </div>
        </div>
        <div className="rd">
          {!autoRd ? (
            <div className="stop container-fluid">
              <div className="row">
                <div className="col-md-2">
                  {role === 'QA' ? (
                    <div
                      className="custom-control custom-switch text-head"
                      style={{ display: 'flex' }}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="inputVehicle"
                        name="inputVehicle"
                        checked={actionValidate}
                        onChange={onToggleValidate}
                      />
                      {!actionValidate ? (
                        <label
                          className="custom-control-label"
                          htmlFor="inputVehicle"
                        >
                          Check
                        </label>
                      ) : (
                        <label
                          className="custom-control-label"
                          htmlFor="inputVehicle"
                        >
                          Validate
                        </label>
                      )}
                    </div>
                  ) : null}

                  <div className="run d-flex justify-content-center mt-4">
                    <SVG
                      className={' svg'}
                      src={`img/svg/car.svg`}
                      uniquifyIDs={false}
                      style={{
                        width: '100%',
                      }}
                    ></SVG>
                  </div>
                  <div
                    className="badge badge-dark text-uppercase text-head d-block mt-4 display-4"
                    onClick={() => setmodalStart(true)}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    {role === 'AGENT'
                      ? 'INSPECT'
                      : role === 'QA' && !actionValidate
                      ? 'CHECK'
                      : role === 'QA' && actionValidate
                      ? 'VALIDATE'
                      : ''}
                  </div>
                </div>
                <div className="col-md-10">
                  <SVG
                    className={' svg'}
                    src={`img/svg/stopHandle.svg`}
                    uniquifyIDs={false}
                    style={{
                      width: '100%',
                    }}
                  ></SVG>
                </div>
              </div>
            </div>
          ) : (
            <div className="start container-fluid">
              <div className="row">
                <div className="col-md-2">
                  <div className="information">
                    <div className="mess">
                      <div
                        className="badge badge-dark"
                        style={{
                          backgroundColor: '#321640',
                        }}
                      >
                        <span className="display-5 text-head">
                          <i className="fas fa-list mr-1"></i>
                          <span className="mr-1"> Last Session :</span>

                          {dataRd && dataRd.data && dataRd.data.sessionID
                            ? dataRd.data.sessionID
                            : 'XXX-XXXXXX'}
                        </span>
                      </div>
                    </div>
                    <div className="count">
                      <div className="row">
                        <div
                          className="col-md-6"
                          style={{
                            paddingRight: '5px',
                          }}
                        >
                          <div
                            className="box-box badge badge-dark d-block mt-2 text-head "
                            style={{
                              backgroundColor: '#321640',
                              padding: '10px',
                            }}
                          >
                            <span className="display-5">Sessions</span>
                            <i className="fas fa-chart-bar text-white d-block mt-2 display-3"></i>
                            <span className=" mt-3 display-3">
                              {dataRd && dataRd.data && dataRd.data.countInsFree
                                ? dataRd.data.countInsFree
                                : '0'}
                            </span>
                          </div>
                        </div>
                        <div
                          className="col-md-6"
                          style={{
                            paddingLeft: '5px',
                          }}
                        >
                          <div
                            className="box-box badge badge-dark d-block mt-2 text-head "
                            style={{
                              backgroundColor: '#321640',
                              padding: '10px',
                            }}
                          >
                            <span className="display-5">Inspecting</span>
                            <i className="fas fa-chart-bar text-white d-block mt-2 display-3"></i>
                            <span className=" mt-3 display-3">
                              {dataRd &&
                              dataRd.data &&
                              dataRd.data.countInsDoing
                                ? dataRd.data.countInsDoing
                                : '0'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    className="badge badge-danger text-uppercase text-head display-4 mt-2 d-block"
                    onClick={() => updateActionUser('Online')}
                  >
                    Stop
                  </div>
                </div>
                <div className="col-md-10">
                  <SVG
                    className={' svg'}
                    src={`img/svg/startHandle.svg`}
                    uniquifyIDs={false}
                    style={{
                      width: '100%',
                    }}
                  ></SVG>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="list_data_right mt-3">
          <div
            style={{
              position: 'fixed',
              right: '20px',
              bottom: '5px',
              zIndex: '9999',
            }}
          >
            <Pagination
              size={limitRd}
              records={countHandle}
              page={pageRd}
              pages={pagesHandle}
              action={onPageChange}
            />
          </div>
          <InspectionListTable
            data={
              dataInspectionsRd && dataInspectionsRd.data.length > 0
                ? dataInspectionsRd.data
                : []
            }
            onChangeList={onChangeList}
            filters={filtersRd}
            onChange={onChange}
            settings={handleSettings}
            auth={auth}
            showModalConfirm={showModalConfirm}
            setModalCage={setModalCage}
            rd={true}
            actionValidate={actionValidate}
          />
        </div>
        {showModalSetCage ? (
          <ModalSetCage
            dataItem={dataItem}
            hideModal={() => setshowModalSetCage(false)}
            getActiveCages={props.getActiveCages}
            cages={cages}
            putInspectionCage={props.putInspectionCage}
            setautoRefresh={() => setautoRefresh(true)}
          />
        ) : null}
        <ModalMD
          show={modalConfirm}
          title="Set Standard"
          body={
            <div className="main-content">
              <div className="form-group">
                <div className="text-center">
                  <span className="text-head" style={{ fontSize: '20px' }}>
                    {`Are u sure , that u want to ${
                      dataUpdate.standard ? 'use' : 'remove'
                    } this ${dataUpdate.sessionID} ?`}
                  </span>
                </div>
              </div>
              <div className="btn-group float-right">
                <button className="btn btn-warning" onClick={adminSetStandard}>
                  <span className="text-bold" style={{ fontSize: '14px' }}>
                    Yes
                  </span>
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => setmodalConfirm(false)}
                >
                  <span className="text-bold" style={{ fontSize: '14px' }}>
                    No
                  </span>
                </button>
              </div>
            </div>
          }
          sizeModal="modal-md"
          handleClose={() => setmodalConfirm(false)}
        />
        <ModalMD
          show={modalStart}
          title={
            role === 'AGENT'
              ? 'INSPECT'
              : role === 'QA' && !actionValidate
              ? 'CHECK'
              : role === 'QA' && actionValidate
              ? 'VALIDATE'
              : ''
          }
          body={
            <div className="main-content">
              <div className="form-group">
                <div className="text-center">
                  <SVG
                    className={'svg'}
                    src={`img/svg/${
                      role === 'AGENT'
                        ? 'ani1'
                        : role === 'QA' && !actionValidate
                        ? 'ani2'
                        : role === 'QA' && actionValidate
                        ? 'ani3'
                        : ''
                    }.svg`}
                    uniquifyIDs={false}
                    style={{
                      width: '100%',
                    }}
                  ></SVG>
                  <span className="text-head" style={{ fontSize: '20px' }}>
                    {`Are you ready to ${
                      role === 'AGENT'
                        ? 'INSPECT'
                        : role === 'QA' && !actionValidate
                        ? 'CHECK'
                        : role === 'QA' && actionValidate
                        ? 'VALIDATE'
                        : ''
                    }`}
                  </span>
                </div>
              </div>
              <div className="btn-group float-right">
                <button
                  className="btn btn-warning"
                  onClick={() =>
                    updateActionUser(
                      role === 'AGENT'
                        ? 'Inspecting'
                        : role === 'QA' && !actionValidate
                        ? 'Checking'
                        : role === 'QA' && actionValidate
                        ? 'Validating'
                        : '',
                    )
                  }
                >
                  <span className="text-bold" style={{ fontSize: '14px' }}>
                    Yes
                  </span>
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => setmodalStart(false)}
                >
                  <span className="text-bold" style={{ fontSize: '14px' }}>
                    No
                  </span>
                </button>
              </div>
            </div>
          }
          sizeModal="modal-md"
          handleClose={() => setmodalStart(false)}
        />
      </div>
    </div>
  ) : (
    <Permission />
  );
};

const mapStateToProps = (state) => ({
  inspection: state.inspection,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getInspectionsRd,
  getActiveCages,
  putInspectionCage,
  adminSetStandard,
  getSessionRd,
  updateAction,
  logout,
})(InspectionsRd);
