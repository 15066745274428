import { default as List } from './AgentList';
import { default as New } from './AgentNew';
import { default as View } from './AgentView';
import { default as Stats } from './AgentStats';
import { default as Skills } from './AgentSkills';
export default {
  List,
  New,
  View,
  Stats,
  Skills,
};
