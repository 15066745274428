import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../operations';
import { clearErrors } from '../../ui/operations';
import TextFieldGroup from '../../../components/common/TextFieldGroup';
import _sample from 'lodash/sample';
import SVG from 'react-inlinesvg';
const Login = (props) => {
  const [login, setlogin] = useState();
  const [password, setpassword] = useState();
  const [errors, seterrors] = useState({});
  const { auth, ui } = props;
  useEffect(() => {
    if (ui) {
      seterrors({
        login: ui.errors.message,
        password: ui.errors.message,
      });
    }
  }, [ui]);
  const onSubmit = (e) => {
    e.preventDefault();
    props.clearErrors();
    const userData = {
      login: login,
      password: password,
      url: 'ADMIN',
    };
    props.login(userData, props.history);
  };

  const onChange = (e) => {
    if (e.target.name === 'login') {
      setlogin(e.target.value);
    }
    if (e.target.name === 'password') {
      setpassword(e.target.value);
    }
  };

  if (auth.isAuthenticated) {
    return <Redirect to="/inspections" />;
  }
  const quotes = [
    'Do one thing every day that scares you.',
    'Instead of worrying about what you cannot control, shift your energy to what you can create.',
    'Who is my biggest opponents? Me, yesterday.',
    'Why join the navy if you can be a pirate?',
    'Football is like life - it requires perseverance, self-denial, hard work, sacrifice, dedication and respect for authority.',
    'Success is no accident. It is hard work, perseverance, learning, studying, sacrifice and most of all, love of what you are doing or learning to do.',
  ];

  return (
    <div className="login" style={{ marginTop: '65px' }}>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-10 m-auto"
            style={{
              background: '#675d7a',
              padding: '45px 70px',
              borderRadius: '15px',
              overflow: 'hidden',
            }}
          >
            <div
              className="login-animation"
              style={{
                position: 'absolute',
                width: '85%',
                height: '100%',
                top: '0px',
                right: '-40%',
                background: '#fff',
                transform: 'rotate(152deg) translateX(0px)',
                borderRadius: '15px',
              }}
            ></div>
            <div className="row">
              <div className="col-5 " style={{ padding: '25px 45px' }}>
                <div className="brand text-center mt-5 mb-4">
                  <img
                    src="img/logo_white.png"
                    width="auto"
                    height="62"
                    alt="PAVE.admin"
                  />
                </div>
                <div className="card bg-transparent" style={{ border: '0' }}>
                  <form onSubmit={onSubmit} className="" autoComplete="off">
                    <TextFieldGroup
                      label="Username/ Email Address"
                      placeholder="Enter your account username or email address"
                      name="login"
                      value={login}
                      onChange={(e) => onChange(e)}
                    />
                    <TextFieldGroup
                      label="Password"
                      placeholder="Enter your account password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={(e) => onChange(e)}
                      error={errors.password}
                    />

                    <button
                      style={{
                        fontSize: '18px',
                      }}
                      type="submit"
                      className="btn btn-dark text-head btn-block mt-4"
                    >
                      <i className="fas fa-sign-in-alt"></i>
                      <span>Sign Me In</span>
                    </button>
                  </form>
                </div>
                <div className="alert alert-logan text-head text-dark mt-3">
                  {_sample(quotes)}
                </div>
              </div>
              <div className="col-7">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: '100%' }}
                >
                  <SVG
                    className={''}
                    src={'img/login.svg'}
                    uniquifyIDs={false}
                    style={{
                      width: '90%',
                    }}
                  ></SVG>
                  {/* <img
                    style={{ width: '85%' }}
                    src="img/login.svg"
                    width="auto"
                    alt="PAVE.admin"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  ui: state.ui,
});

export default connect(mapStateToProps, { login, clearErrors })(Login);
