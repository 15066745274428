import React from "react";
import PropTypes from "prop-types";

const NoteItem = ({ index, data, settings }) => {
    const { listTypeToRequestReview } = settings;
    let cardTitleClass;
    let cardTitle = data.type;
    if (
        listTypeToRequestReview &&
        listTypeToRequestReview.includes(data.type)
    ) {
        cardTitleClass = "text-danger";
    } else {
        cardTitleClass = "text-info";
    }

    const createdAt = new Date(data.createdAt);

    return (
        <div className={`card text-center`}>
            <div className="card-body">
                <h5 className={`card-title ${cardTitleClass}`}>
                    {index}. {cardTitle}
                </h5>
                <p className="card-text">{data.message}</p>
                <p className="card-text">
                    <small className="text-logan">
                        {createdAt.toLocaleString()} by{" "}
                        <b>{data.owner ? data.owner.username : "system"}</b>
                    </small>
                </p>
            </div>
        </div>
    );
};

NoteItem.propTypes = {
    index: PropTypes.number,
    data: PropTypes.object.isRequired
};

export default NoteItem;
