import actions from './actions';
import uiActions from '../ui/actions';
import * as client from '../../utils/client';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import inspectActions from '../inspect/actions';
import inspectionActions from '../inspections/actions';
let agentAPI =
  process.env.REACT_APP_AGENT_BASE_URL || 'https://agent.paveapi.com';

export const setAuthToken = (token) => {
  if (token) {
    // Apply to every request
    axios.defaults.headers.common['Authorization'] = token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const login = (data, history) => {
  return (dispatch) =>
    client.exec(
      'post',
      `${agentAPI}/api/users/login`,
      data,
      function (res) {
        const { token } = res;
        // Set token to ls
        localStorage.setItem('jwtToken', token);
        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data
        const decoded = jwt_decode(token);
        // Set current user
        dispatch(actions.setCurrentUser(decoded));
        dispatch(uiActions.clearErrors());
        history.push('/inspections');
      },
      true,
    );
};
export const logout = () => (dispatch) => {
  // Store logout history
  const token = localStorage.getItem('jwtToken');
  if (token) {
    axios.post(`${agentAPI}/api/users/logout`, jwt_decode(token));
  }
  // Remove token from localStorage
  localStorage.removeItem('jwtToken');
  // Remove auth header for future requests
  setAuthToken(false);

  // Set current user to {} which will set isAuthenticated to false
  dispatch(inspectActions.resetInspection());
  dispatch(actions.clearCurrentUser({}));
  dispatch(uiActions.clearLoading());
  dispatch(inspectionActions.clearInspection());
};

export const checkToken = () => (dispatch) => {
  if (localStorage.jwtToken) {
    // Set auth token header auth
    setAuthToken(localStorage.jwtToken);
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);
    // Set user and isAuthenticated
    dispatch(actions.setCurrentUser(decoded));
    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user
      logout();
      // Redirect to login
      window.location.href = '/';
    }
  }
};

export const getLoggedUser = () => (dispatch) => {
  axios
    .get(`${agentAPI}/api/users/current`)
    .then((res) => {
      dispatch(actions.getLoggedUser(res.data));
    })
    .catch((err) => dispatch(uiActions.setErrors(err)));
};

export const changeProfile = (data) => (dispatch) => {
  axios
    .put(`${agentAPI}/api/users/changeProfile`, data)
    .then((res) => {
      dispatch(actions.clearErrors());
      dispatch(actions.clearResponse());
      dispatch(actions.changeProfile(res.data));
    })
    .catch((err) => dispatch(uiActions.setErrors(err)));
};

export const clearResponse = () => (dispatch) => {
  dispatch(actions.clearResponse());
};

export const setAccessUrl = (data) => (dispatch) => {
  dispatch(actions.setAccessUrl(data));
};
