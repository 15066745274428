import React from 'react';

const FinishButton = (props) => {
	return (
		<div className="show-modal-button mb-2" style={{ width: '100%' }}>
			<button
				key={`Finish`}
				type="button"
				className="btn btn-success"
				style={{ width: '100%' }}
				onClick={() =>
					props.showModalBtn(
						'Finish',
						'Finish',
						'Are You Sure Want to Finish This Image'
					)
				}
			>
				<i className="fa fa-check mr-2 check-icon" aria-hidden="true" />
				Finish
			</button>
		</div>
	);
};

export default FinishButton;
