import React from 'react';
import { DateRangePicker } from '../../../ui/components/Form';
import TaskFailedTableItem from './TaskFailedTableItem';
const TaskFailedTable = ({
  data,
  filters,
  onChange,
  onChangeApi,
  deleteTaskFailed,
  timezone,
  listStatusTaskFailed,
  skills,
  user,
  errors,
  onSubmit,
  handleKeyPress,
  optionSelectType,
  changeOptionSelectType,
}) => {
  const tblHeaders = (
    <div style={{ display: 'flex', fontWeight: 'bold', opacity: '0.7' }}>
      <div
        style={{
          width: '9%',
        }}
      >
        <div
          className="searchUserName mr-2"
          style={{
            position: 'relative',
            width: '95%',
            display: 'inline-block',
          }}
        >
          <input
            style={{
              display: 'block',
              borderRadius: '7px',
              height: '45px',
            }}
            type="text"
            className="form-control"
            name="qa"
            onKeyPress={handleKeyPress}
            defaultValue={filters.qa}
            onChange={onChange}
            placeholder="QA"
          />
        </div>
      </div>
      <div
        style={{
          width: '9%',
        }}
      >
        <div
          className="searchUserName mr-2"
          style={{
            position: 'relative',
            width: '95%',
            display: 'inline-block',
          }}
        >
          <input
            style={{
              display: 'block',
              borderRadius: '7px',
              height: '45px',
            }}
            type="text"
            className="form-control"
            name="inspector"
            onKeyPress={handleKeyPress}
            defaultValue={filters.inspector}
            onChange={onChange}
            placeholder="Inspector"
          />
        </div>
      </div>
      <div
        style={{
          width: '12%',
        }}
      >
        <div className="searchTask mr-2" style={{ display: 'inline-block' }}>
          <select
            className="form-control"
            name="taskName"
            onChange={onChangeApi}
            value={filters.taskName ? filters.taskName : ''}
            style={{
              height: '45px',
              borderRadius: '7px',
            }}
          >
            <option key={`Loc_222`} value="">
              Task Name (All)
            </option>
            {skills &&
              skills.map((loc) => (
                <option key={`task_${loc}`} value={loc}>
                  {loc}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div
        style={{
          width: '15%',
        }}
      >
        <div className="search" style={{ display: 'inline-block' }}>
          <select
            className="form-control"
            name="status"
            onChange={onChangeApi}
            value={filters.status ? filters.status : ''}
            style={{ height: '45px', borderRadius: '7px' }}
          >
            <option key={`Loc_111`} value="">
              Status (All)
            </option>
            {listStatusTaskFailed &&
              listStatusTaskFailed.map((loc) => (
                <option key={`Loc_${loc}`} value={loc}>
                  {loc}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div
        style={{
          width: '24%',
        }}
      >
        <div
          className="search mr-2"
          style={{
            position: 'relative',
            width: '97%',
            display: 'flex',
          }}
        >
          <div className="select-type mr-1" style={{ width: '25%' }}>
            <select
              className="custom-select "
              style={{ borderRadius: '7px', height: '45px' }}
              name="type"
              onChange={changeOptionSelectType}
            >
              <option key="damage-option" value="damage">
                Damage
              </option>
              <option key="component-option" value="component">
                Component
              </option>
              <option key="action-option" value="action">
                Action
              </option>
            </select>
          </div>
          <div style={{ width: '75%' }}>
            {optionSelectType === 'action' ? (
              <select
                className="custom-select "
                style={{ borderRadius: '7px', height: '45px' }}
                name="action"
                onChange={onChangeApi}
              >
                <option key="action-all" value="">
                  All
                </option>
                <option key="action-inspector" value="inspector">
                  Inspector
                </option>
                <option key="action-qa" value="qa">
                  Qa
                </option>
                <option key="action-support" value="support">
                  Support
                </option>
                <option key="action-review" value="review">
                  Review
                </option>
              </select>
            ) : optionSelectType === 'component' ? (
              <input
                style={{
                  borderRadius: '7px',
                  height: '45px',
                }}
                type="text"
                className="form-control"
                id="input"
                name="component"
                onChange={onChange}
                placeholder="Component"
                onKeyPress={handleKeyPress}
              />
            ) : (
              <input
                style={{
                  borderRadius: '7px',
                  height: '45px',
                }}
                id="input"
                type="text"
                className="form-control"
                name="damageName"
                onChange={onChange}
                placeholder="Damage Name"
                onKeyPress={handleKeyPress}
              />
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          width: '12%',
        }}
      >
        <div
          className="search mr-2"
          style={{
            position: 'relative',
            width: '95%',
            display: 'inline-block',
          }}
        >
          <input
            style={{
              display: 'block',
              borderRadius: '7px',
              height: '45px',
            }}
            type="text"
            className="form-control"
            name="sessionID"
            onKeyPress={handleKeyPress}
            defaultValue={filters.sessionID}
            onChange={onChange}
            placeholder="Session"
          />
        </div>
      </div>
      <div
        style={{
          width: '11%',
        }}
      >
        Time Task
      </div>
      <div
        style={{
          width: '8%',
        }}
      >
        Action
      </div>
    </div>
  );
  let tblBody;
  if (data.length > 0) {
    tblBody = data.map((item) => {
      return (
        <TaskFailedTableItem
          deleteTaskFailed={deleteTaskFailed}
          key={`Priority_item_${item._id}`}
          data={item}
          timezone={timezone}
          user={user}
        />
      );
    });
  } else {
    tblBody = (
      <div>
        <div colSpan="5" className="text-secondary">
          <b>You Don't Have any task failed</b>
        </div>
      </div>
    );
  }
  return (
    <div className="Agents mt-3">
      <div className="groupSearch mb-2 d-flex ">
        <div
          className="searchRange"
          style={{
            width: '25%',
            displa: 'flex',
            alignItems: 'center',
          }}
        >
          <DateRangePicker
            name="range"
            onChange={onChange}
            error={errors.range}
            maxDate={new Date()}
          ></DateRangePicker>
        </div>
        <button
          className="btn btn-primary mt-4"
          onClick={onSubmit}
          style={{ width: '80px', height: '45px' }}
        >
          Search
        </button>
      </div>
      <div className="header mb-2">{tblHeaders}</div>
      <div className="body">{tblBody}</div>
    </div>
  );
};

export default TaskFailedTable;
