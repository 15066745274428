import React from 'react';

const CountComponent = (props) => {
  const { listCounts } = props;
  let style = {
    width: '25%',
  };
  if (listCounts.length > 6) {
    return <h2>Too much component counting</h2>;
  }
  switch (listCounts.length) {
    case 2:
      style = {
        width: '50%',
        display: 'flex',
      };
      break;
    case 3:
      style = {
        width: '75%',
        display: 'flex',
      };
      break;
    case 4:
      style = {
        width: '100%',
        display: 'flex',
      };
      break;
    case 5:
      style = {
        width: '100%',
        display: 'flex',
      };
      break;
    case 6:
      style = {
        width: '100%',
        display: 'flex',
      };
      break;
    default:
      break;
  }
  return (
    <div className="div mt-2" style={{ ...style }}>
      {listCounts && listCounts.length > 0
        ? listCounts.map((item, index) => {
            const backGround = item.backGround ? item.backGround : '#fff';
            const text = item.text ? item.text : 'Default';
            const count = item.count ? item.count : 0;
            const icon = item.icon ? item.icon : 'fas fa-grin-wink';
            const width = 100 / listCounts.length;
            return (
              <div
                className="box-count"
                key={`count_${index}`}
                style={{
                  width: `${width}%`,
                  display: 'flex',
                  padding: '15px 25px',
                  marginRight: '10px',
                  borderRadius: '15px',
                  background: `${backGround}`,
                  alignItems: 'center',
                }}
              >
                <div
                  className="group-number d-flex"
                  style={{
                    color: '#fff',
                    width: '70%',
                    fontWeight: 'bold',
                    alignItems: 'center',
                  }}
                >
                  <div
                    className="action mr-2"
                    style={{ textTransform: 'uppercase', fontSize: '21px' }}
                  >
                    {text}: <span className="ml-1">{count}</span>
                  </div>
                </div>
                <div
                  className="icon"
                  style={{
                    position: 'relative',
                    width: '30%',
                    height: '80px',
                    padding: '5px 10px',
                  }}
                >
                  <div
                    style={{
                      background: '#fff',
                      width: '100%',
                      height: '100%',
                      borderRadius: '15px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '32px',
                    }}
                  >
                    <i className={`${icon}`}></i>
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default CountComponent;
