import React from 'react';
import PropTypes from 'prop-types';

const HistoryItem = ({ data }) => {
  let cardMessage;
  const options = data.options || {};
  switch (data.event) {
    case 'DUPLICATE':
      cardMessage = (
        <span className="small text-muted">
          Duplicate from{' '}
          <b className="text-primary">{options.cageName || 'unknown'}</b>.
        </span>
      );
      break;
    case 'PHOTO-UPLOAD':
      cardMessage = (
        <span className="small text-muted">
          Upload vehicle photo for{' '}
          <b className="text-info">{options.photoName || 'unknown'}</b>.
        </span>
      );
      break;
    case 'CAGE-UPLOAD':
      cardMessage = (
        <span className="small text-muted">
          Upload cage (svg) for{' '}
          <b className="text-success">{options.photoName || 'unknown'}</b>.
        </span>
      );
      break;
    case 'UPDATE': {
      const affected =
        options && options.fields && Array.isArray(options.fields)
          ? options.fields
          : [];
      let affectedFields = [];
      for (let i = 0; i < affected.length; i++) {
        const field = affected[i];
        if (!field.key || ['updatedField', 'note'].includes(field.key)) {
          continue;
        }
        let fieldDetail;
        if (field.old && field.new) {
          fieldDetail = (
            <small className="text-info">
              {field.old || 'empty'} <i className="fa fa-arrow-right" />{' '}
              {field.new}
            </small>
          );
        }
        affectedFields.push(
          <span key={field.key} className="mr-2">
            Affected fields: <b className="text-primary">{field.key}</b>{' '}
            {fieldDetail}
          </span>,
        );
      }
      cardMessage = (
        <span className="small text-muted">
          Cage information has been changed.
          {affectedFields}
        </span>
      );
      break;
    }
    case 'UPDATE-STATUS':
      cardMessage = (
        <span className="small text-muted">
          Status updated:
          <span className="ml-2 text-info">
            ({options.old || options.current || 'unknown'}{' '}
            <i className="fa fa-arrow-right" /> {options.new || options.status})
          </span>
        </span>
      );
      break;
    case 'CAGE-LABEL-REMOVE':
      cardMessage = (
        <span className="small text-muted">
          Remove <span className="text-info">{options.user || '???'}</span> from
          label of <span className="text-info">{options.detail}</span>
        </span>
      );
      break;
    default:
      cardMessage = data.event;
      break;
  }

  const createdAt = new Date(data.createdAt);

  return (
    <li className="list-group-item">
      {cardMessage}
      <span className="badge badge-muted pull-right">
        {createdAt.toLocaleString()}
      </span>
      <span className="badge badge-blackcurrant pull-right mr-2">
        {data.owner ? data.owner.username : 'system'}
      </span>
    </li>
  );
};

HistoryItem.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object.isRequired,
};

export default HistoryItem;
