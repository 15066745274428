import * as types from './types';

const setCurrentUser = decoded => {
	return {
		type: types.SET_CURRENT_USER,
		payload: decoded
	};
};

const clearCurrentUser = () => {
	return {
		type: types.CLEAR_CURRENT_USER
	};
};

const clearResponse = () => {
	return {
		type: types.CLEAR_RESPONSE
	};
};

const getLoggedUser = data => {
	return {
		type: types.GET_LOGGED_USER,
		payload: data
	};
};

const changeProfile = data => {
	return {
		type: types.CHANGE_PROFILE,
		payload: data
	};
};

const setAccessUrl = data => {
	return {
		type: types.SET_ACCESS_URL,
		payload: data
	};
};

export default {
	setCurrentUser,
	getLoggedUser,
	changeProfile,
	clearCurrentUser,
	clearResponse,
	setAccessUrl
};
