import React from 'react';

const AgentHistoryTable = ({ items, timezone }) => {
  return (
    <div id="History_table" className="table-responsive">
      <table className="table table-sm bg-white">
        <thead>
          <tr>
            <th>Date/Time</th>
            <th>Event</th>
            <th>Device</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            const ua = item.ua;
            let device = [];
            if (ua) {
              device.push(<div key="Browser" className="small">{`${ua}`}</div>);
            }
            if (ua && ua.os) {
              let cpu = '';
              if (ua.cpu) {
                cpu = `(${ua.cpu.architecture})`;
              }
              device.push(
                <div key="OS" className="small">
                  {`${ua.os.name} ${ua.os.version}`} {cpu}
                </div>,
              );
            }
            return (
              <tr key={index}>
                <td>
                  {new Date(item.createdAt).toLocaleString('en-US', {
                    timeZone: timezone,
                  })}
                </td>
                <td>{item.action}</td>
                <td>{device}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AgentHistoryTable;
