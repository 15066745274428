import React from 'react';
import UserItemSkills from './UserItemSkills';

const UserListTableSkills = ({
  data,
  filters,
  onChange,
  timezone,
  listRole,
  listShifts,
  groups,
  skills,
  shifts,
  user,
  onChangeSkills,
  onChangeGroups,
  onChangeShift,
  onCheckSelect,
  unCheckSelect,
  selectAll,
  updateSkills,
  listUsers,
  onSort,
  sort,
}) => {
  const tblHeaders = (
    <div style={{ display: 'flex', fontWeight: 'bold', opacity: '0.7' }}>
      <div
        style={{
          width: '5%',
        }}
      >
        Select
      </div>
      <div
        style={{
          width: '12%',
        }}
        onClick={() => onSort(!sort)}
        className={`clickable`}
      >
        Username
        <div className="mr-2" style={{ float: 'right' }}>
          {sort ? (
            <i className="fas fa-arrow-up"></i>
          ) : (
            <i className="fas fa-arrow-down"></i>
          )}
        </div>
      </div>
      <div
        style={{
          width: '6%',
        }}
      >
        Shift
      </div>
      <div
        style={{
          width: '15%',
        }}
      >
        Groups
      </div>
      <div
        style={{
          width: '62%',
        }}
      >
        Skills
      </div>
    </div>
  );
  let tblBody;
  if (data.length > 0) {
    tblBody = data.map((item, i) => {
      return (
        <UserItemSkills
          listUsers={listUsers}
          key={`User_item_${item._id}`}
          data={item}
          timezone={timezone}
          role={user.role}
          index={i}
          onCheckSelect={onCheckSelect}
        />
      );
    });
  } else {
    tblBody = (
      <div>
        <div colSpan="5" className="text-secondary">
          <b>No account found.</b>
        </div>
      </div>
    );
  }

  return (
    <div className="Agents mt-3">
      <div className="groupSearch mb-3">
        <div
          className="searchUserName mr-3"
          style={{
            position: 'relative',
            width: '22%',
            display: 'inline-block',
          }}
        >
          <input
            style={{
              display: 'block',
              borderRadius: '7px',
              height: '45px',
            }}
            type="text"
            className="form-control"
            name="username"
            defaultValue={filters.username}
            onChange={onChange}
            placeholder="User Name"
          />
          <i
            className="fas fa-search"
            style={{
              position: 'absolute',
              top: '13px',
              right: '15px',
              fontSize: '17px',
            }}
          ></i>
        </div>
        {['SUPER', 'ADMIN', 'TRAINER'].includes(user.role) ? (
          <div className="searchRole mr-3" style={{ display: 'inline-block' }}>
            <select
              className="form-control"
              name="role"
              onChange={onChange}
              value={filters.role ? filters.role : ''}
              style={{ height: '45px', borderRadius: '7px' }}
            >
              <option key={`Loc_111`} value="">
                ALL
              </option>
              {listRole &&
                listRole.map((loc) => (
                  <option key={`Loc_${loc.value}`} value={loc.value}>
                    {loc.label}
                  </option>
                ))}
            </select>
          </div>
        ) : null}
        {['SUPER', 'ADMIN', 'TRAINER'].includes(user.role) ? (
          <div
            className="searchShifts mr-3"
            style={{ display: 'inline-block' }}
          >
            <select
              className="form-control"
              name="shift"
              onChange={onChange}
              value={filters.shift ? filters.shift : ''}
              style={{ height: '45px', borderRadius: '7px' }}
            >
              <option key={`Loc_2222222`} value="">
                ALL
              </option>
              {listShifts &&
                listShifts.map((loc) => (
                  <option key={`shift_${loc.value}`} value={loc.value}>
                    {loc.label}
                  </option>
                ))}
            </select>
          </div>
        ) : null}
        <div className="btn btn-success mr-2" onClick={selectAll}>
          <i className="fa fa-lg fa-list" />
          <span>Select All</span>
        </div>
      </div>
      <div className="group-set-skills mb-3" style={{ display: 'flex' }}>
        <div className="skills mr-3" style={{ width: '70%' }}>
          <div className="form-group mb-0">
            <label className="display-7 text-logan text-head text-uppercase">
              Inspection Skills
            </label>
          </div>
          <div className="row container-fluid">
            {skills.map((s, i) => {
              return (
                <div
                  key={`Skill_${i}`}
                  className="custom-control custom-checkbox col-3"
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`skill_set_${i}`}
                    value={i}
                    checked={s.checked}
                    onChange={onChangeSkills}
                  />
                  <label
                    className="custom-control-label text-xsm text-uppercase"
                    htmlFor={`skill_set_${i}`}
                  >
                    {s.name.toUpperCase()}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="groups" style={{ width: '30%' }}>
          <div className="form-group mb-0">
            <label className="display-7 text-logan text-head text-uppercase">
              Groups
            </label>
          </div>
          <div className="row container-fluid">
            {groups.map((s, i) => {
              return (
                <div
                  key={`Group_${i}`}
                  className="custom-control custom-checkbox col-3"
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`group_set_${i}`}
                    value={i}
                    checked={s.checked}
                    onChange={onChangeGroups}
                  />
                  <label
                    className="custom-control-label text-xsm text-uppercase"
                    htmlFor={`group_set_${i}`}
                  >
                    {s.name.toUpperCase()}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="group-set-shift mb-3" style={{ display: 'flex' }}>
        <div className="skills mr-3" style={{ width: '70%' }}>
          <div className="form-group mb-0">
            <label className="display-7 text-logan text-head text-uppercase">
              Shifts
            </label>
          </div>
          <div className="row container-fluid">
            {shifts.map((s, i) => {
              return (
                <div
                  key={`shift${i}`}
                  className="custom-control custom-radio col-3"
                >
                  <input
                    type="radio"
                    className="custom-control-input"
                    id={`shift_set_${i}`}
                    value={i}
                    checked={s.checked}
                    onChange={onChangeShift}
                  />
                  <label
                    className="custom-control-label text-xsm text-uppercase"
                    htmlFor={`shift_set_${i}`}
                  >
                    {s.name.toUpperCase()}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="listGroups mb-3">
        <div className="form-group mb-0">
          <label className="display-7 text-logan text-head text-uppercase">
            List Users Add
          </label>
        </div>
        <div className="row container-fluid">
          {listUsers.map((s, i) => {
            return (
              <div
                key={`user_${i}`}
                className="badge badge-bullet  badge-success mr-1 mb-1"
              >
                <span className="mr-1"> {s}</span>
                <i
                  style={{ cursor: 'pointer' }}
                  onClick={() => unCheckSelect(i)}
                  className="fas fa-window-close"
                ></i>
              </div>
            );
          })}
        </div>
      </div>
      <div className="btn btn-success mb-2" onClick={updateSkills}>
        <i className="fa fa-lg fa-check" />
        <span>Update Users</span>
      </div>
      <div className="header mb-2">{tblHeaders}</div>
      <div className="body">{tblBody}</div>
    </div>
  );
};

export default UserListTableSkills;
