import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, auth }) => {
	return (
		<Route
			render={(props) => {
				const { isAuthenticated } = auth;
				if (!isAuthenticated) {
					return <Redirect to="/" />;
				}
				return <Component {...props} />;
			}}
		/>
	);
};

PrivateRoute.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
