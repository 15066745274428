import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  getAgentStats,
  getCsv,
  getStatisticInspectors,
  getStatisticCheckers,
} from '../operations';
import AgentStatsTable from './components/AgentStatsTable';
import { durationText } from '../../../utils/string';
import Permission from '../../ui/containers/Permission';
import StatsTable from './components/StatsTable';

const today = new Date();

const Dashboard = (props) => {
  const {
    auth,
    dashboard,
    getAgentStats,
    getCsv,
    getStatisticInspectors,
    getStatisticCheckers,
  } = props;
  const [showInfo, setshowInfo] = useState('dashboard');

  const { user } = auth;
  const { inspectors, checkers, agentStats } = dashboard;

  const onChangeDate = (date) => {
    let query = {};
    const { from, to } = date || {};
    if (from) {
      query['startDate'] = from;
    }
    if (to) {
      query['endDate'] = to;
    }
    getAgentStats(query);
  };
  const onChangeDateInspector = (date) => {
    let query = {};
    const { from, to } = date || {};
    if (from) {
      query['startDate'] = from;
    }
    if (to) {
      query['endDate'] = to;
    }
    getStatisticInspectors(query);
  };
  const onChangeDateChecker = (date) => {
    let query = {};
    const { from, to } = date || {};
    if (from) {
      query['startDate'] = from;
    }
    if (to) {
      query['endDate'] = to;
    }
    getStatisticCheckers(query);
  };
  const onDownloadStats = (data) => {
    const skills = [
      'vin',
      'qc_plate',
      'qc_odom',
      'qc_interior',
      'qc_tire',
      'qc_glass',
      'interior',
      'glass',
      'odom',
      'tire',
      'plate_number',
      'ext_id',
      'trim_id',
      'damage_body',
      'damage_other',
      'damage_full',
    ];
    let content = [
      'Username',
      'Check',
      'Inspect',
      'Tasks',
      'Accepted',
      'Rejected',
      'Inspected',
      'Time On Inspect',
      'Time On Check',
      'Time On Waiting',
      'Time On Inspect',
      'Time On Duty',
      'Plus Time',
      'Timeout',
      'Timeout Inspect',
      [...skills],
    ].join();
    for (let i = 0; i < data.length; i++) {
      const item = data[i];

      const row = [
        item.username,
        item.check || 0,
        item.inspect || 0,
        item.total,
        item.type && item.type['qc-accepted'] ? item.type['qc-accepted'] : 0,
        item.type && item.type['qc-rejected'] ? item.type['qc-rejected'] : 0,
        (item.type && item.type['inspect']) || 0,
        durationText(item.timeInspect),
        durationText(item.timeCheck),
        durationText(item.onDuty),
        durationText(item.onInspect),
        durationText(item.onDuty + item.onInspect),
        item.plusTime,
        item.hasTimeout,
        item.hasInspectTimeout,
      ];
      for (let j = 0; j < skills.length; j++) {
        const element = skills[j];
        const data =
          item.skills && item.skills[element] ? item.skills[element] : 0;
        row.push(data);
      }
      content += '\r\n' + row.join();
    }

    getCsv(content, 'User');
  };
  const onDownload = (data, type) => {
    let content = [
      'Username',
      'Sessions',
      'Time',
      'Auto',
      'TaskSlots',
      'Groups',
      'Components',
      'Damages',
      'Damages Inspect',
    ];
    let fileName = 'Inspector';
    if (type === 'CHECKER') {
      fileName = 'Checker';
      content = [
        'Username',
        'Sessions',
        'Time',
        'Auto',
        'TaskSlots',
        'Groups',
        'Components',
        'Damages',
        'Damages Inspect',
        'Damages Check',
        'Damages Reject',
      ];
    }
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const detected_damages = item?.detected_damages ?? {};
      let row = [
        item.username,
        item.totalSession,
        durationText(item.time),
        item.auto,
        item.totalTaskSlot ?? 0,
        detected_damages?.totalGroups ?? 0,
        detected_damages?.totalComponents ?? 0,
        detected_damages?.totalDamages ?? 0,
        detected_damages?.totalDamagesInspect ?? 0,
      ];
      if (type === 'CHECKER') {
        row = [
          item.username,
          item.totalSession,
          durationText(item.time),
          item.auto,
          item.totalTaskSlot ?? 0,
          detected_damages?.totalGroups ?? 0,
          detected_damages?.totalComponents ?? 0,
          detected_damages?.totalDamages ?? 0,
          detected_damages?.totalDamagesInspect ?? 0,
          detected_damages?.totalDamagesCheck ?? 0,
          detected_damages?.totalDamagesReject ?? 0,
        ];
      }
      content += '\r\n' + row.join();
    }
    //return;
    getCsv(content, fileName);
  };
  return (
    <div id="Dashboard">
      {['SUPER', 'TRAINER'].includes(user.role) ? (
        <div id="dash-board-progress" className="card">
          <div className="card-header" id="dash-board-progress_head">
            <span
              className={`menu ${showInfo === 'dashboard' ? 'active' : ''}`}
              onClick={() => {
                setshowInfo('dashboard');
              }}
            >
              Dashboard
            </span>
            <span
              className={`menu ${showInfo === 'inspectors' ? 'active' : ''}`}
              onClick={() => {
                setshowInfo('inspectors');
              }}
            >
              Inspectors
            </span>
            <span
              className={`menu ${showInfo === 'checkers' ? 'active' : ''}`}
              onClick={() => {
                setshowInfo('checkers');
              }}
            >
              Checkers
            </span>
          </div>
          <div
            id="dash-board-progress_content"
            className={`collapse show`}
            aria-labelledby="dash-board-progress_head"
            style={{ padding: '10px' }}
          >
            {showInfo === 'inspectors' && (
              <div className="container-fluid">
                <div className="row">
                  {user && ['SUPER', 'TRAINER'].includes(user.role) && (
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title mb-0">
                            Inspectors Statistics
                          </h3>
                        </div>
                        <div className="card-body">
                          <StatsTable
                            items={inspectors && inspectors.inspectors}
                            today={today}
                            onChangeDate={onChangeDateInspector}
                            onDownload={onDownload}
                          ></StatsTable>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {showInfo === 'checkers' && (
              <div className="container-fluid">
                <div className="row">
                  {user && ['SUPER', 'TRAINER'].includes(user.role) && (
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title mb-0">
                            Checkers Statistics
                          </h3>
                        </div>
                        <div className="card-body">
                          <StatsTable
                            type="CHECKER"
                            items={checkers && checkers.checkers}
                            today={today}
                            onDownload={onDownload}
                            onChangeDate={onChangeDateChecker}
                          ></StatsTable>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {showInfo === 'dashboard' && (
              <div className="container-fluid">
                <div className="row">
                  {user && ['SUPER', 'TRAINER'].includes(user.role) && (
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title mb-0">
                            Dashboard Statistics
                          </h3>
                        </div>
                        <div className="card-body">
                          <AgentStatsTable
                            items={agentStats && agentStats.byUser}
                            today={today}
                            onChangeDate={onChangeDate}
                            onDownloadStats={onDownloadStats}
                          ></AgentStatsTable>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Permission />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  getAgentStats,
  getCsv,
  getStatisticInspectors,
  getStatisticCheckers,
})(Dashboard);
