import React, { useEffect, useState } from 'react';

export default function CarStatus(props) {
  const { qcProgress, inspectProgress, tasks } = props;
  const [colorFill, setColorFill] = useState({});

  const fillColor = (dataName) => {
    switch (dataName) {
      case '02':
        return colorFill['02'];
      case '03':
        return colorFill['03'];
      case '04_front':
        return colorFill['04front'] ? colorFill['04front'] : colorFill['04'];
      case '04_rear':
        return colorFill['04rear'] ? colorFill['04rear'] : colorFill['04'];
      case '05':
        return colorFill['05'];
      case '06_front':
        return colorFill['06'];
      case '06_rear':
        return colorFill['15'];
      case '07_front':
        return colorFill['07front'] ? colorFill['07front'] : colorFill['07'];
      case '07_rear':
        return colorFill['07rear'] ? colorFill['07rear'] : colorFill['07'];
      case '08':
        return colorFill['08'];
      case '09':
        return colorFill['09'];
      case '10':
        return colorFill['10'];
      case '11':
        return colorFill['11'];
      case '12':
        return colorFill['12'];
      case '13':
        return colorFill['13'];
      case '14':
        return colorFill['14'];
      default:
        return '#fff';
    }
  };
  useEffect(() => {
    let newColorFill = {
      '02': 'rgb(191,191,191)',
      '03': 'rgb(191,191,191)',
      '04': 'rgb(191,191,191)',
      '05': 'rgb(191,191,191)',
      '06': 'rgb(191,191,191)',
      '07': 'rgb(191,191,191)',
      '08': 'rgb(191,191,191)',
      '09': 'rgb(191,191,191)',
      10: 'rgb(191,191,191)',
      11: 'rgb(191,191,191)',
      12: 'rgb(191,191,191)',
      13: 'rgb(191,191,191)',
      14: 'rgb(191,191,191)',
      15: 'rgb(191,191,191)',
    };
    if (qcProgress) {
      if (qcProgress.length) {
        qcProgress.forEach((item) => {
          newColorFill = {
            ...newColorFill,
            [item]: 'rgba(250,219,20,.8)',
          };
        });
      }
      if (
        qcProgress.length &&
        (qcProgress.includes('04') || qcProgress.includes('07'))
      ) {
        tasks.forEach((item) => {
          if (item.photoCode === '04' || item.photoCode === '07') {
            item.taskSlots.forEach((task) => {
              if (task.inspects.name !== 'qc' && task.status === 'FINISHED') {
                let name = item.photoCode + task.inspects.position;
                newColorFill = {
                  ...newColorFill,
                  [name]: 'rgba(82,196,26,.8)',
                };
              }
            });
          }
        });
      }
    }

    if (inspectProgress && inspectProgress.length) {
      inspectProgress.forEach((item) => {
        newColorFill = {
          ...newColorFill,
          [item]: 'rgba(82,196,26,.8)',
        };
      });
    }

    setColorFill({ ...newColorFill });
  }, [qcProgress, inspectProgress, tasks]);

  return (
    <div className="car_status_svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="car_svg"
        height="175"
        viewBox="0 0 951.138 946.014"
      >
        <g id="CarStatus" transform="translate(1588.321 1347.014)">
          <path
            data-name="02"
            fill={fillColor('02')}
            d="M.056,61.392h0C-.149,59.274.278,47.083.283,46.96c0,0-.32-7.489,1.805-9.85.38-.421,19.481-26.447,19.5-26.458L22.011,0c.547.052,141.464,3.035,141.514,3.034l16.709,16.709L168.328,41.236l-.174.021c-8.261.98-81.55,9.661-110.535,12.655C39.8,55.752,22.3,58.236,5.378,60.638l-.015,0-5.306.751Z"
            transform="translate(-1163.253 -1024.354)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="08_circle"
            fill={fillColor('08')}
            d="M19.163,0A14.3,14.3,0,0,1,33.386,14.379s-9.361,43.568-9.361,87.664,9.361,88.721,9.361,88.721a14.3,14.3,0,0,1-14.223,14.379c-7.855,0-12.78-5.911-16.288-14.379,0,0-8.262-44.625-8.262-88.721S2.875,14.379,2.875,14.379C5.96,6.6,11.308,0,19.163,0Z"
            transform="translate(-1528.769 -977.254)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="05_circle"
            fill={fillColor('05')}
            d="M8.836,0A14.3,14.3,0,0,0-5.387,14.379s9.361,43.568,9.361,87.664-9.361,88.721-9.361,88.721A14.3,14.3,0,0,0,8.836,205.143c7.855,0,12.78-5.911,16.288-14.379,0,0,8.262-44.625,8.262-88.721s-8.262-87.664-8.262-87.664C22.039,6.6,16.691,0,8.836,0Z"
            transform="translate(-723.152 -978.213)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="11_circle"
            fill={fillColor('11')}
            d="M14.223,0A14.3,14.3,0,0,0,0,14.379s9.361,43.568,9.361,87.664S0,190.764,0,190.764a14.3,14.3,0,0,0,14.223,14.379c7.855,0,12.78-5.911,16.288-14.379,0,0,8.262-44.625,8.262-88.721s-8.262-87.664-8.262-87.664C27.426,6.6,22.077,0,14.223,0Z"
            transform="matrix(0.809, 0.588, -0.588, 0.809, -741.235, -732.786)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="07_front_circle"
            fill={fillColor('07_front')}
            d="M14.223,0A14.3,14.3,0,0,0,0,14.379s9.361,43.568,9.361,87.664S0,190.764,0,190.764a14.3,14.3,0,0,0,14.223,14.379c7.855,0,12.78-5.911,16.288-14.379,0,0,8.262-44.625,8.262-88.721s-8.262-87.664-8.262-87.664C27.426,6.6,22.077,0,14.223,0Z"
            transform="matrix(0.309, 0.951, -0.951, 0.309, -893.756, -542.771)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="07_rear_circle"
            fill={fillColor('07_rear')}
            d="M14.223,0A14.3,14.3,0,0,0,0,14.379s9.361,43.568,9.361,87.664S0,190.764,0,190.764a14.3,14.3,0,0,0,14.223,14.379c7.855,0,12.78-5.911,16.288-14.379,0,0,8.262-44.625,8.262-88.721s-8.262-87.664-8.262-87.664C27.426,6.6,22.077,0,14.223,0Z"
            transform="matrix(-0.309, 0.951, -0.951, -0.309, -1133.097, -479.378)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="12_circle"
            fill={fillColor('12')}
            d="M14.223,0A14.3,14.3,0,0,0,0,14.379s9.361,43.568,9.361,87.664S0,190.764,0,190.764a14.3,14.3,0,0,0,14.223,14.379c7.855,0,12.78-5.911,16.288-14.379,0,0,8.262-44.625,8.262-88.721s-8.262-87.664-8.262-87.664C27.426,6.6,22.077,0,14.223,0Z"
            transform="matrix(-0.809, 0.588, -0.588, -0.809, -1360.904, -566.821)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="13_circle"
            fill={fillColor('13')}
            d="M24.55,0A14.3,14.3,0,0,1,38.772,14.379s-9.361,43.568-9.361,87.664,9.361,88.721,9.361,88.721A14.3,14.3,0,0,1,24.55,205.143c-7.855,0-12.78-5.911-16.288-14.379,0,0-8.262-44.625-8.262-88.721S8.262,14.379,8.262,14.379C11.347,6.6,16.7,0,24.55,0Z"
            transform="matrix(0.809, 0.588, -0.588, 0.809, -1392.271, -1206.796)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="04_rear_circle"
            fill={fillColor('04_rear')}
            d="M24.55,0A14.3,14.3,0,0,1,38.772,14.379s-9.361,43.568-9.361,87.664,9.361,88.721,9.361,88.721A14.3,14.3,0,0,1,24.55,205.144c-7.855,0-12.78-5.911-16.288-14.379,0,0-8.262-44.625-8.262-88.721S8.262,14.379,8.262,14.379C11.347,6.6,16.7,0,24.55,0Z"
            transform="matrix(0.309, 0.951, -0.951, 0.309, -1145.079, -1308.981)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="04_front_circle"
            fill={fillColor('04_front')}
            d="M24.55,0A14.3,14.3,0,0,1,38.772,14.379s-9.361,43.568-9.361,87.664,9.361,88.721,9.361,88.721A14.3,14.3,0,0,1,24.55,205.144c-7.855,0-12.78-5.911-16.288-14.379,0,0-8.262-44.625-8.262-88.721S8.262,14.379,8.262,14.379C11.347,6.6,16.7,0,24.55,0Z"
            transform="matrix(-0.309, 0.951, -0.951, -0.309, -881.775, -1245.588)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="10_circle"
            fill={fillColor('10')}
            d="M14.223,0A14.3,14.3,0,0,0,0,14.379s9.361,43.568,9.361,87.664S0,190.764,0,190.764a14.3,14.3,0,0,0,14.223,14.379c7.855,0,12.78-5.911,16.288-14.379,0,0,8.262-44.625,8.262-88.721s-8.262-87.664-8.262-87.664C27.426,6.6,22.077,0,14.223,0Z"
            transform="matrix(0.809, -0.588, 0.588, 0.809, -860.78, -1184.006)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="09"
            fill={fillColor('09')}
            d="M198.256,147.992c-13.026-4.39-43.706-11.26-90.951-12.508-31.741.069-71.133,6.842-71.133,6.842s-22.89,4.783-23.372,5.25c-.339.367.915,2.783-6.964-62.429L.11,37.376c-.059-.319,18.969-18.4,27.875-23.261s20.78-8.783,29.8-11.221A88.51,88.51,0,0,1,77.47.458h-.053C80.71.308,84.092.2,87.47.144L87.177.087s14.356-.537,36.738,1.045c26.546,1.933,65.8,10.555,70.841,12.794a64.1,64.1,0,0,1,10.85,6.028,36.767,36.767,0,0,1,5.835,5.009,20.66,20.66,0,0,1,2.217,2.811,22.186,22.186,0,0,1,2.568,9.827C216.227,41.178,198.51,148.078,198.256,147.992ZM77.619.458Z"
            transform="translate(-956.968 -983.145) rotate(90)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="05"
            fill={fillColor('05')}
            d="M21.747,75.062C38.556,28,53.515,9.172,53.515,9.172S55.868,4.8,65.953,3.625,111.169-.576,133.692.1,186.3,2.954,190,4.467s7.228.672,15.8,15.968,14.041,32.738,21.763,64.954,4.456,99.077,4.456,99.077-1.613-7.988-4.456-11c-9.748-8.494-16.485-10.875-16.485-10.875-24.1-7.177-52.006-12.222-70.322-13.062s-38.169-.839-38.169-.839-16.809,0-26.755,1.494c-11.873,1.655-34.994,13.3-34.994,13.3S15.445,184.2,15.444,185.2C15.444,185.2,4.938,122.127,21.747,75.062Z"
            transform="translate(-808.839 -998.575) rotate(90)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="06_front"
            fill={fillColor('06_front')}
            d="M17.76,148.4S5.851,143.733,3.673,135.776s-1.53-7.305-2.324-16.459A192.008,192.008,0,0,1,.5,99.162c-.011-6.737.274-13.468.852-20.154.546-6.165.373-15.928,3.93-22.781s15.6-8.471,15.6-8.471-6.307,59.417-5.586,74.3S17.76,148.4,17.76,148.4Z"
            transform="matrix(-0.174, 0.985, -0.985, -0.174, -824.561, -1013.068)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="03"
            fill={fillColor('03')}
            d="M21.178,0H84.712c11.7,0,21.178,12.73,21.178,28.432v8.53c0,15.7-9.482,28.432-21.178,28.432H21.178C9.482,65.395,0,52.665,0,36.962v-8.53C0,12.73,9.482,0,21.178,0Z"
            transform="translate(-992.962 -970.362) rotate(90)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="10"
            fill={fillColor('10')}
            d="M178.621,77.072c-16.233-8.808-58.073-30.186-77.279-35.15S76.248,36.36,53.891,35.183-.413,37.89-.413,37.89L20.532,0A332.886,332.886,0,0,1,69.01,5.884c24.3,5.011,25.131,4.169,48.709,14.159,16.614,7.039,31.17,18.879,39.5,26.545C175.064,63,178.86,77.476,178.621,77.072Z"
            transform="translate(-994.354 -1021.268)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="11"
            fill={fillColor('11')}
            d="M175.332,4.611C158.482,19.65,120.223,28.6,100.171,33.75a112.5,112.5,0,0,1-18.242,3.481c-11.558,1.393-22.411,1.717-27.99,2.089-16.71,1.114-54.352-.128-54.352-.128L20.532,77.081A332.886,332.886,0,0,0,69.01,71.2c24.3-5.011,25.131-4.169,48.709-14.159,16.614-7.039,31.17-18.879,39.5-26.545C175.064,14.078,175.57,4.208,175.332,4.611Z"
            transform="translate(-992.549 -805.46)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="06_front"
            fill={fillColor('06_front')}
            d="M16.729.355S5.365,4.664,3.187,12.621,1.657,19.926.863,29.079A192.007,192.007,0,0,0,.01,49.234C0,55.971.284,62.7.862,69.388c.546,6.165.373,15.928,3.93,22.781s16.18,8.763,16.18,8.763S16.532,81.2,15.789,74.991s-1.377-40.452-.979-48.654C15.531,11.449,16.729.355,16.729.355Z"
            transform="matrix(-0.174, -0.985, 0.985, -0.174, -965.788, -712.084)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="06_rear"
            fill={fillColor('06_rear')}
            d="M14.958,148.944s-9.107-5.211-11.285-13.168-1.53-7.305-2.324-16.459-.036-10.248.509-20.037S2.952,86.851,3.53,80.165C4.077,74,4.807,67.088,8.363,60.235S20.882,47.757,20.882,47.757Z"
            transform="matrix(0.087, 0.996, -0.996, 0.087, -1184.231, -1048.578)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="06_rear"
            fill={fillColor('06_rear')}
            d="M16.412,2.272S5.365,4.664,3.187,12.621,1.657,19.926.863,29.079A192.007,192.007,0,0,0,.01,49.234C0,55.971.284,62.7.862,69.388c.546,6.165.373,15.928,3.93,22.781a24.968,24.968,0,0,0,8.361,9.667s1.775-66.2,2.172-74.4C16.047,12.548,16.412,2.272,16.412,2.272Z"
            transform="translate(-1327.921 -715.499) rotate(-90)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="07_front"
            fill={fillColor('07_front')}
            d="M47.908,101.262V81.008s-18-20.766-24.848-40.8c17.024,2.024,40.517,4.216,59.977,3.817,31.3,6.354,46.371,8.163,65.407,11.646,26.681,5.119,42.876,6.755,45.986,7,2.41,1.224,21.306,38.712,21.306,38.712S122.313,101.036,47.908,101.262Z"
            transform="translate(-1187.486 -829.371)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="07_rear"
            fill={fillColor('07_rear')}
            d="M244.051,124.094c-.351,0-52.344-.432-90.616-1.389-31.152-.778-57.907-1.644-77.371-2.5,3.78-11.338,12.058-24.519,24.6-39.18a344.755,344.755,0,0,1,31.448-31.729c.377.022.741.055,1.082.1,13.06,1.482,22.386,2.439,30.614,3.283,12.008,1.232,20.684,2.121,33.021,3.856h.006l.016,0c6.364.894,10.963,1.541,12.685,1.754v-.24c7.047.841,13.181,1.789,19.675,2.793h.008c3.819.59,7.767,1.2,11.929,1.789-.26,2.684,4.226,10.976,8.034,17.461,3.144,5.353,7.385,11.581,10.389,15.864,3.231,4.605,5.842,8.114,5.86,8.13v19.746l-21.38.265Z"
            transform="translate(-1405.412 -852.079)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="08"
            fill={fillColor('08')}
            d="M16.381,237.621h0a254.411,254.411,0,0,1-11.745-42.8A274.187,274.187,0,0,1,0,145.053,192.143,192.143,0,0,1,6.47,97.514,292,292,0,0,1,21.26,54.823l.124-.287a128.7,128.7,0,0,0,27.4,10.907c21.462,5.791,87.2,7.232,87.2,7.232L135.82,217.4c-6.535-.282-13.2-.425-19.822-.425-18.893,0-45.918,1.216-67.379,7.007a128.774,128.774,0,0,0-32.238,13.644Z"
            transform="translate(-1408.452 -1020.821)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="12"
            fill={fillColor('12')}
            d="M29.1,68C16.857,60.963,9.782,46.725,0,20.651A128.731,128.731,0,0,1,32.239,7.007C61.467-.88,100.864-.375,119.439.425v.6c-.76-.066-9.411,7.506-20.976,19.485C95.23,23.856,91.15,28.061,87.643,32.4c-22.6,25.28-24.921,39.575-24.921,39.575C52.04,71.5,41.333,75.037,29.1,68Z"
            transform="translate(-1392.071 -803.851)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="04_rear"
            fill={fillColor('04_rear')}
            d="M153.435,50.68c-31.152.778-57.907,1.644-77.371,2.5,3.78,11.338,12.058,24.519,24.6,39.18a344.756,344.756,0,0,0,31.448,31.729c.377-.022.741-.055,1.082-.1,13.06-1.482,22.386-2.439,30.614-3.283,12.008-1.232,20.684-2.121,33.021-3.856h.006l.016,0c6.364-.894,10.963-1.541,12.685-1.754,7.047-.6,13.181-1.549,19.675-2.553h.008c3.819-.59,7.767-1.2,11.929-1.789-.26-2.684-.241-21.409,2.763-25.692,3.231-4.605,19.2-25.189,19.217-25.205V48.58S191.707,49.723,153.435,50.68Z"
            transform="translate(-1404.524 -1073.066)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="13"
            fill={fillColor('13')}
            d="M29.1,4.451C16.857,11.487,13.615,28.3,3.833,54.369c8.78,5.218,15.72,7.652,28.406,11.075,29.228,7.887,68.625,7.382,87.2,6.582-2.721-.921-4.046-3-6.06-4.8-3.471-3.087-7.133-7.222-14.917-15.284C95.23,48.6,91.15,44.39,87.643,40.053,65.041,14.773,62.723.478,62.723.478,52.04.95,41.333-2.586,29.1,4.451Z"
            transform="translate(-1390.907 -1020.821)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="14"
            fill={fillColor('14')}
            d="M328.077,211.519h-.1c-2,0-20.451-.107-51.2-3.826h-.006l-.841-.1-.037,0-.329-.04c-11.667-1.411-26.187-3.166-43.753-5.258l-71.132-9.018V47.842c26.978-3.453,100.194-13.427,140.68-18.688,16.15-2.225,27.578-3.138,27.6-3.131.329.082,5.5,24.761,5.965,28.008,1.373,9.468,2.829,20.293,3.621,29.87.985,11.855,2.241,23.9,2.261,35.051-.142,12.547-.691,24.87-1.632,36.627a170.139,170.139,0,0,1-2.575,17.439c-.624,3.392-1.214,6.595-1.691,9.907-.006.043-.7,5.041-1.874,10.99-1.091,5.518-2.839,13.052-4.965,17.6Z"
            transform="translate(-1433.152 -996.384)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="04_front"
            fill={fillColor('04_front')}
            d="M366.769,105.38c-9.412,7.158-101.63,101.186-113.535,115.734s0,28.815,0,28.815L389.3,109.809S376.178,98.222,366.769,105.38Z"
            transform="translate(-873.514 -1395.427) rotate(90)"
            stroke="#000"
            strokeWidth="5"
          />
          <path
            data-name="14_num"
            fill="#000"
            d="M-13.228-50.107V0h-7.93V-40.4a25.26,25.26,0,0,1-4.631,2.615,38.176,38.176,0,0,1-5.657,1.9v-6.768q1.982-.649,3.828-1.367t3.64-1.606q1.794-.889,3.623-1.982t3.777-2.5Zm38.794,1.094v31.65h7.28v6.6h-7.28V0h-7.69V-10.767H-4.307v-6.665q3.008-3.384,6.169-7.332T7.947-32.83q2.922-4.119,5.417-8.254a75.324,75.324,0,0,0,4.2-7.93Zm-7.69,31.65V-37.939A113.569,113.569,0,0,1,10.51-26.25q-3.64,4.99-6.819,8.887Z"
            transform="translate(-1192 -849)"
          />
          <path
            data-name="8_num"
            fill="#000"
            d="M-16.321-13.672a15.818,15.818,0,0,1,.53-4.1A13.141,13.141,0,0,1-14.2-21.431a12.778,12.778,0,0,1,2.666-3.008,13.178,13.178,0,0,1,3.76-2.153,14.532,14.532,0,0,1-5.059-4.563,10.79,10.79,0,0,1-1.88-6.169,11.281,11.281,0,0,1,1.111-4.99,12.1,12.1,0,0,1,3.076-3.965A14.483,14.483,0,0,1-5.9-48.894a17.362,17.362,0,0,1,5.811-.94,17.333,17.333,0,0,1,5.862.957,14.7,14.7,0,0,1,4.648,2.632,11.9,11.9,0,0,1,3.059,3.965,11.35,11.35,0,0,1,1.094,4.956,10.79,10.79,0,0,1-1.88,6.169,14.713,14.713,0,0,1-4.99,4.563,13.812,13.812,0,0,1,3.743,2.136,12.966,12.966,0,0,1,2.717,2.991,12.934,12.934,0,0,1,1.641,3.657,15.482,15.482,0,0,1,.547,4.136,14.2,14.2,0,0,1-1.2,5.879,13.214,13.214,0,0,1-3.35,4.563A15.5,15.5,0,0,1,6.648-.273,19.84,19.84,0,0,1,.017.786a19.583,19.583,0,0,1-6.58-1.06A15.726,15.726,0,0,1-11.724-3.23a13.305,13.305,0,0,1-3.384-4.563A14.026,14.026,0,0,1-16.321-13.672Zm7.725-.684a10.844,10.844,0,0,0,.615,3.76A7.944,7.944,0,0,0-6.238-7.759,7.509,7.509,0,0,0-3.521-5.981a9.728,9.728,0,0,0,3.538.615A9.17,9.17,0,0,0,3.469-6,7.792,7.792,0,0,0,6.187-7.81a8.432,8.432,0,0,0,1.794-2.837,10.093,10.093,0,0,0,.649-3.708,10.085,10.085,0,0,0-.615-3.538,8.706,8.706,0,0,0-1.743-2.888,8.238,8.238,0,0,0-2.717-1.948,8.5,8.5,0,0,0-3.538-.718,8.792,8.792,0,0,0-3.435.667A7.925,7.925,0,0,0-6.152-20.9a8.78,8.78,0,0,0-1.794,2.888A10.032,10.032,0,0,0-8.6-14.355ZM-6.921-36.5a7.363,7.363,0,0,0,.547,2.854A7.45,7.45,0,0,0-4.9-31.36a6.616,6.616,0,0,0,2.17,1.521,6.609,6.609,0,0,0,2.683.547,6.437,6.437,0,0,0,2.683-.564,7.041,7.041,0,0,0,2.188-1.538,7.29,7.29,0,0,0,1.487-2.29,7.274,7.274,0,0,0,.547-2.82,7.7,7.7,0,0,0-.53-2.871,6.959,6.959,0,0,0-1.453-2.29,6.694,6.694,0,0,0-2.187-1.5,6.815,6.815,0,0,0-2.734-.547,6.805,6.805,0,0,0-2.8.564A6.477,6.477,0,0,0-5.024-41.6a7.082,7.082,0,0,0-1.4,2.307A7.837,7.837,0,0,0-6.921-36.5Z"
            transform="translate(-1572 -849)"
          />
          <path
            data-name="5_num"
            fill="#000"
            d="M-14.133-1.128V-8.75A17.338,17.338,0,0,0-4.358-5.5,12.1,12.1,0,0,0,3.674-8.049a8.4,8.4,0,0,0,3.042-6.785,7.659,7.659,0,0,0-3.093-6.494Q.53-23.652-5.247-23.652q-1.982,0-8.032.41l1.743-25.771H12.6v6.768H-4.939l-.82,12.271q2.358-.137,4.272-.137,7.793,0,12.048,4t4.255,10.938A14.953,14.953,0,0,1,9.946-3.5Q5.076.889-3.435.889-10.613.889-14.133-1.128Z"
            transform="translate(-652 -849)"
          />
          <path
            data-name="4_front_num"
            fill="#000"
            d="M-6.1-49.014v31.65h7.28v6.6H-6.1V0h-7.69V-10.767H-35.974v-6.665q3.008-3.384,6.169-7.332t6.084-8.066q2.922-4.119,5.417-8.254a75.324,75.324,0,0,0,4.2-7.93Zm-7.69,31.65V-37.939A113.569,113.569,0,0,1-21.157-26.25q-3.64,4.99-6.819,8.887ZM35.085-42.109H16.765v14.731H33.65v6.87H16.765V0H8.63V-49.014H35.085Z"
            transform="translate(-974 -1298)"
          />
          <path
            data-name="7_front_num"
            fill="#000"
            d="M-1.213-45.049q-3.076,5.64-5.811,11.16T-11.98-22.8Q-14.2-17.227-15.842-11.57A88.941,88.941,0,0,0-18.372,0h-8.306a84.391,84.391,0,0,1,2.341-10.51Q-22.815-15.757-20.764-21t4.6-10.544q2.546-5.3,5.417-10.7H-33.958v-6.768H-1.213Zm34.9,2.939H15.364v14.731H32.249v6.87H15.364V0H7.229V-49.014H33.684Z"
            transform="translate(-969 -401)"
          />
          <path
            data-name="7_rear_num"
            fill="#000"
            d="M-5.417-45.049q-3.076,5.64-5.811,11.16T-16.184-22.8q-2.222,5.571-3.862,11.228A88.941,88.941,0,0,0-22.576,0h-8.306A84.391,84.391,0,0,1-28.54-10.51Q-27.019-15.757-24.968-21t4.6-10.544q2.546-5.3,5.417-10.7H-38.162v-6.768H-5.417ZM40.9,0h-9.5L23.567-13.125A27.412,27.412,0,0,0,21.5-16.218a12.448,12.448,0,0,0-2.051-2.1,6.921,6.921,0,0,0-2.273-1.2,9.461,9.461,0,0,0-2.769-.376H11.125V0h-8.1V-49.014H19.158a23.336,23.336,0,0,1,6.357.82,14.776,14.776,0,0,1,5.059,2.478A11.56,11.56,0,0,1,33.94-41.58a13.029,13.029,0,0,1,1.213,5.793,14.188,14.188,0,0,1-.769,4.768A12.4,12.4,0,0,1,32.2-27.156a13.378,13.378,0,0,1-3.418,2.888,17.081,17.081,0,0,1-4.495,1.846v.137a12.616,12.616,0,0,1,2.324,1.658q.991.906,1.88,1.863a22.341,22.341,0,0,1,1.76,2.17q.872,1.213,1.931,2.82ZM11.125-42.417v15.928h6.768a10.032,10.032,0,0,0,3.469-.581,7.94,7.94,0,0,0,2.751-1.675,7.58,7.58,0,0,0,1.812-2.666,9.143,9.143,0,0,0,.649-3.521,7,7,0,0,0-2.222-5.5q-2.222-1.982-6.392-1.982Z"
            transform="translate(-1245 -401)"
          />
          <path
            data-name="4_rear_num"
            fill="#000"
            d="M-10.305-49.014v31.65h7.28v6.6h-7.28V0H-18V-10.767H-40.178v-6.665q3.008-3.384,6.169-7.332t6.084-8.066q2.922-4.119,5.417-8.254a75.324,75.324,0,0,0,4.2-7.93ZM-18-17.363V-37.939A113.569,113.569,0,0,1-25.361-26.25Q-29-21.26-32.18-17.363ZM42.3,0H32.8L24.968-13.125A27.412,27.412,0,0,0,22.9-16.218a12.448,12.448,0,0,0-2.051-2.1,6.921,6.921,0,0,0-2.273-1.2,9.461,9.461,0,0,0-2.769-.376H12.527V0h-8.1V-49.014H20.559a23.336,23.336,0,0,1,6.357.82,14.776,14.776,0,0,1,5.059,2.478,11.56,11.56,0,0,1,3.367,4.136,13.029,13.029,0,0,1,1.213,5.793,14.188,14.188,0,0,1-.769,4.768A12.4,12.4,0,0,1,33.6-27.156a13.378,13.378,0,0,1-3.418,2.888,17.081,17.081,0,0,1-4.495,1.846v.137a12.616,12.616,0,0,1,2.324,1.658q.991.906,1.88,1.863a22.341,22.341,0,0,1,1.76,2.17q.872,1.213,1.931,2.82ZM12.527-42.417v15.928h6.768a10.032,10.032,0,0,0,3.469-.581,7.94,7.94,0,0,0,2.751-1.675,7.58,7.58,0,0,0,1.812-2.666,9.143,9.143,0,0,0,.649-3.521,7,7,0,0,0-2.222-5.5q-2.222-1.982-6.392-1.982Z"
            transform="translate(-1245 -1298)"
          />
          <path
            data-name="12_num"
            fill="#000"
            d="M-12.493-50.107V0h-7.93V-40.4a25.26,25.26,0,0,1-4.631,2.615,38.176,38.176,0,0,1-5.657,1.9v-6.768q1.982-.649,3.828-1.367t3.64-1.606q1.794-.889,3.623-1.982t3.777-2.5ZM28.762,0H-2V-3.794A14.246,14.246,0,0,1-1.35-8.152,17.632,17.632,0,0,1,.393-11.98a21.327,21.327,0,0,1,2.563-3.384Q4.426-16.953,6.1-18.44t3.418-2.871Q11.262-22.7,12.937-24.1q1.572-1.333,2.871-2.615A17.456,17.456,0,0,0,18.03-29.36a11.99,11.99,0,0,0,1.436-2.888,10.265,10.265,0,0,0,.513-3.3,8.891,8.891,0,0,0-.6-3.4,6.611,6.611,0,0,0-1.675-2.427,7.149,7.149,0,0,0-2.546-1.47,10,10,0,0,0-3.213-.5,14.818,14.818,0,0,0-6.118,1.4,20.938,20.938,0,0,0-5.981,4.2v-7.622a25,25,0,0,1,3.042-1.965,20.732,20.732,0,0,1,3.179-1.4,19.593,19.593,0,0,1,3.452-.837,25.9,25.9,0,0,1,3.862-.273,19.518,19.518,0,0,1,6.016.872A12.806,12.806,0,0,1,24.011-46.4a11.265,11.265,0,0,1,2.957,4.221,15.148,15.148,0,0,1,1.042,5.811,18.072,18.072,0,0,1-.735,5.349,17.31,17.31,0,0,1-2.085,4.426,22.966,22.966,0,0,1-3.264,3.9Q20.012-20.85,17.654-18.9q-1.982,1.606-3.948,3.127T10.168-12.8A16.6,16.6,0,0,0,7.622-9.9a5.209,5.209,0,0,0-.974,2.922V-6.8H28.762Z"
            transform="translate(-1489 -573)"
          />
          <path
            data-name="10_num"
            fill="#000"
            d="M-12.493-50.107V0h-7.93V-40.4a25.26,25.26,0,0,1-4.631,2.615,38.176,38.176,0,0,1-5.657,1.9v-6.768q1.982-.649,3.828-1.367t3.64-1.606q1.794-.889,3.623-1.982t3.777-2.5ZM13.552.82A13.687,13.687,0,0,1,1.606-5.435q-4.29-6.255-4.29-18.081,0-12.988,4.478-19.653t12.92-6.665q16.167,0,16.167,25.122,0,12.441-4.563,18.987A14.683,14.683,0,0,1,13.552.82Zm.786-44.331q-8.921,0-8.921,19.653,0,18.389,8.75,18.389,8.613,0,8.613-18.7Q22.781-43.511,14.338-43.511Z"
            transform="translate(-729 -1133)"
          />
          <path
            data-name="11_num"
            fill="#000"
            d="M-7.144-50.107V0h-7.93V-40.4A25.26,25.26,0,0,1-19.7-37.786a38.176,38.176,0,0,1-5.657,1.9v-6.768q1.982-.649,3.828-1.367t3.64-1.606q1.794-.889,3.623-1.982t3.777-2.5Zm28.164,0V0h-7.93V-40.4a25.26,25.26,0,0,1-4.631,2.615,38.176,38.176,0,0,1-5.657,1.9v-6.768q1.982-.649,3.828-1.367t3.64-1.606q1.794-.889,3.623-1.982t3.777-2.5Z"
            transform="translate(-729 -561)"
          />
          <path
            data-name="3_num"
            fill="#000"
            d="M-15.227-1.812V-9.263a16.932,16.932,0,0,0,10.8,3.794A12.587,12.587,0,0,0,3.384-7.69a7.159,7.159,0,0,0,2.922-6.016q0-8.34-12.749-8.34h-3.794V-28.4h3.623Q4.7-28.4,4.7-36.265q0-7.246-8.818-7.246a14.459,14.459,0,0,0-9.263,3.521v-7.041a23.7,23.7,0,0,1,11.792-2.8q6.528,0,10.459,3.281A10.462,10.462,0,0,1,12.8-38.145q0,9.673-9.844,12.441v.137a12.9,12.9,0,0,1,8.374,3.708,11.053,11.053,0,0,1,3.076,7.981A13.081,13.081,0,0,1,9.451-3.23Q4.495.82-3.606.82-10.886.82-15.227-1.812Z"
            transform="translate(-1024 -893)"
          />
          <path
            data-name="9_num"
            fill="#000"
            d="M16.252-27.173a44.325,44.325,0,0,1-1.589,12.646,25.02,25.02,0,0,1-4.375,8.716A17.209,17.209,0,0,1,3.76-.769,20.006,20.006,0,0,1-4.29.854,23.555,23.555,0,0,1-7.109.684Q-8.528.513-9.81.239t-2.324-.6A15.608,15.608,0,0,1-13.86-.991V-8.135a14.146,14.146,0,0,0,1.777.906,18.788,18.788,0,0,0,2.307.82,22.754,22.754,0,0,0,2.6.581,16.1,16.1,0,0,0,2.615.222A12.156,12.156,0,0,0,.718-6.733a10.871,10.871,0,0,0,4.05-3.35A16.206,16.206,0,0,0,7.349-15.6a28.969,28.969,0,0,0,.906-7.639H8.118a9.063,9.063,0,0,1-1.624,2.068,11.329,11.329,0,0,1-2.307,1.709A12.885,12.885,0,0,1,1.3-18.286a12.583,12.583,0,0,1-3.4.444,15.059,15.059,0,0,1-5.725-1.077,13.489,13.489,0,0,1-4.6-3.059A14.123,14.123,0,0,1-15.483-26.8a17.212,17.212,0,0,1-1.111-6.323,18.137,18.137,0,0,1,1.265-6.921,15.4,15.4,0,0,1,3.486-5.264,15.476,15.476,0,0,1,5.229-3.35A17.73,17.73,0,0,1-.085-49.834a15.967,15.967,0,0,1,6.272,1.248,13.508,13.508,0,0,1,5.2,3.965A20.219,20.219,0,0,1,14.937-37.6,36,36,0,0,1,16.252-27.173ZM7.981-32.4a14.919,14.919,0,0,0-.632-4.478,10.716,10.716,0,0,0-1.76-3.452,7.948,7.948,0,0,0-2.683-2.222A7.43,7.43,0,0,0-.5-43.34a7.561,7.561,0,0,0-3.127.667,7.724,7.724,0,0,0-2.6,1.9A9.145,9.145,0,0,0-7.981-37.8,10.989,10.989,0,0,0-8.63-33.94,12.213,12.213,0,0,0-8-29.839a8.523,8.523,0,0,0,1.76,2.991A7.383,7.383,0,0,0-3.521-25a9.4,9.4,0,0,0,3.5.632A7.835,7.835,0,0,0,3.145-25a8.123,8.123,0,0,0,2.529-1.709,7.839,7.839,0,0,0,1.692-2.546A8.06,8.06,0,0,0,7.981-32.4Z"
            transform="translate(-1023 -791)"
          />
          <path
            data-name="13_num"
            fill="#000"
            d="M-12.493-50.107V0h-7.93V-40.4a25.26,25.26,0,0,1-4.631,2.615,38.176,38.176,0,0,1-5.657,1.9v-6.768q1.982-.649,3.828-1.367t3.64-1.606q1.794-.889,3.623-1.982t3.777-2.5Zm11.348,48.3V-9.263a16.932,16.932,0,0,0,10.8,3.794,12.587,12.587,0,0,0,7.81-2.222,7.159,7.159,0,0,0,2.922-6.016q0-8.34-12.749-8.34H3.845V-28.4H7.468q11.313,0,11.313-7.861,0-7.246-8.818-7.246A14.459,14.459,0,0,0,.7-39.99v-7.041a23.7,23.7,0,0,1,11.792-2.8q6.528,0,10.459,3.281a10.462,10.462,0,0,1,3.931,8.408q0,9.673-9.844,12.441v.137a12.9,12.9,0,0,1,8.374,3.708,11.053,11.053,0,0,1,3.076,7.981A13.081,13.081,0,0,1,23.533-3.23Q18.577.82,10.476.82,3.2.82-1.145-1.812Z"
            transform="translate(-1495 -1130)"
          />
        </g>
      </svg>
    </div>
  );
}
