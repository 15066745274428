import React from 'react';
import DetailTask from './DetailTask';

const Progress = (props) => {
  const {
    tasks,
    inspection,
    user,
    rejectTask,
    reportImage,
    acceptTask,
    kickAgent,
    TaskSlotStatus,
    photosSetting,
    resetInspect,
    inspectAllTask,
    validatePhoto,
    setshowValidateSuccess,
  } = props;
  let ppWarning = [];
  let showSttPhoto = [];
  let ppCheckRule = [];
  let showPhotoSkip = [];
  let photoExist = [];
  let agents = {};
  const qcProgress = inspection.qcProgress ? inspection.qcProgress : [];
  const inspectProgress = inspection.inspectProgress
    ? inspection.inspectProgress
    : [];
  const qc_on_having_odometer =
    inspection?.options?.qc_on_having_odometer ?? false;
  const capture_photo_codes =
    inspection?.options?.customized?.capture_photo_codes ?? [];
  const vehicle = inspection?.vehicle ?? null;
  const dataVehicle = vehicle?.data ?? null;
  const task03 = tasks.find((task) => task.photoCode === '03');
  let showUserInput = false;
  let listTaskSlots03 = [];
  if (task03) {
    listTaskSlots03 = task03.taskSlots ? task03.taskSlots : [];
    if (
      listTaskSlots03.length === 1 &&
      qc_on_having_odometer &&
      dataVehicle &&
      dataVehicle.odom_unit &&
      dataVehicle.odom_reading &&
      dataVehicle.odom_reading !== '0'
    ) {
      showUserInput = true;
    }
  } else {
    if (
      qc_on_having_odometer &&
      dataVehicle &&
      dataVehicle.odom_unit &&
      dataVehicle.odom_reading &&
      dataVehicle.odom_reading !== '0'
    ) {
      showUserInput = true;
    }
  }
  let perQc = 0;
  let perIns = 0;
  let qcPassed = inspection?.qcPassed ?? false;

  if (qcProgress && qcProgress.length) {
    perQc = ['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
      inspection.status,
    )
      ? 100
      : Math.ceil((qcProgress.length * 100) / 13);
  }
  if (inspectProgress && inspectProgress.length) {
    perIns = ['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
      inspection.status,
    )
      ? 100
      : Math.ceil((inspectProgress.length * 100) / 13);
  }
  let listAgents = [],
    listQc = [],
    listInspect = [],
    listImages = [
      { photoCode: '01' },
      { photoCode: '02' },
      { photoCode: '03' },
      { photoCode: '04' },
      { photoCode: '05' },
      { photoCode: '06' },
      { photoCode: '07' },
      { photoCode: '08' },
      { photoCode: '09' },
      { photoCode: '10' },
      { photoCode: '11' },
      { photoCode: '12' },
      { photoCode: '13' },
      { photoCode: '15' },
    ];
  if (capture_photo_codes.length > 0) {
    listImages = capture_photo_codes.map((item) => {
      item = item < 10 ? `0${item}` : `${item}`;
      return {
        photoCode: item,
      };
    });
  }
  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i];
    if (task.status !== 'REJECTED') {
      photoExist.push(task.photoCode);
    }

    // count agent tasks
    let slots = task.taskSlots || [];
    if (slots && slots.length) {
      const qcIndex = slots.findIndex((slot) => slot.type === 'QC');
      slots.push(...slots.splice(0, qcIndex));
    }
    // Flow Check Validating Photo before PROCESSING
    const hitl = inspection?.hitl ?? [];
    //
    for (let i = 0; i < slots.length; i++) {
      const slot = slots[i];

      if (task.image_cap && slot.type === 'QC' && slot.status === 'FINISHED') {
        let foundTaskIndex = listImages.findIndex(
          (item) => item.photoCode === slot.photoCode,
        );
        if (foundTaskIndex || foundTaskIndex === 0) {
          let dataHitl = {};
          if (hitl) {
            const foundHitl = hitl.find(
              (itemHitl) => `0${itemHitl.photo_code}` === slot.photoCode,
            );
            if (foundHitl) {
              dataHitl = {
                ...foundHitl,
              };
            }
          }
          listImages[foundTaskIndex] = {
            ...task,
            dataHitl,
          };
        }
      }
      if (slot.agent) {
        const agent = slot.agent.username;
        const type = i === 0 ? 'QC' : 'INSPECT';
        let info = agents[agent] || { QC: 0, INSPECT: 0 };
        info[type]++;
        agents[agent] = info;
      }
    }
  }
  const foundAgents = Object.keys(agents);
  for (let i = 0; i < foundAgents.length; i++) {
    const agent = foundAgents[i];
    const count = i + 1;
    listAgents.push(<th key={`Agent_${count}`}>{agent}</th>);
    listQc.push(<td key={`Agent_QC_${count}`}>{agents[agent]['QC']}</td>);
    listInspect.push(
      <td key={`Agent_Inspect_${count}`}>{agents[agent]['INSPECT']}</td>,
    );
  }
  if (
    inspection.inspectionThemes &&
    inspection.inspectionThemes.name === 'PAVE-PASS'
  ) {
    ppWarning.push(
      <div
        key="Is_PP"
        className="badge badge-dark"
        style={{
          fontWeight: 'bold',
          background: 'rgb(41, 128, 185)',
        }}
      >
        <span className="text-white">PAVE-PASS</span>
      </div>,
    );
    const processPhotoCodes =
      inspection.options && inspection.options.processPhotoCodes
        ? inspection.options.processPhotoCodes
        : [];
    const skipRule =
      inspection.options && inspection.options.pp_skip_mandatory
        ? inspection.options.pp_skip_mandatory
        : false;
    const ppDirectFacing = ['04', '05', '07', '08'].every((c) =>
      processPhotoCodes.includes(c),
    );
    const ppDiagonalFacing = ['10', '11', '12', '13'].every((c) =>
      processPhotoCodes.includes(c),
    );
    ppWarning.push(
      <div
        key="Is_PP_Process_Codes"
        className="badge badge-light"
        style={{
          fontWeight: 'bold',
        }}
      >
        <span className="text-logan">
          ({processPhotoCodes.length}){processPhotoCodes.join(',')}
        </span>
      </div>,
    );
    ppWarning.push(
      <div
        key="Is_PP_Missing_Direct"
        className={`badge badge-light ${
          ppDirectFacing ? 'text-success' : 'text-danger'
        }`}
      >
        <i
          className={`fa fa-lg fa-${
            ppDirectFacing ? 'check' : 'exclamation'
          }-circle`}
        ></i>
        <span className="">Direct Facing</span>
      </div>,
    );

    ppWarning.push(
      <div
        key="Is_PP_Missing_Diagonal"
        className={`badge badge-light ${
          ppDiagonalFacing ? 'text-success' : 'text-danger'
        }`}
      >
        <i
          className={`fa fa-lg fa-${
            ppDiagonalFacing ? 'check' : 'exclamation'
          }-circle`}
        ></i>
        <span className="">Diagonal Facing</span>
      </div>,
    );
    ppWarning.push(
      <div
        key="Check_Lock_Task"
        className={`badge badge-light ${
          skipRule ? 'text-primary' : 'text-success'
        }`}
      >
        <i className={`fa fa-lg fa-${skipRule ? 'times' : 'check'}-circle`}></i>
        <span className="">Lock Task</span>
      </div>,
    );
  } else {
    if (photoExist && photoExist.length > 0) {
      let photoDefault =
        inspection &&
        inspection.inspectionThemes &&
        inspection.inspectionThemes.taskSettings
          ? inspection.inspectionThemes.taskSettings
              .filter((item) => {
                if (item.photo !== '15') {
                  return item;
                }
              })
              .map((item) => {
                return item.photo;
              })
          : [];

      if (capture_photo_codes.length > 0) {
        photoDefault = inspection.options.customized.capture_photo_codes.map(
          (item) => {
            if (item > 9) {
              return `${item}`;
            } else {
              return `0${item}`;
            }
          },
        );
      }
      const customized =
        inspection.options && inspection.options.customized
          ? inspection.options.customized
          : null;
      const skipTire =
        inspection.options && inspection.options.skip_tire
          ? inspection.options.skip_tire
          : false;
      let taskSkip = [];
      if (
        customized &&
        customized.allow_skip &&
        customized.allow_skip.length > 0
      ) {
        for (let i = 0; i < customized.allow_skip.length; i++) {
          const photoCode = customized.allow_skip[i];
          taskSkip.push(photoCode);
        }
      }
      if (skipTire) {
        taskSkip.push('06');
      }
      if (customized) {
        if (customized.skip_vin) {
          taskSkip.push('01');
        }
        if (customized.skip_interior) {
          taskSkip.push('02');
        }
      }
      taskSkip.sort((a, b) => {
        return a - b;
      });
      let photoMissing = [];
      photoMissing = photoDefault.filter(
        (item) => !photoExist.includes(item) && !taskSkip.includes(item),
      );
      photoMissing.sort((a, b) => {
        return a - b;
      });
      if (taskSkip && taskSkip.length) {
        showPhotoSkip.push(
          <div
            key="Photo_Skip"
            className="badge badge-success"
            style={{
              fontWeight: 'bold',
            }}
          >
            <span className="text-white">Skip</span>
          </div>,
        );
        showPhotoSkip.push(
          <div key="Photo_SkipTask" className="badge badge-light">
            <span className="text-dark">{taskSkip.join(',')}</span>
          </div>,
        );
      }
      if (photoMissing && photoMissing.length > 0) {
        showSttPhoto.push(
          <div
            key="Missing_Reject"
            className="badge badge-warning"
            style={{
              fontWeight: 'bold',
            }}
          >
            <span className="text-dark">Rejected Or Missing</span>
          </div>,
        );
        showSttPhoto.push(
          <div
            key="Photo_Missing_Reject"
            className="badge badge-light"
            style={{
              fontWeight: 'bold',
            }}
          >
            <span className="text-dark">{photoMissing.join(',')}</span>
          </div>,
        );
      }
    }
  }
  return (
    <div id="Inspection_Progress">
      <div
        className="information_image mt-2"
        style={{
          borderRadius: '30px',
          padding: '15px 25px',
          background: '#675d7a',
        }}
      >
        <div
          className="text-head text-white mb-2 d-inline-block"
          style={{ width: '225px' }}
        >
          <div className="d-flex align-items-center">
            {!inspection.qcPassed ? (
              <div className="badge badge-primary">QC</div>
            ) : (
              <div className="badge badge-success">QC PASSED</div>
            )}
            <div
              className="progress"
              id={`qc_detail_${inspection.sessionID}`}
              style={{ cursor: 'pointer', margin: '0 5px', width: '80%' }}
            >
              <div
                className={`progress-bar ${
                  perQc >= 100 ||
                  ['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
                    inspection.status,
                  )
                    ? 'bg-success'
                    : 'bg-primary progress-bar-striped progress-bar-animated'
                }`}
                role="progressbar"
                style={{
                  width: `${perQc}%`,
                  fontWeight: 'bold',
                }}
                aria-valuenow={perQc}
                aria-valuemin="0"
                aria-valuemax={perQc}
              >
                {qcProgress && qcProgress.length ? qcProgress.length : 0}
              </div>
            </div>
          </div>
        </div>
        <div
          className="text-head text-white mb-2 d-inline-block"
          style={{ width: '250px' }}
        >
          <div className="d-flex align-items-center">
            {!perIns >= 100 ||
            ['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
              inspection.status,
            ) ? (
              <div className="badge badge-success">INSPECT</div>
            ) : (
              <div className="badge badge-primary">INSPECT</div>
            )}
            <div
              className="progress"
              id={`ins-detail-${inspection.inspectionID}`}
              style={{ cursor: 'pointer', margin: '0 5px', width: '80%' }}
            >
              <div
                className={`progress-bar ${
                  perIns >= 100 ||
                  ['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
                    inspection.status,
                  )
                    ? 'bg-success'
                    : 'bg-primary progress-bar-striped progress-bar-animated'
                }`}
                role="progressbar"
                style={{
                  width: `${perIns}%`,
                  fontWeight: 'bold',
                }}
                aria-valuenow={perIns}
                aria-valuemin="0"
                aria-valuemax={perIns}
              >
                {inspectProgress && inspectProgress.length
                  ? inspectProgress.length
                  : 0}
              </div>
            </div>
          </div>
        </div>

        {showUserInput ? (
          <div className="text-head text-white mb-1">
            <div className="d-flex align-items-center">
              <div className="badge badge-danger mr-1 text-uppercase">
                User Input
              </div>
              <div className="badge badge-light mr-1 text-uppercase text-head">
                <span className="mr-1"> Odometer :</span>
                <span className="text-dark">{dataVehicle.odom_reading}</span>
              </div>

              <div className="badge badge-light mr-1 text-uppercase text-head">
                <span className="mr-1"> Unit :</span>
                <span className="text-dark">{dataVehicle.odom_unit}</span>
              </div>
            </div>
          </div>
        ) : null}
        {ppWarning.length > 0 && (
          <div className="badge-group">
            {ppWarning}
            {ppCheckRule}
          </div>
        )}

        {showSttPhoto.length > 0 && (
          <div className="badge-group">{showSttPhoto}</div>
        )}
        {showPhotoSkip.length > 0 && (
          <div className="badge-group">{showPhotoSkip}</div>
        )}
      </div>

      <div className="information_inspect mt-2">
        <DetailTask
          qcPassed={qcPassed}
          listImages={listImages}
          tasks={tasks}
          inspection={inspection}
          photosSetting={photosSetting}
          user={user}
          reportImage={reportImage}
          rejectTask={rejectTask}
          acceptTask={acceptTask}
          inspectAllTask={inspectAllTask}
          kickAgent={kickAgent}
          TaskSlotStatus={TaskSlotStatus}
          resetInspect={resetInspect}
          validatePhoto={validatePhoto}
          setshowValidateSuccess={setshowValidateSuccess}
        />
      </div>
      <div className="table-responsive mt-3">
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th>
                Agent
                <span className="ml-2">({listAgents.length})</span>
              </th>
              {listAgents}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>QC</th>
              {listQc}
            </tr>
            <tr>
              <th>Inspect</th>
              {listInspect}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Progress;
