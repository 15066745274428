export const GET_AGENTS = 'app/users/GET_AGENTS';
export const GET_AGENT = 'app/users/GET_AGENT';
export const GET_SETTINGS = 'app/users/GET_SETTINGS';
export const POST_AGENT = 'app/users/POST_AGENT';
export const PUT_AGENT = 'app/users/PUT_AGENT';
export const DELETE_AGENT = 'app/users/DELETE_AGENT';
export const GET_AGENT_HISTORY = 'app/users/GET_AGENT_HISTORY';
export const GET_AGENT_SHIFTS = 'app/users/GET_AGENT_SHIFTS';
export const GET_AGENT_SHIFT = 'app/users/GET_AGENT_SHIFT';
export const SET_AGENT_SHIFT = 'app/users/SET_AGENT_SHIFT';
export const UPDATE_AGENT_SHIFT = 'app/users/UPDATE_AGENT_SHIFT';
export const SET_AGENT_SCHEDULE = 'app/users/SET_AGENT_SCHEDULE';
export const SET_AGENT_STATS = 'app/users/SET_AGENT_STATS';
export const SET_AGENT_PROCESS_ERROR = 'app/users/SET_AGENT_PROCESS_ERROR';
export const CLEAR_SUCCESS = 'app/users/CLEAR_SUCCESS';
