import React from 'react';
import moment from 'moment-timezone';
const Notes = (props) => {
  const { notes, timezone } = props;
  return (
    <div className="groupNotes mt-3">
      {notes.length > 0 &&
        notes.map((item, index) => {
          const messAt = item.messAt
            ? moment(item.messAt).tz(timezone).format('DD/MM/YY hh:mm:ss A')
            : null;
          const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
          let mess = [];
          let textMess = item.message ? item.message : '';
          if (
            textMess &&
            textMess.match(urlRegex) &&
            textMess.match(urlRegex).length > 0
          ) {
            const url = textMess.match(urlRegex);
            textMess = textMess.replace(urlRegex, () => {
              return '';
            });
            for (let i = 0; i < url.length; i++) {
              const item = url[i];
              mess.push(
                <a
                  style={{
                    wordBreak: 'break-all',
                  }}
                  key={'hyperlink_' + index}
                  className="text-primary d-block"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item}
                >
                  {item}
                </a>,
              );
            }
          }
          textMess = textMess.split('\n').map((str, index) => (
            <p
              style={{
                wordBreak: 'break-all',
              }}
              key={`text_${index}`}
            >
              {str}
            </p>
          ));
          mess = [...textMess, ...mess];
          return (
            <div className="mt-5" key={`message_` + index}>
              <div
                className="d-flex"
                style={{
                  color: '#fff',
                  fontSize: '15px',
                  borderRadius: '10px',
                  position: 'relative',
                }}
              >
                <div
                  className="d-flex"
                  style={{
                    position: 'absolute',
                    zIndex: '99',
                    left: '15px',
                    top: '-30px',
                    padding: '5px 15px',
                    background: '#fff',
                    borderRadius: '10%',
                    border: '2px solid #321640',
                  }}
                >
                  <div>
                    <span
                      className="text-head text-dark"
                      style={{
                        display: 'block',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                      }}
                    >
                      {item.username}
                    </span>
                    <span
                      style={{
                        display: 'block',
                        fontWeight: 'bold',
                      }}
                      className={
                        item.role !== 'AGENT'
                          ? 'text-danger text-head'
                          : 'text-success text-head'
                      }
                    >
                      {item.role}
                    </span>
                  </div>
                </div>
                <div
                  className="mess"
                  style={{
                    display: 'inline-block',
                    background: '#321640',
                    borderRadius: '10px',
                    position: 'relative',
                    padding: '10px',
                  }}
                >
                  <div
                    className="text-white mt-4"
                    style={{
                      fontFamily: 'Noto Sans',
                    }}
                  >
                    {mess}
                  </div>
                  <div className="mt-1">{messAt}</div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Notes;
