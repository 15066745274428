import React from 'react';
import SVG from 'react-inlinesvg';
const ModalShowErr = (props) => {
  return (
    <div className={`modal timeout show-modal`} id="myModal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="group-timeout">
              <div className="group-text">
                <div className="layout"></div>
                <h3>ERROR!!!!!</h3>
                <span>{props.messErr}</span>
                <button
                  type="button"
                  className={`btn btn-primary`}
                  onClick={() => props.resetError()}
                >
                  CLOSE
                </button>
              </div>
              <SVG
                className={'imageHolder'}
                src={'/img/svg/oops.svg'}
                uniquifyIDs={false}
              ></SVG>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalShowErr;
