import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ModalMD = (props) => {
	const { show, title, body, handleClose, size } = props;

	return (
		<Modal size={size} isOpen={show}>
			<ModalHeader>
				{title}
				<button
					type="button"
					className="close"
					aria-label="Close"
					onClick={handleClose}
				>
					<span aria-hidden="true">
						<i className="fa fa-times" />
					</span>
				</button>
			</ModalHeader>

			<ModalBody>{body}</ModalBody>
		</Modal>
	);
};

export default ModalMD;
